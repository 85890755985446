import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { Box, Container, Text, Flex, Button, Spinner } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
} from '../firebaseConfig';

const NotificationsList = () => {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchNotifications = async (year, month) => {
      setLoading(true);
      const notificationsRef = collection(
        firestore,
        'users',
        currentUser.id,
        'notifications'
      );
      const q = query(
        notificationsRef,
        where('year', '==', year),
        where('month', '==', month)
      );
      const notificationsSnapshot = await getDocs(q);
      const notificationsData = notificationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
      setLoading(false);
    };
    fetchNotifications(year, month);
  }, [year, month, currentUser.id]);

  const handlePrevMonth = () => {
    if (month === 1) {
      setYear(year - 1);
      setMonth(12);
    } else {
      setMonth(month - 1);
    }
  };

  const handleNextMonth = () => {
    if (month === 12) {
      setYear(year + 1);
      setMonth(1);
    } else {
      setMonth(month + 1);
    }
  };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Button onClick={handlePrevMonth} size="sm" variant="outline">
          <ChevronLeftIcon />
        </Button>
        <Text fontSize="lg" fontWeight="bold">
          {year}年{month}月
        </Text>
        <Button
          onClick={handleNextMonth}
          size="sm"
          variant="outline"
          disabled={
            month === new Date().getMonth() + 1 &&
            year === new Date().getFullYear()
          }
        >
          <ChevronRightIcon />
        </Button>
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        notifications.map(notification => (
          <Flex
            key={notification.id}
            borderWidth="1px"
            borderRadius="xl"
            overflow="hidden"
            p={4}
            mt={2}
            bg={notification.is_read ? 'white' : 'yellow.100'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text fontSize="xs" color="gray.500" mb={1}>
                {new Date(
                  notification.timestamp.seconds * 1000
                ).toLocaleString()}
              </Text>
              <Text fontSize="lg">{notification.title}</Text>
              <Text>{notification.message}</Text>
            </Box>
            {notification.link && (
              <Button
                as="a"
                href={notification.link}
                size="sm"
                variant="outline"
                target="_blank"
              >
                詳細
              </Button>
            )}
          </Flex>
        ))
      )}
    </Container>
  );
};

export default NotificationsList;
