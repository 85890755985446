export const handleWaitExecFunction = async (execFunc, onOpen, onClose) => {
  onOpen(); // モーダルを開く
  // 非同期処理と最低限の待機時間1秒を並行して実行
  await Promise.all([
      execFunc(), // 非同期処理を実行
      new Promise(resolve => setTimeout(resolve, 4000)) // 4秒間待機
  ]);

  onClose(); // モーダルを閉じる
};
