import React from 'react';
import { Image, Heading } from '@chakra-ui/react';

const containerStyle = {
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '56px', // この高さは例です。必要に応じて調整してください。
};

const imgStyle = {
  marginRight: '24px',
  objectFit: 'cover',
  objectPosition: 'center', // 中央の部分を表示
};

const Logo = ({ text = '会員専用システム' }) => (
  <>
    <div style={containerStyle}>
      <Image
        src={'/medy_logo.svg'}
        alt="Logo"
        style={imgStyle}
        width={['66vw', '48%']}
      />
    </div>
    <Heading
      as="h1"
      size="xs"
      textAlign="center"
      mt="12px"
      mb="12px"
      color="gray"
    >
      {text}
    </Heading>
  </>
);

export default Logo;
