import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Container,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Input,
  useDisclosure,
  Badge,
} from '@chakra-ui/react';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';

// import { sendDiscordNotification } from '../utils/discord';

const TicketsList = () => {
  const { currentUser } = useAuth();
  const { userId } = useParams();
  const location = useLocation();
  const { user } = location.state || {};
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [newType, setNewType] = useState('');
  const [newExpire, setNewExpire] = useState('');
  const [ticketCount, setTicketCount] = useState(1); // チケットの枚数
  const [usedStatus, setUsedStatus] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchTickets = async () => {
      const ticketsRef = collection(firestore, `users/${userId}/tickets`);
      const ticketsSnapshot = await getDocs(ticketsRef);
      const ticketsData = ticketsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const groupedTickets = ticketsData.reduce((groups, ticket) => {
        const yearMonth = dayjs(ticket.expire.toDate()).format('YYYY年MM月');
        if (!groups[yearMonth]) {
          groups[yearMonth] = [];
        }
        groups[yearMonth].push(ticket);
        return groups;
      }, {});

      const sortedGroups = Object.keys(groupedTickets)
        .sort((a, b) =>
          dayjs(b, 'YYYY年MM月').isBefore(dayjs(a, 'YYYY年MM月')) ? -1 : 1
        )
        .map(group => ({ month: group, tickets: groupedTickets[group] }));

      setTickets(sortedGroups);
    };

    fetchTickets();
  }, [userId]);

  const addTicket = async () => {
    if (newType === '') return;
    const userDocRef = doc(firestore, 'users', userId);
    const newExpireDate =
      newType === 'once'
        ? new Date('2039-12-31T23:59:59')
        : dayjs().add(1, 'month').endOf('month').toDate();

    const newTickets = Array.from({ length: ticketCount }, () => ({
      type: newType,
      expire: newExpireDate,
      used: false,
    }));

    // Firestoreに指定枚数分のチケットを追加
    const ticketsCollectionRef = collection(userDocRef, 'tickets');
    const addPromises = newTickets.map(ticket =>
      addDoc(ticketsCollectionRef, ticket)
    );

    await Promise.all(addPromises)
      .then(() => {
        setNewType('');
        setNewExpire('');
        setTicketCount(1); // 枚数をリセット
        onClose();
      })
      .catch(error => {
        console.error('チケットの追加に失敗しました: ', error);
      });
    window.location.reload();
  };

  const updateTicket = async () => {
    if (!selectedTicket) return;

    const ticketRef = doc(
      firestore,
      `users/${userId}/tickets`,
      selectedTicket.id
    );

    const updatedExpire =
      newType === 'once'
        ? new Date('2039-12-31T23:59:59')
        : dayjs().add(1, 'month').endOf('month').toDate();

    await updateDoc(ticketRef, {
      type: newType,
      expire: updatedExpire,
      used: usedStatus,
    })
      .then(() => {
        onEditClose();
      })
      .catch(error => {
        console.error('チケットの更新に失敗しました: ', error);
      });
    window.location.reload();
  };

  const handleTypeChange = type => {
    setNewType(type);
    if (type === 'once') {
      setNewExpire('2039-12-31T23:59:59');
    } else if (type === 'plan') {
      setNewExpire(
        dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm')
      );
    } else {
      setNewExpire('');
    }
  };

  const getTicketColor = type => {
    return type === 'plan' ? 'green.200' : 'blue.200';
  };

  // discord 通知用メモ

  // const addUserTicket = async () => {
  //   if (user) {
  //     const ticketsCollectionRef = collection(
  //       firestore,
  //       `users/${user.id}/tickets`
  //     );
  //     console.log(`users/${user.uid}/tickets`);

  //     // 来月の末日を計算
  //     const expireDate = calculateNextMonthLastDay();
  //     const ticketData = {
  //       expire: expireDate,
  //       type: 'plan',
  //       used: false,
  //     };
  //     console.log(ticketData);
  //     await addDoc(ticketsCollectionRef, ticketData);
  //     const embeds = [
  //       {
  //         title: 'プランチケット付与',
  //         description: `月${user?.plan}回プランのチケットを付与（管理画面）`,
  //         color: 5814783,
  //         fields: [
  //           {
  //             name: 'インストラクター',
  //             value: currentUser.fullName,
  //             inline: true,
  //           },
  //           {
  //             name: '会員氏名',
  //             value: user?.fullName,
  //             inline: true,
  //           },
  //         ],
  //         footer: {
  //           text: '操作日時',
  //         },
  //         timestamp: new Date().toISOString(),
  //       },
  //     ];

  //     await sendDiscordNotification('manage', embeds);
  //     await sendDiscordNotification('audit', embeds);

  //     navigate('/studios/' + studioId);
  //   } else {
  //     console.log('User is not authenticated.');
  //   }
  // };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      {currentUser.instructor && (
        <Box
          borderWidth="0px"
          borderRadius="xl"
          overflow="hidden"
          mt={6}
          p={4}
          w="100%"
          backgroundColor={'#fdfdfd'}
          boxShadow="sm"
          maxH="70vh"
          display="flex"
          flexDirection="column"
        >
          <Text textAlign={'center'} fontSize="lg" mb="4">
            <b>{user ? user.fullName : 'ユーザー'}さまのチケット</b>
          </Text>
          <Flex direction="column" overflowY="auto">
            {tickets.length > 0 ? (
              tickets.map(group => (
                <Box key={group.month} mb={6}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    {group.month}
                  </Text>
                  {group.tickets.map(ticket => (
                    <Flex
                      key={ticket.id}
                      borderWidth="0px"
                      borderRadius="xl"
                      overflow="hidden"
                      p={4}
                      mt={2}
                      bg={getTicketColor(ticket.type)}
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => {
                        if (ticket.used) return;
                        setSelectedTicket(ticket);
                        setNewType(ticket.type);
                        setNewExpire(
                          ticket.type === 'once'
                            ? '2039-12-31T23:59:59'
                            : dayjs(ticket.expire.toDate()).format(
                                'YYYY-MM-DDTHH:mm'
                              )
                        );
                        setUsedStatus(ticket.used);
                        onEditOpen();
                      }}
                    >
                      <Box>
                        <Text fontSize="sm">
                          種類:{' '}
                          {ticket.type === 'plan' ? 'プラン' : 'ワンタイム'}
                        </Text>
                        <Text fontSize="xs">
                          期限:{' '}
                          {ticket.expire
                            ? dayjs(ticket.expire.toDate()).format(
                                'YYYY年MM月DD日'
                              )
                            : '期限なし'}
                        </Text>
                      </Box>
                      <Badge
                        colorScheme={
                          ticket.used
                            ? 'green'
                            : ticket.expire &&
                              dayjs().isAfter(ticket.expire.toDate())
                            ? 'orange'
                            : 'gray'
                        }
                        fontSize="xs"
                        p={2}
                        borderRadius="md"
                      >
                        {ticket.used
                          ? '使用済み'
                          : ticket.expire &&
                            dayjs().isAfter(ticket.expire.toDate())
                          ? '期限切れ'
                          : '未使用'}
                      </Badge>
                    </Flex>
                  ))}
                </Box>
              ))
            ) : (
              <Text textAlign="center">現在チケットはありません。</Text>
            )}
          </Flex>
          <Button
            mt={4}
            p={4}
            colorScheme="teal"
            size="md"
            onClick={onOpen}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            チケットを追加
          </Button>
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>新しいチケットを追加</ModalHeader>
          <ModalBody>
            種類を選択:
            <Select
              placeholder="チケットの種類を選択"
              value={newType}
              onChange={e => handleTypeChange(e.target.value)}
              mt={2}
            >
              <option value="plan">プラン</option>
              <option value="once">ワンタイム</option>
            </Select>
            期限を選択:
            <Input
              type="datetime-local"
              value={newExpire}
              onChange={e => setNewExpire(e.target.value)}
              mt={2}
              isDisabled={newType !== 'plan'} // ワンタイムの場合は入力を無効化
            />
            枚数:
            <Input
              type="number"
              value={ticketCount}
              onChange={e => setTicketCount(parseInt(e.target.value))}
              mt={2}
              min={1}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme={newType === '' ? 'gray' : 'blue'}
              mr={2}
              onClick={addTicket}
            >
              追加
            </Button>
            <Button size="sm" variant="ghost" onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>チケットの編集</ModalHeader>
          <ModalBody>
            種類を選択:
            <Select
              placeholder="チケットの種類を選択"
              value={newType}
              onChange={e => handleTypeChange(e.target.value)}
              mt={2}
            >
              <option value="plan">プラン</option>
              <option value="once">ワンタイム</option>
            </Select>
            期限を選択:
            <Input
              type="datetime-local"
              value={newExpire}
              onChange={e => setNewExpire(e.target.value)}
              mt={2}
              isDisabled={newType === 'once'} // ワンタイムの場合は入力を無効化
            />
            使用状況:
            <Select
              placeholder="使用状況を選択"
              value={usedStatus}
              onChange={e => setUsedStatus(e.target.value === 'true')}
              mt={2}
            >
              <option value="true">使用済み</option>
              <option value="false">未使用</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="blue" mr={2} onClick={updateTicket}>
              更新
            </Button>
            <Button size="sm" variant="ghost" onClick={onEditClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default TicketsList;
