import React, { useState } from 'react';
import { auth, sendPasswordResetEmail } from '../firebaseConfig';
import {
  Container,
  Box,
  Button,
  Input,
  VStack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import Logo from './Logo';
import BackButton from './BackButton';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async e => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('パスワード再設定のメールを送信しました。');
      setError('');
    } catch (error) {
      console.error('パスワード再設定エラー', error);
      setMessage('');
      setError('メールアドレスが正しいか確認してください。');
    }
  };

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb={4}
    >
      <Logo text="パスワード再設定" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
      >
        <Box width="100%" maxW="400px" mx="auto" mt="5%">
          <VStack spacing="24px">
            <Text fontSize="14">登録したメールアドレスを入力して下さい</Text>
            {message && (
              <Alert status="success" mb="0px">
                <AlertIcon />
                <AlertTitle>{message}</AlertTitle>
              </Alert>
            )}
            {error && (
              <Alert status="error" mb="0px">
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            )}
            <form onSubmit={handlePasswordReset} style={{ width: '100%' }}>
              <VStack spacing="0px">
                <Input
                  placeholder="メールアドレス"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <Button type="submit" colorScheme="blue" width="full" mt="4">
                  パスワード再設定リンクを送信
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Box>
      {'　'}
      <BackButton />
    </Container>
  );
};

export default ResetPasswordPage;
