import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  firestore,
} from '../firebaseConfig';
import { sendDiscordNotification } from '../utils/discord';
import LoadingModal from '../components/LoadingModal';

const TrialPaymentSuccessPage = () => {
  const { currentUser } = useAuth();
  const [processing, setProcessing] = useState(true);
  const navigate = useNavigate();

  const combineDateTime = (date, hour) => {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date object:', date);
      return null;
    }
    const dateStr = date
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');
    return `${dateStr}T${String(hour).padStart(2, '0')}:00`;
  };

  useEffect(() => {
    const confirmReservation = async () => {
      setProcessing(true);
      const selectedDateTime = JSON.parse(
        localStorage.getItem('selectedDateTime')
      );
      // selectedDateTimeのdateをDateオブジェクトに変換
      selectedDateTime.date = new Date(selectedDateTime.date);
      if (!selectedDateTime) {
        navigate('/reserve');
        return;
      }

      const startDateTime = combineDateTime(
        selectedDateTime.date,
        selectedDateTime.hour
      );
      const endDateTime = combineDateTime(
        selectedDateTime.date,
        selectedDateTime.hour + 2
      );

      try {
        const now = new Date();
        const nowStr = now.toISOString();
        const calendarEvent = {
          calendarId: `${currentUser.studio.slug}@studio-medy.com`,
          event: {
            summary: `【体験】${currentUser.fullName}`,
            location: currentUser.studio.name,
            description: `${currentUser.fullName}が体験予約から追加(${nowStr})`,
            colorId: 11,
            start: {
              dateTime: `${startDateTime}:00+09:00`,
              timeZone: 'Asia/Tokyo',
            },
            end: {
              dateTime: `${endDateTime}:00+09:00`,
              timeZone: 'Asia/Tokyo',
            },
          },
        };

        const response = await fetch(
          'https://asia-northeast1-medy-system.cloudfunctions.net/create_google_calendar_event',
          {
            method: 'POST',
            body: JSON.stringify(calendarEvent),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          window.alert('カレンダーに他の予定があります。');
          throw new Error('Googleカレンダーへのイベント追加に失敗しました');
        }

        const eventData = await response.json();

        const reservation = await addDoc(
          collection(firestore, 'reservations'),
          {
            startDate: startDateTime,
            endDate: endDateTime,
            userId: currentUser.uid,
            description: currentUser.fullName,
            studioId: currentUser.studioId,
            ticketId: null,
            visited: false,
            active: true,
            changed: false,
            type: 'trial',
            eventId: eventData.id,
            priority: false,
            instructorId:
              currentUser.studio.shifts[
                `${
                  ['日', '月', '火', '水', '木', '金', '土'][
                    selectedDateTime.date.getDay()
                  ]
                }${selectedDateTime.hour}`
              ]?.[0], // instructorIdの安全なアクセス
          }
        );

        // userドキュメントのtrialIdフィールドに予約IDを追加
        const userRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userRef, {
          trialId: reservation.id,
          trialPaid: true,
        });

        // ディスコード通知
        const embeds = [
          {
            title: '新規体験のご予約が入りました✨',
            description: `体験レッスン予約ページからお客さま自身が入力しました。`,
            color: 15158332,
            fields: [
              {
                name: '氏名（漢字）',
                value: currentUser.fullName,
                inline: true,
              },
              {
                name: '氏名（かな）',
                value: currentUser.fullKana,
                inline: true,
              },
              {
                name: 'LINE表示名',
                value: currentUser.displayName,
                inline: true,
              },
              {
                name: '電話番号',
                value: currentUser.phoneNumber,
                inline: true,
              },
              {
                name: 'メールアドレス',
                value: currentUser.email,
                inline: true,
              },
              {
                name: '認知経路',
                value: currentUser.path,
                inline: true,
              },
              {
                name: 'モチベーション',
                value: currentUser.reason,
                inline: true,
              },
              {
                name: '連絡事項',
                value: currentUser.message || 'なし',
                inline: true,
              },
              {
                name: '体験費用',
                value: '支払い済み',
                inline: true,
              },
              {
                name: '開始時刻',
                value: `${startDateTime.replace('T', ' ')}`,
                inline: true,
              },
              {
                name: '終了時刻',
                value: `${endDateTime.replace('T', ' ')}`,
                inline: true,
              },
            ],
            footer: { text: 'UID: ' + currentUser.uid },
            timestamp: new Date().toISOString(),
          },
        ];

        await sendDiscordNotification(currentUser.studio.slug, embeds);
        await sendDiscordNotification('trial', embeds);
        await sendDiscordNotification('audit', embeds);

        setProcessing(false);
        navigate('/waiting', { state: { reload: true } });
      } catch (error) {
        console.error('予約の追加に失敗しました', error);

        // ディスコードエラー通知
        const errorEmbeds = [
          {
            title: '体験費用の支払い後のエラー',
            description: `体験費用はお支払いされましたが、予約の追加に失敗しました。`,
            color: 16711680,
            fields: [
              {
                name: '対象者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: 'USER ID:' + currentUser?.uid,
            },
            timestamp: new Date().toISOString(),
          },
        ];
        await sendDiscordNotification('payment', errorEmbeds);

        setProcessing(false);
        navigate('/reserve', { state: { reload: true } });
      }
    };

    const updateUserInfo = async () => {
      if (currentUser) {
        // ユーザードキュメントのIDがFirebase AuthのUIDに直接対応していると仮定
        const userRef = doc(firestore, 'users', currentUser.uid);
        try {
          await updateDoc(userRef, {
            trialPaid: true,
          });
          console.log('User Trial status updated successfully.');
          const price = 8000 * (1 - currentUser?.trial_discount / 100);
          const embeds = [
            {
              title: 'medy体験申し込み費用',
              description: `体験レッスン費用がお支払いされました。`,
              color: 5814783,
              fields: [
                {
                  name: '売上',
                  value: `${price.toLocaleString()}円`,
                  inline: true,
                },
                {
                  name: '購入者',
                  value: currentUser?.fullName,
                  inline: true,
                },
              ],
              footer: {
                text: '購入日時',
              },
              timestamp: new Date().toISOString(),
            },
          ];

          await sendDiscordNotification('payment', embeds);

          confirmReservation();
        } catch (error) {
          console.error('Error updating Trial status:', error);
          const errorEmbeds = [
            {
              title: '体験費用の支払い後のエラー',
              description: `体験費用はお支払いされましたが、ユーザー支払い情報の更新に失敗しました。`,
              color: 16711680,
              fields: [
                {
                  name: '対象者',
                  value: currentUser?.fullName,
                  inline: true,
                },
              ],
              footer: {
                text: 'USER ID:' + currentUser?.uid,
              },
              timestamp: new Date().toISOString(),
            },
          ];
          await sendDiscordNotification('payment', errorEmbeds);
          navigate('/reserve', { state: { reload: true } });
        }
      } else {
        console.log('User is not authenticated.');
        // ここでログインページへのリダイレクト等の処理を追加
        navigate('/login');
      }
    };

    if (currentUser) {
      updateUserInfo();
    }
  }, [currentUser, navigate]);

  if (processing) return <LoadingModal text="予約を処理中..." />;

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>お支払い成功🎉</title>
      </Helmet>
      <Heading
        as="h1"
        size="xs"
        textAlign="center"
        mt="12px"
        mb="20px"
        color="gray.600"
      >
        待機画面
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          次のステップの準備中です...
        </Text>
        <Text mt={4} fontSize={'xs'} color="gray.500" textAlign={'center'}>
          少々お待ちくださいませ。
        </Text>
      </Box>
    </Container>
  );
};

export default TrialPaymentSuccessPage;
