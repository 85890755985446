import React, { useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { collection, addDoc, firestore, Timestamp } from '../firebaseConfig';
import { sendDiscordNotification } from '../utils/discord';

const OncePaymentSuccessPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // ２０３９年の年末を計算してTimestamp型で返す関数
  const calculate203912LastDay = () => {
    const month = new Date(2039, 11, 1);
    const lastDayMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    // 23:59:59に設定
    lastDayMonth.setHours(23, 59, 59);
    return Timestamp.fromDate(lastDayMonth); // FirestoreのTimestamp型に変換
  };

  useEffect(() => {
    const price = {
      female: {
        0: '9,900',
        2: '9,350',
        4: '8,800',
        6: '8,250',
        8: '7,700',
      },
      male: {
        0: '11,000',
        2: '10,450',
        4: '9,900',
        6: '9,350',
        8: '8,800',
      },
    };
    const addUserTicket = async () => {
      if (currentUser) {
        const ticketsCollectionRef = collection(
          firestore,
          `users/${currentUser.uid}/tickets`
        );

        // 来月の末日を計算
        const expireDate = calculate203912LastDay();
        const ticketData = {
          expire: expireDate,
          type: 'once',
          used: false,
        };
        await addDoc(ticketsCollectionRef, ticketData);
        const embeds = [
          {
            title: '無期限チケット購入',
            description: `都度払いで無期限の予約チケットが購入されました。`,
            color: 5814783,
            fields: [
              {
                name: '売上',
                value: `${price[currentUser?.sex][0]}円`,
                inline: true,
              },
              {
                name: '購入者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: '購入日時',
            },
            timestamp: new Date().toISOString(),
          },
        ];

        await sendDiscordNotification('payment', embeds);
        await sendDiscordNotification('audit', embeds);

        navigate('/home', { state: { reload: true } });
      } else {
        console.log('User is not authenticated.');
        const embeds = [
          {
            title: '無期限チケット購入後のエラー',
            description: `無期限チケットは購入されましたが、チケットの付与に失敗しました。`,
            color: 16711680,
            fields: [
              {
                name: '対象者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: 'USER ID:' + currentUser?.uid,
            },
            timestamp: new Date().toISOString(),
          },
        ];
        await sendDiscordNotification('payment', embeds);
        await sendDiscordNotification('audit', embeds);

        navigate('/home');
      }
    };
    addUserTicket();
  }, [currentUser, navigate]);

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>お支払い成功🎉</title>
      </Helmet>
      <Heading
        as="h1"
        size="xs"
        textAlign="center"
        mt="12px"
        mb="20px"
        color="gray.600"
      >
        待機画面
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          チケットを付与しています...
        </Text>
        {/* <Image
          src="/waiting.jpg"
          alt="メンテナンス中のイラスト"
          borderRadius="md"
        /> */}
        <Text mt={4} fontSize={'xs'} color="gray.500" textAlign={'center'}>
          少々お待ちくださいませ。
        </Text>
      </Box>
    </Container>
  );
};

export default OncePaymentSuccessPage;
