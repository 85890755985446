import React from 'react';
import {
  Flex,
  Box,
  Text,
  IconButton,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import {
  FaLine,
  FaDiscord,
  FaInstagram,
  FaCheckCircle,
  FaInfo,
} from 'react-icons/fa'; // react-iconsから必要なアイコンをインポート
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 現在のパスを取得

  // メニュー項目の定義
  const menuItems = [
    {
      icon: FaInfo,
      label: '会員',
      route: '/manage', // 管理画面へのルート
      external: false,
    },
    {
      icon: FaCheckCircle,
      label: '日報',
      route: '/reports', // 日次報告画面へのルート
      external: false,
    },
    {
      icon: FaDiscord,
      label: 'Discord',
      url: 'https://discord.com/channels/1194159409174171688/1194160941516984340', // DiscordのURL
      external: true,
    },
    {
      icon: FaLine,
      label: 'LINE',
      url: 'https://manager.line.biz/account/@568pfexg', // 公式LINEのURL
      external: true,
    },
    {
      icon: FaInstagram,
      label: 'Instagram',
      url: 'https://www.instagram.com/medy_pilates', // InstagramのURL
      external: true,
    },
  ];

  return (
    <Box
      position="fixed" // 固定位置に設定
      bottom="0" // 画面下部に固定
      left="50%" // 左から50%の位置に設定
      transform="translateX(-50%)" // 左から50%の位置を中央に調整
      width="100vw" // ビューポート全体の幅を設定
      bg="white"
      boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
      py={4}
      zIndex={1000}
      borderRadius="3xl"
      mb={-6}
    >
      <Flex
        justify="space-evenly" // 均等にスペースを配置
        align="center"
        gap={[2, 4, 6]} // レスポンシブなギャップ
        mx={4} // 左右のマージン
        pb={6}
      >
        {menuItems.map((item, index) => (
          <VStack key={index} spacing={1}>
            {item.external ? (
              <ChakraLink href={item.url} isExternal>
                <IconButton
                  aria-label={item.label}
                  icon={<item.icon />}
                  variant="ghost"
                  size="md"
                />
              </ChakraLink>
            ) : (
              <IconButton
                aria-label={item.label}
                icon={<item.icon />}
                variant="ghost"
                size="md"
                onClick={item.onClick || (() => navigate(item.route))}
                color={
                  location.pathname === item.route ? 'teal.500' : 'gray.600'
                } // アクティブな場合の色
              />
            )}
            <Text fontSize="xs" mt={0} mb={1}>
              {item.label}
            </Text>
          </VStack>
        ))}
      </Flex>
    </Box>
  );
};

export default Footer;
