import { firestore, doc, getDoc, updateDoc } from '../firebaseConfig';

// ユーザーログイン時にユーザーのログインフィールドをtrueに更新
export const updateUserLoginField = async (userId, bool) => {
  const userDocRef = doc(firestore, 'users', userId);
  await updateDoc(userDocRef, {
    login: bool,
  });
};

export const addFixies = async (user, slot) => {
  const maxFixiesAllowed =
    user.plan === 8 ? 2 : user.plan === 4 || user.plan === 6 ? 1 : 0;
  const currentFixiesCount = user.fixies ? user.fixies.length : 0;

  if (currentFixiesCount >= maxFixiesAllowed && !user.instructor) {
    return false; // ユーザーが追加可能な専用枠の上限に達している
  }

  const userDocRef = doc(firestore, 'users', user.uid);
  const studioDocRef = doc(firestore, 'studios', user.studioId);
  const studioDoc = await getDoc(studioDocRef);
  const studioFixies = studioDoc.data()?.fixies || [];

  // スタジオのfixiesで既に同じスロットが存在するかチェック
  const isSlotExistsInStudio = studioFixies.some(
    fixie => fixie.weekday === slot.weekday && fixie.start === slot.start
  );
  if (isSlotExistsInStudio) {
    return false; // 既にスタジオで同じ専用枠が設定されている
  }

  // ユーザーのfixiesに追加
  await updateDoc(userDocRef, {
    fixies: [...(user.fixies || []), slot],
  });

  // スタジオのfixiesに追加
  await updateDoc(studioDocRef, {
    fixies: [...studioFixies, slot],
  });

  return true;
};

export const deleteFixies = async (user, slotToDelete) => {
  if (!user?.studio) return false;
  // userドキュメントから対象の専用枠を削除
  const userDocRef = doc(firestore, 'users', user.uid);
  const newUserFixies = user.fixies.filter(
    slot =>
      slot.weekday !== slotToDelete.weekday || slot.start !== slotToDelete.start
  );
  await updateDoc(userDocRef, {
    fixies: newUserFixies,
  });

  // studioドキュメントからも対象の専用枠を削除
  const studioDocRef = doc(firestore, 'studios', user.studioId);
  const studioDoc = await getDoc(studioDocRef);
  if (studioDoc.exists()) {
    const newStudioFixies = studioDoc
      .data()
      .fixies.filter(
        slot =>
          slot.weekday !== slotToDelete.weekday ||
          slot.start !== slotToDelete.start
      );
    await updateDoc(studioDocRef, {
      fixies: newStudioFixies,
    });
  }

  return true;
};

export const fetchStudioName = async studioId => {
  if (!studioId) return 'スタジオが見つかりません';

  try {
    const studioDocRef = doc(firestore, 'studios', studioId);
    const docSnapshot = await getDoc(studioDocRef);
    return docSnapshot.exists()
      ? docSnapshot.data().name
      : 'スタジオが見つかりません';
  } catch (error) {
    console.error('スタジオの取得中にエラーが発生しました:', error);
    return 'スタジオが見つかりません';
  }
};

export const fetchStudio = async studioId => {
  if (!studioId) return 'スタジオが見つかりません';

  try {
    const studioDocRef = doc(firestore, 'studios', studioId);
    const docSnapshot = await getDoc(studioDocRef);
    return docSnapshot.exists()
      ? docSnapshot.data()
      : 'スタジオが見つかりません';
  } catch (error) {
    console.error('スタジオの取得中にエラーが発生しました:', error);
    return 'スタジオが見つかりません';
  }
};

// 予約IDで予約を１件取得する
export const fetchReservationById = async reservationId => {
  try {
    const reservationDocRef = doc(firestore, 'reservations', reservationId);
    const docSnapshot = await getDoc(reservationDocRef);
    return docSnapshot.exists() ? docSnapshot.data() : null;
  } catch (error) {
    console.error('予約の取得中にエラーが発生しました:', error);
    return null;
  }
};
