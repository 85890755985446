import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Select,
  Box,
  Text,
  Button,
  Flex,
  FormLabel,
  Heading,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import { DeleteIcon } from '@chakra-ui/icons';
import { useAuth } from '../AuthProvider';
import { addFixies, deleteFixies } from '../utils/firestore';

const StudioScheduleForm = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selected, setSelected] = useState(false);
  const [noSlotsMessage, setNoSlotsMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!selectedDay) return;
    setAvailableSlots([]);
    setNoSlotsMessage('');

    const timeSlots = [
      { value: '0800', label: '8:00' },
      { value: '0900', label: '9:00' },
      { value: '1000', label: '10:00' },
      { value: '1100', label: '11:00' },
      { value: '1230', label: '12:30' },
      { value: '1330', label: '13:30' },
      { value: '1430', label: '14:30' },
      { value: '1530', label: '15:30' },
      { value: '1630', label: '16:30' },
      { value: '1730', label: '17:30' },
      { value: '1900', label: '19:00' },
      { value: '2000', label: '20:00' },
      { value: '2100', label: '21:00' },
      { value: '2200', label: '22:00' },
    ];

    const { startAt, closeAt } =
      currentUser?.studio?.openingTimes.find(
        ot => ot.weekday === selectedDay
      ) || {};

    if (startAt === closeAt) {
      alert('定休日です。別の曜日を選択してください。');
      return;
    }

    const dayFixies = (currentUser?.studio?.fixies || []).filter(
      fix => fix.weekday === selectedDay
    );

    const slots = [];

    for (const slot of timeSlots) {
      // Convert times to minutes for comparison
      const slotMinutes =
        parseInt(slot.value.slice(0, 2)) * 60 + parseInt(slot.value.slice(2));
      const startAtMinutes = startAt * 60;
      const closeAtMinutes = closeAt * 60;

      if (slotMinutes >= startAtMinutes && slotMinutes < closeAtMinutes) {
        const slotKey = `${selectedDay}${slot.value}`;
        if (
          currentUser?.studio?.shifts[slotKey]?.length > 0 &&
          !dayFixies.some(fix => fix.start === slot.value)
        ) {
          slots.push(slot);
        }
      }
    }

    if (slots.length === 0) {
      setNoSlotsMessage(
        currentUser.instructor
          ? 'ブロックできる予約受付枠がありません'
          : 'この曜日は専用枠をお取りできません。'
      );
    }

    setAvailableSlots(slots);
    setSelectedSlot(null);
    setSelected(false);
  }, [selectedDay, currentUser]);

  const handleDayChange = event => {
    setSelectedDay(event.target.value);
  };

  const handleSlotChange = event => {
    const selectedValue = event.target.value;
    setSelectedSlot({
      ...selectedSlot,
      weekday: selectedDay,
      start: selectedValue,
      type: 'all',
      block: currentUser.instructor ? true : false,
      userId: currentUser.id,
      userName: currentUser.fullName,
    });
    setSelected(true);
  };

  const handleMovableChange = event => {
    const isMovable = event.target.value === 'true';
    setSelectedSlot({ ...selectedSlot, movable: isMovable });
  };

  const handleDeleteFixies = async fixie => {
    const isConfirmed = window.confirm(
      `本当に${fixie.weekday}曜${fixie.start.slice(0, 2)}:${fixie.start.slice(
        2
      )}の専用枠を解除してもよいですか？`
    );

    if (isConfirmed) {
      const result = await deleteFixies(currentUser, fixie);
      if (result) {
        navigate('/home', { state: { reload: true } });
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!selectedSlot) return;
    if (
      selectedSlot.weekday === undefined ||
      selectedSlot.start === undefined
    ) {
      alert('曜日と時間を選択してください。');
    }
    const result = await addFixies(currentUser, selectedSlot);
    result ? navigate('/home', { state: { reload: true } }) : onOpen();
  }, [currentUser, navigate, onOpen, selectedSlot]);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
      >
        <Heading as="h1" size="sm" textAlign="center" mt="12px" mb="20px">
          {currentUser.instructor
            ? '毎週の固定ブロックを設定'
            : 'ご予約専用枠の設定'}
        </Heading>
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          mx={5}
          p={4}
          mb={2}
        >
          <Heading size="xs" mb={4}>
            {currentUser.fullName}さまの
            {currentUser.instructor ? '毎週のブロック' : 'ご予約専用枠'}
          </Heading>
          {currentUser.fixies && currentUser.fixies.length > 0 ? (
            currentUser.fixies.map((fix, index) => (
              <Flex align="center" justify="space-between" my={1} key={index}>
                <Text>
                  ・{fix.weekday}曜 {fix.start.slice(0, 2)}:{fix.start.slice(2)}
                </Text>
                <Button
                  size="xs"
                  colorScheme="red"
                  onClick={() => handleDeleteFixies(fix)}
                >
                  <DeleteIcon />
                  <span style={{ marginLeft: 6 }}>解除</span>
                </Button>
              </Flex>
            ))
          ) : (
            <Text fontSize="sm">
              {currentUser.instructor ? (
                <span>
                  まだ設定されていません。
                  <br />
                  4枠まで設定できます。
                </span>
              ) : currentUser?.plan > 0 ? (
                <span>
                  まだ設定されていません。
                  <br />
                  あと{parseInt(currentUser?.plan / 4)}
                  枠設定できます。
                </span>
              ) : (
                <span>プラン登録がないため、専用枠を設定できません。</span>
              )}
            </Text>
          )}
        </Box>
        {currentUser.instructor
          ? currentUser.fixies.length >= 4 && (
              <Box p="5">
                <Text fontSize="sm" color="red">
                  専用枠の設定上限に達しております。
                  <br />
                  １つ削除するまで新たに専用枠の設定ができません。
                </Text>
              </Box>
            )
          : parseInt(currentUser?.plan / 4) === currentUser?.fixies.length && (
              <Box p="5">
                <Text fontSize="sm" color="red">
                  専用枠の設定上限に達しております。
                  <br />
                  １つ削除するまで新たに専用枠の設定ができません。
                </Text>
              </Box>
            )}
        {(currentUser.instructor
          ? currentUser.fixies.length < 4
          : parseInt(currentUser?.plan / 4) !== currentUser?.fixies.length &&
            currentUser.plan !== 0) && (
          <Box p="5">
            <FormLabel fontSize="12" mb="1">
              曜日を選択してください：
            </FormLabel>
            <Select placeholder="曜日を選択" onChange={handleDayChange} mb="4">
              {currentUser?.studio?.openingTimes.map((time, index) => (
                <option key={index} value={time.weekday}>
                  {time.weekday}
                </option>
              ))}
            </Select>
            {availableSlots.length > 0 ? (
              <Select
                placeholder="時間枠を選択"
                onChange={handleSlotChange}
                mb="4"
              >
                {availableSlots.map((slot, index) => (
                  <option key={index} value={slot.value}>
                    {slot.label}
                  </option>
                ))}
              </Select>
            ) : (
              <Text fontSize="sm" color="red" mb={4}>
                {noSlotsMessage}
              </Text>
            )}
            {selected && selectedSlot && (
              <>
                <FormLabel fontSize="12" mb="1">
                  この専用枠は移動できますか？：
                </FormLabel>
                <Select
                  placeholder="回答を選択"
                  onChange={handleMovableChange}
                  mb="4"
                >
                  <option key="true" value={true}>
                    相談して可能であれば🙆‍♀️
                  </option>
                  <option key="false" value={false}>
                    別の枠だと厳しいです🙇‍♀️
                  </option>
                </Select>
              </>
            )}
            <Flex justifyContent="center">
              {selectedSlot && (
                <Text mb="4" fontSize={12} color={'gray'}>
                  選択された時間枠：{selectedSlot.weekday}曜・
                  {selectedSlot.start.slice(0, 2)}:{selectedSlot.start.slice(2)}
                </Text>
              )}
            </Flex>
            <Flex justifyContent="center">
              <Button
                onClick={handleSubmit}
                colorScheme="telegram"
                isDisabled={!selectedSlot}
                size="md"
              >
                専用枠を確定
              </Button>
            </Flex>
          </Box>
        )}
      </Box>
      <BackButton />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>別の方の専用枠でした🙇‍♂️</ModalHeader>
          <ModalBody>
            大変申し訳ありません。
            <br />
            <br />
            わずかな差で他の方が少し早く設定した場合などに、選択できた時間で専用枠をお取りできない場合があります。
            <br />
            <br />
            お手数ですが、別の日時を指定して再度設定をお願いいたします。
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="gray" mr={2} onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box overflow="hidden" px={8} pb={6}>
        <p style={{ fontSize: 12, color: 'gray' }}>
          確認してみて、
          <b>
            {currentUser?.fullName}
            さまが定期的に通える曜日と時間が専用枠として取れない場合
          </b>
          は、
          <br />
          <br />
          一度公式LINEからインストラクターにご相談ください。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          希望する曜日と時間をいくつかお伝えいただけましたら、
          <br />
          <br />
          インストラクターの方から移動が可能なお客様に確認と専用枠の変更のお願いをさせていただきます。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          また、上記の理由で{currentUser?.fullName}
          さまに<b>専用枠の移動のお願いをする場合</b>があります。
          <br />
          <br />
          何卒ご協力いただけますと幸いです。
        </p>
        <br />
        <p
          style={{
            fontSize: 13,
            color: 'teal',
            paddingRight: 8,
            float: 'right',
          }}
        >
          STUDIO medy
        </p>
      </Box>
    </Container>
  );
};

export default StudioScheduleForm;
