import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { doc, updateDoc, firestore } from '../firebaseConfig';

const SetStatusTruePage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const updateUserStatus = async () => {
      if (currentUser) {
        const userRef = doc(firestore, 'users', currentUser.uid);
        try {
          // ユーザードキュメントを更新
          await updateDoc(userRef, { status: true });
          console.log('User status updated successfully.');
          navigate('/before', { state: { reload: true } });
        } catch (error) {
          console.error('Error updating user status:', error);
        }
      } else {
        console.log('User is not authenticated or plan is not set.');
      }
    };
    updateUserStatus();
  }, [currentUser, navigate]);

  return (
    <div>
      <h1>Status Update Success</h1>
      <p>Processing your register. Please wait...</p>
    </div>
  );
};

export default SetStatusTruePage;
