import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
} from '../firebaseConfig';
import { updateUserLoginField } from '../utils/firestore';
import {
  Container,
  Box,
  Button,
  Input,
  VStack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import Logo from './Logo';

const LoginPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showHelp, setShowHelp] = useState(false);

  const signInWithEmail = async e => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await updateUserLoginField(auth.currentUser.uid, true);
      setEmail('');
      setPassword('');
      const preLoginUrl = localStorage.getItem('preLoginUrl') || '/home';
      localStorage.removeItem('preLoginUrl');
      navigate(preLoginUrl, { replace: true });
    } catch (error) {
      console.log('ログインエラー', error);
      // invalid-emailのtoast通知
      if (error.code === 'auth/invalid-credential') {
        alert('メールアドレスかパスワードが正しくありません。');
        window.location.reload();
      }
    }
  };

  const resendVerificationEmail = async () => {
    if (currentUser && !currentUser.emailVerified) {
      try {
        await sendEmailVerification(currentUser.auth.currentUser);
        alert('確認メールを再送信しました。');
      } catch (error) {
        console.log('確認メールの再送信エラー', error);
        alert('確認メールの再送信に失敗しました。');
      }
    }
  };

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  useEffect(() => {
    if (currentUser && currentUser.login) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb={4}
    >
      <Logo text="ログイン認証" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
      >
        <Box width="100%" maxW="400px" mx="auto" mt="5%">
          {currentUser && !currentUser.emailVerified && (
            <Alert status="warning" mb="20px">
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>
                  このページは閉じず、メールを確認してください！
                </AlertTitle>
                <AlertDescription display="block">
                  アカウントを有効化するため、登録時にお送りしたメール内のリンクを開いてください。
                  <br />
                  <br />
                  １度開けば大丈夫です。２回開くと期限切れか既に使用されたと表示されますが問題ありません。
                  <br />
                  <br />
                  このページは閉じないてください。
                </AlertDescription>
              </Box>
            </Alert>
          )}
          <VStack spacing="24px">
            {currentUser && !currentUser.emailVerified ? (
              <>
                <Button
                  colorScheme="orange"
                  width="full"
                  onClick={() => window.location.reload()}
                >
                  メール確認後に再読み込み
                </Button>
                <Button
                  size="xs"
                  mt={4}
                  onClick={() => setShowHelp(!showHelp)}
                  variant={'outlineBlack'}
                >
                  メールが届かない方へ
                </Button>
                {showHelp && (
                  <>
                    <Text
                      color={'gray'}
                      fontSize="xs"
                      my={2}
                      textAlign={'center'}
                    >
                      ・メールアドレスの間違いがないか
                      <br />
                      ・迷惑フォルダ等に入っていないか
                      <br />
                      をご確認くださいませ
                    </Text>
                    <Text
                      color={'blue'}
                      fontSize="sm"
                      my={2}
                      textAlign={'center'}
                    >
                      間違いがあったり、解決が難しい場合は
                      <br />
                      以下から別のアドレスでご登録ください
                    </Text>
                    <Button
                      colorScheme="red"
                      width="full"
                      onClick={async () => await signOut(currentUser.auth)}
                    >
                      別のアドレスで登録をやり直す
                    </Button>
                    <Text
                      color={'gray'}
                      fontSize="xs"
                      my={2}
                      textAlign={'center'}
                    >
                      メールが届けばあとは簡単です🌟
                      <br />
                      もうひと踏ん張りです😭
                    </Text>
                  </>
                )}
                <Button
                  colorScheme="teal"
                  width="full"
                  onClick={resendVerificationEmail}
                >
                  確認メールを再送する
                </Button>
              </>
            ) : (
              <Text fontSize="14">
                入会時に設定したメールとパスワードを入力
              </Text>
            )}
            <form onSubmit={signInWithEmail} style={{ width: '100%' }}>
              <VStack spacing="12px">
                {currentUser && !currentUser.emailVerified ? (
                  <></>
                ) : (
                  <>
                    <Input
                      placeholder="メールアドレス"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Input
                      placeholder="パスワード"
                      type="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                      type="submit"
                      colorScheme="blue"
                      width="full"
                      mt="4"
                    >
                      ログイン
                    </Button>
                  </>
                )}
              </VStack>
            </form>
          </VStack>
        </Box>
        <Text fontSize="sm" mt="8" mb="4" textAlign="center">
          <a
            href="/trial"
            style={{ textDecoration: 'underline', color: '#990000' }}
          >
            体験レッスンがまだの方はこちら
          </a>
        </Text>
        <Text fontSize="sm" mt="8" mb="4" textAlign="center">
          <a
            href="/resset"
            style={{ textDecoration: 'underline', color: 'teal' }}
          >
            パスワードを忘れた方はこちら
          </a>
        </Text>
      </Box>
      <Text fontSize="sm" mt="8" mb="4" px="4" color={'gray'}>
        体験レッスンに来られなかった方は公式ラインでお伝えいただければ再度体験ご予約可能です。
      </Text>
    </Container>
  );
};

export default LoginPage;
