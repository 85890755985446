import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ path }) => {
  const navigate = useNavigate();

  const onClickBack = () => {
    if (window.history.length > 1) {
      navigate(path || -1);
    } else {
      window.close(); // このコードはユーザーによって開かれたタブで動作しない可能性が高い
    }
  };

  return (
    <>
      <Flex justifyContent="center" mt={4} mb={4}>
        <Button size="sm" colorScheme="gray" onClick={onClickBack}>
          戻る
        </Button>
      </Flex>
      {'　'}
    </>
  );
};

export default BackButton;
