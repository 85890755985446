import React from 'react';
import { Box } from '@chakra-ui/react';

const FormLink = ({ onClick, children }) => (
  <Box
    borderWidth="0px"
    borderRadius="xl"
    overflow="hidden"
    p={4}
    mt={2}
    cursor="pointer"
    onClick={onClick}
    backgroundColor={'#fdfdfd'}
    boxShadow="sm"
  >
    {children}
  </Box>
);

export default FormLink;
