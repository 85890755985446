// export const createEventOnGoogleCalendar = async (event, calendarId) => {
//   try {
//     const response = await gapi.client.calendar.events.insert({
//       'calendarId': calendarId,
//       'resource': event,
//     });
//     console.log('Event created: ', response);
//     return response;
//   } catch (error) {
//     console.error('Error creating event', error);
//     throw new Error('Error creating event');
//   }
// };

const addEventToGoogleCalendar = async (
  currentUser,
  category,
  page,
  startDateTime,
  endDateTime,
  studio
) => {
  const now = new Date();
  const nowStr = now.toISOString();
  const calendarEvent = {
    calendarId: `${studio.slug}@studio-medy.com`,
    event: {
      summary: `【${category}】${currentUser.fullName}`,
      location: studio.name,
      description: `${currentUser.fullName}が${page}から追加(${nowStr})`,
      colorId: 11,
      start: {
        dateTime: `${startDateTime}:00+09:00`,
        timeZone: 'Asia/Tokyo',
      },
      end: {
        dateTime: `${endDateTime}:00+09:00`,
        timeZone: 'Asia/Tokyo',
      },
    },
  };
  console.log('calendarEvent:', calendarEvent);

  const response = await fetch(
    'https://asia-northeast1-medy-system.cloudfunctions.net/create_google_calendar_event',
    {
      method: 'POST',
      body: JSON.stringify(calendarEvent),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    window.alert('カレンダーに他の予定があります。');
    throw new Error('Googleカレンダーへのイベント追加に失敗しました');
  }

  return await response.json();
};

export { addEventToGoogleCalendar };

// const createEventOnGoogleCalendar = async (event, calendarId) => {
//   // Google Cloud Functionsにリクエストを送信
//   try {
//     const response = await fetch('YOUR_CLOUD_FUNCTION_URL', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         // 必要に応じてトークンを使用
//         // 'Authorization': `Bearer ${token}`,
//       },
//       body: JSON.stringify({
//         event: event,
//         calendarId: calendarId, // ここにカレンダーIDを設定
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Event creation failed');
//     }

//     const responseData = await response.json();
//     console.log('Event created:', responseData);
//   } catch (error) {
//     console.error('Error creating event:', error);
//   }
// };

// gapi.client.calendar.events.insert({
//   'calendarId': 'your_calendar_id@group.calendar.google.com', // 実際のカレンダーIDに置き換えてください
//   'resource': {
//     'summary': '予約タイトル',
//     'location': '会場',
//     'description': 'イベントの説明',
//     'start': {
//       'dateTime': '2023-01-01T09:00:00',
//       'timeZone': 'Asia/Tokyo',
//     },
//     'end': {
//       'dateTime': '2023-01-01T10:00:00',
//       'timeZone': 'Asia/Tokyo',
//     },
//     // その他のイベント詳細
//   }
// }).then(function(response) {
//   console.log('イベントを追加しました', response);
// }, function(reason) {
//   console.error('エラー: ' + reason.result.error.message);
// });

// export const updateEventOnGoogleCalendar = async (eventId, event, calendarId) => {
//   try {
//     const response = await gapi.client.calendar.events.update({
//       'calendarId': calendarId,
//       'eventId': eventId,
//       'resource': event,
//     });
//     console.log('Event updated: ', response);
//     return response;
//   } catch (error) {
//     console.error('Error updating event', error);
//     throw new Error('Error updating event');
//   }
// };

// export const deleteEventOnGoogleCalendar = async (eventId, calendarId) => {
//   try {
//     const response = await gapi.client.calendar.events.delete({
//       'calendarId': calendarId,
//       'eventId': eventId,
//     });
//     console.log('Event deleted: ', response);
//     return response;
//   } catch (error) {
//     console.error('Error deleting event', error);
//     throw new Error('Error deleting event');
//   }
// };
