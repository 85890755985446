import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Container, VStack } from '@chakra-ui/react';
import { firestore, collection, addDoc } from '../firebaseConfig';

const AddStudioPage = () => {
  const [name, setName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [calendarUrl, setCalendarUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(collection(firestore, "studios"), {
      name,
      postalCode,
      address,
      calendarUrl,
    });
    // フォームをクリア
    setName('');
    setPostalCode('');
    setAddress('');
    setCalendarUrl('');
    alert('スタジオが追加されました！');
  };

  return (
    <Container backgroundColor={"#ede9e5"} borderRadius={"10"} mt={4} pt={8}>
      <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>スタジオ名</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>郵便番号</FormLabel>
              <Input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>住所</FormLabel>
              <Input value={address} onChange={(e) => setAddress(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>連携カレンダーURL</FormLabel>
              <Input value={calendarUrl} onChange={(e) => setCalendarUrl(e.target.value)} />
            </FormControl>
            <Button colorScheme="blue" type="submit">追加</Button>
          </VStack>
        </form>
      </Box>
    </Container>
  );
};

export default AddStudioPage;
