import React, { useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { doc, updateDoc, getDoc } from 'firebase/firestore'; // getDoc を追加
import { firestore } from '../firebaseConfig'; // 分離
import { sendDiscordNotification } from '../utils/discord';

const InitialPaymentSuccessPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const updateUserInfo = async () => {
      if (currentUser) {
        // ユーザードキュメントのIDがFirebase AuthのUIDに直接対応していると仮定
        const userRef = doc(firestore, 'users', currentUser.uid);
        try {
          await updateDoc(userRef, {
            initial: true,
          });
          console.log('User initial status updated successfully.');

          // 予約データを取得
          const reservationRef = doc(
            firestore,
            'reservations',
            currentUser.trialId
          );
          const reservationDoc = await getDoc(reservationRef);
          const reservationData = reservationDoc.data();

          // インストラクターの情報を取得
          const instructorRef = doc(
            firestore,
            'users',
            reservationData.instructorId
          );
          const instructorDoc = await getDoc(instructorRef);
          const instructorData = instructorDoc.data();

          const price =
            40000 * (1 - (currentUser?.initial_discount || 0) / 100);

          const embeds = [
            {
              title: 'medy入会費用',
              description: `入会金がお支払いされました。`,
              color: 5814783,
              fields: [
                {
                  name: '売上',
                  value: `${price.toLocaleString()}円`,
                  inline: true,
                },
                {
                  name: '購入者',
                  value: currentUser?.fullName,
                  inline: true,
                },
                {
                  name: '入会対応',
                  value: instructorData?.fullName || '不明',
                  inline: true,
                },
              ],
              footer: {
                text: '購入日時',
              },
              timestamp: new Date().toISOString(),
            },
          ];

          await sendDiscordNotification('payment', embeds);

          const embeds_studio = [
            {
              title: 'medy入会のお知らせ',
              description: `お客様の入会が確定しました🎉`,
              color: 5814783,
              fields: [
                {
                  name: '氏名',
                  value: currentUser?.fullName,
                  inline: true,
                },
                {
                  name: '経路',
                  value: currentUser?.path,
                  inline: true,
                },
                {
                  name: '担当インストラクター',
                  value: instructorData?.fullName || '不明',
                  inline: true,
                },
              ],
              footer: {
                text: '入会日時',
              },
              timestamp: new Date().toISOString(),
            },
          ];

          await sendDiscordNotification(currentUser.studio.slug, embeds_studio);

          navigate('/before', { state: { reload: true } });
        } catch (error) {
          console.error('Error updating initial status:', error);

          const embeds = [
            {
              title: '入会金の支払い後のエラー',
              description: `入会金はお支払いされましたが、ユーザー情報の更新に失敗しました。`,
              color: 16711680,
              fields: [
                {
                  name: '対象者',
                  value: currentUser?.fullName,
                  inline: true,
                },
              ],
              footer: {
                text: 'USER ID:' + currentUser?.uid,
              },
              timestamp: new Date().toISOString(),
            },
          ];
          await sendDiscordNotification('payment', embeds);
          navigate('/before', { state: { reload: true } });
        }
      } else {
        console.log('User is not authenticated.');
        // ここでログインページへのリダイレクト等の処理を追加
        navigate('/login');
      }
    };
    updateUserInfo();
  }, [currentUser, navigate]);

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>お支払い成功🎉</title>
      </Helmet>
      <Heading
        as="h1"
        size="xs"
        textAlign="center"
        mt="12px"
        mb="20px"
        color="gray.600"
      >
        待機画面
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          次のステップの準備中です...
        </Text>
        {/* <Image
          src="/waiting.jpg"
          alt="メンテナンス中のイラスト"
          borderRadius="md"
        /> */}
        <Text mt={4} fontSize={'xs'} color="gray.500" textAlign={'center'}>
          少々お待ちくださいませ。
        </Text>
      </Box>
    </Container>
  );
};

export default InitialPaymentSuccessPage;
