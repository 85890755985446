import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  firestore,
  doc,
  setDoc,
  collection,
  onSnapshot,
  arrayUnion,
} from '../firebaseConfig';
import {
  Container,
  Box,
  Text,
  Button,
  Input,
  VStack,
  useToast,
  FormLabel,
  Flex,
  InputGroup,
  InputRightElement,
  Select,
} from '@chakra-ui/react';
import Logo from './Logo';

const SignUpPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [studios, setStudios] = useState([]);

  const [studioId, setStudioId] = useState('');
  const [studioName, setStudioName] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [fullKana, setFullKana] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sex, setSex] = useState('female'); // 性別用の状態を追加
  const [path, setPath] = useState('instagram'); // 性別用の状態を追加
  const toast = useToast();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // メールアドレスの形式をチェックする関数
  const isValidEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // 電話番号が半角数字のみかチェックする関数
  const isValidPhoneNumber = phone => {
    return /^\d+$/.test(phone);
  };

  // 全角数字を半角数字に変換し、非数字以外を削除しない関数
  const normalizePhoneNumber = input => {
    // 全角数字を半角に変換
    const fullWidthToHalfWidth = input.replace(/[０-９]/g, s => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    // 非数字以外の文字を保持
    return fullWidthToHalfWidth.replace(/[^\d]/g, '');
  };

  const setSelectedStudio = id => {
    setStudioId(id);
    const selectedStudio = studios.find(s => s.id === id);
    console.log('selectedStudio: ');
    console.log(selectedStudio);
    setStudioName(selectedStudio?.name);
  };

  const signUpWithEmailAndPassword = async e => {
    e.preventDefault();
    // パスワードの長さチェック
    if (password.length < 8) {
      toast({
        title: 'エラー',
        description: 'パスワードは8文字以上で設定してください。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // パスワードの一致確認
    if (password !== confirmPassword) {
      toast({
        title: 'エラー',
        description: 'パスワードが一致しません。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // メールアドレスの形式をチェック
    if (!isValidEmail(email)) {
      toast({
        title: 'エラー',
        description: '無効なメールアドレスです。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // 電話番号のバリデーションチェック
    if (!isValidPhoneNumber(phoneNumber)) {
      toast({
        title: 'エラー',
        description: '電話番号は半角数字のみで入力してください。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // 名前の入力チェック
    if (fullName === '') {
      toast({
        title: 'エラー',
        description: '名前を入力してください。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // ふりがなの入力チェック
    if (fullKana === '') {
      toast({
        title: 'エラー',
        description: 'ふりがなを入力してください。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    // 経路の入力チェック
    if (path === '') {
      toast({
        title: 'エラー',
        description: '経路を選択してください。',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await sendEmailVerification(user);
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(
        userRef,
        {
          fullName: fullName,
          fullKana: fullKana,
          displayName: '',
          phoneNumber: phoneNumber,
          email: email,
          studioId: studioId,
          belongStudioIds: { [studioId]: studioName.slice(5) },
          manageStudioIds: [],
          workingStudioIds: [],
          status: false,
          instructor: false,
          initial: false,
          initial_discount: 100,
          trial_discount: 50,
          plan: 0,
          sex: sex,
          fixies: [],
          allowEmail: true,
          login: false,
          active: true,
          path: path,
          createdAt: new Date(),
          updatedAt: new Date(),
        },
        { merge: true }
      ); // merge: trueでドキュメントにデータを追加

      // スタジオドキュメントの参照を取得
      const studioRef = doc(firestore, 'studios', studioId);

      // スタジオドキュメントにユーザーIDを追加
      await setDoc(
        studioRef,
        {
          users: arrayUnion(userRef),
        },
        { merge: true }
      );

      toast({
        title: 'アカウント作成成功',
        description: 'サインアップに成功しました。',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/login', { state: { email } });
    } catch (error) {
      const errorMessageMap = {
        'auth/email-already-in-use':
          'このメールアドレスは既に使用されています。',
        'auth/invalid-email': 'メールアドレスが無効です。',
        'auth/operation-not-allowed': 'この操作は許可されていません。',
        'auth/weak-password': 'パスワードが弱すぎます。',
        // 他のエラーコードに対する翻訳もここに追加
      };
      const translatedMessage =
        errorMessageMap[error.code] || '未定義のエラーが発生しました。';
      toast({
        title: 'アカウント作成エラー',
        description: translatedMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const studiosCollection = collection(firestore, 'studios');
    const unsubscribe = onSnapshot(
      studiosCollection,
      snapshot => {
        const studiosArray = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        const openStudiosArray = studiosArray.filter(
          studio => studio.open === true
        );
        setStudios(openStudiosArray);
        setStudioId(openStudiosArray[0]?.id);
        setStudioName(openStudiosArray[0]?.name);
      },
      error => {
        console.error('Error fetching studios:', error);
      }
    );

    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   // ローカルストレージから'confirm'の値を取得
  //   const confirm = localStorage.getItem('confirm');

  //   // 'confirm'がtrueでない場合、/confirmにリダイレクト
  //   if (confirm !== 'true') {
  //     navigate('/confirm');
  //   }
  // }, [navigate]);

  if (currentUser && currentUser.login) {
    navigate('/home');
  }

  return (
    <>
      <Container
        backgroundColor={'#ede9e5'}
        borderRadius={'10'}
        mt={4}
        pt={8}
        pb={4}
      >
        <Logo text="ご入会の手続き" />
        <Box
          borderWidth="0px"
          borderRadius="xl"
          overflow="hidden"
          p={4}
          mt={4}
          backgroundColor={'#fdfdfd'}
        >
          <Box width="100%" maxW="400px" mx="auto" mt="0%">
            <VStack spacing="0px">
              <form
                onSubmit={signUpWithEmailAndPassword}
                style={{ width: '100%' }}
              >
                <VStack spacing="12px">
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      スタジオ名
                    </FormLabel>
                    <Select
                      value={studioId}
                      onChange={e => setSelectedStudio(e.target.value)}
                    >
                      {studios?.map(s => (
                        <option key={s?.id} value={s?.id}>
                          {s?.name}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      フルネーム（漢字）
                    </FormLabel>
                    <Input
                      placeholder="フルネーム"
                      value={fullName}
                      onChange={e => setFullName(e.target.value)}
                    />
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      フルネーム（ひらがな）
                    </FormLabel>
                    <Input
                      placeholder="フルネーム（かな）"
                      value={fullKana}
                      onChange={e => setFullKana(e.target.value)}
                    />
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel htmlFor="sex" fontSize="12" mb="1">
                      性別
                    </FormLabel>
                    <Select
                      id="sex"
                      placeholder="身体の性別を選択してください"
                      value={sex}
                      onChange={e => setSex(e.target.value)}
                    >
                      <option value="male">男性</option>
                      <option value="female">女性</option>
                    </Select>
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      電話番号（半角数字のみ）
                    </FormLabel>
                    <Input
                      placeholder="電話番号"
                      value={phoneNumber}
                      onChange={e =>
                        setPhoneNumber(normalizePhoneNumber(e.target.value))
                      }
                    />
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      メールアドレス
                    </FormLabel>
                    <Input
                      placeholder="メールアドレス"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      パスワード（８文字以上）
                    </FormLabel>
                    <InputGroup>
                      <Input
                        pr="4.5rem"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="パスワード"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? '隠す' : '見る'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    alignItems="start"
                    width="full"
                    mt=""
                  >
                    <FormLabel fontSize="12" mb="1">
                      パスワードの確認
                    </FormLabel>
                    <InputGroup>
                      <Input
                        pr="4.5rem"
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="パスワードの確認"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? '隠す' : '見る'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      ご入会の経緯
                    </FormLabel>
                    <Select
                      value={path}
                      onChange={e => setPath(e.target.value)}
                    >
                      <option key="instagram" value="Instagram">
                        Instagram
                      </option>
                      <option key="signborad" value="看板">
                        看板
                      </option>
                      <option key="leaflet" value="チラシ">
                        チラシ
                      </option>
                      <option key="search" value="ウェブ検索">
                        ウェブ検索
                      </option>
                      <option key="search" value="Googleマップ">
                        Googleマップ
                      </option>
                      <option key="friend" value="友人の紹介">
                        友人の紹介
                      </option>
                    </Select>
                  </Flex>
                  <Text fontSize="sm" my="2" textAlign="center">
                    <a
                      href="/terms-of-service"
                      style={{ textDecoration: 'underline', color: '#565656' }}
                      target={{ blank: true }}
                    >
                      サービスご利用規約
                    </a>
                  </Text>
                  <Button type="submit" colorScheme="blue" width="full">
                    規約に同意してmedyに入会する
                  </Button>
                </VStack>
              </form>
            </VStack>
          </Box>
          <Text fontSize="sm" mt="8" mb="4" textAlign="center">
            <a
              href="/login"
              style={{ textDecoration: 'underline', color: '#565656' }}
            >
              会員の方はこちら
            </a>
          </Text>
        </Box>
      </Container>
      {'　'}
    </>
  );
};

export default SignUpPage;
