// No Use

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Text,
  Container,
  Flex,
  Button,
  Input,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import LoadingModal from '../components/LoadingModal';
import Logo from './Logo';
import {
  firestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from '../firebaseConfig';
import { sendDiscordNotification } from '../utils/discord';

const AddReservation = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [weekday, setWeekday] = useState('');
  const [hour, setHour] = useState('');
  const [reserving, setReserving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');
  const [unavailableHours, setUnavailableHours] = useState([]);
  const [fixedUnavailableHours, setFixedUnavailableHours] = useState([]);
  const [calendarUnavailableHours, setCalendarUnavailableHours] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 日付と時間を組み合わせてISO形式の文字列に変換
  const combineDateTime = (date, hour) => {
    return `${date}T${hour}`;
  };

  const updateWeekdayAndHour = useCallback(
    async selectedDate => {
      const dateObj = new Date(selectedDate);
      const weekdayStr = ['日', '月', '火', '水', '木', '金', '土'][
        dateObj.getDay()
      ];
      setWeekday(weekdayStr);
      console.log('weekdayStr:', weekdayStr);

      const openingTime = currentUser?.studio.openingTimes?.find(
        time => time.weekday === weekdayStr
      );
      if (openingTime) {
        setHour(
          `${
            openingTime.startAt < 10
              ? `0${openingTime.startAt}`
              : openingTime.startAt
          }:00`
        );
      }
      // 選択された曜日に基づいて開店時間と閉店時間を確認
      const openingTimeForDay = currentUser?.studio.openingTimes.find(
        time => time.weekday === weekdayStr
      );
      const isOpenAllDay =
        openingTimeForDay?.startAt === openingTimeForDay?.closeAt &&
        openingTimeForDay.startAt === 0;
      setPlaceholderText(
        isOpenAllDay ? '定休日です😌' : 'ご希望の時間を選んで下さい🤲'
      );
    },
    [currentUser.studio.openingTimes]
  );

  const fetchCalendarEvents = useCallback(
    async selectedDate => {
      setFetching(true);
      try {
        if (placeholderText === '定休日です😌') return;
        const response = await fetch(
          'https://asia-northeast1-medy-system.cloudfunctions.net/read_google_calendar_event',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              calendarId: 'hatagaya@studio-medy.com',
              date: selectedDate,
            }),
          }
        );

        if (!response.ok) {
          throw new Error('Calendar events fetch failed');
        }

        const events = await response.json();
        const newUnavailableHours = [];
        events.forEach(event => {
          const startHour = new Date(event.start.dateTime).getHours();
          const endHour = new Date(event.end.dateTime).getHours();

          // イベントの開始時間から終了時間までの全ての時間を追加
          for (let hour = startHour; hour < endHour; hour++) {
            if (!newUnavailableHours.includes(hour)) {
              newUnavailableHours.push(hour);
            }
          }
        });
        setFetching(false);
        setCalendarUnavailableHours(newUnavailableHours);
      } catch (error) {
        setFetching(false);
        console.error('イベントの取得に失敗しました:', error);
      }
    },
    [placeholderText]
  );

  const handleDateChange = e => {
    setDate(e.target.value);
    updateWeekdayAndHour(e.target.value);
    fetchCalendarEvents(e.target.value);
  };

  const onChangeHour = value => {
    setHour(value);
  };

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setReserving(true);
      if (!hour || hour === '') {
        alert('日付と時間を選択してください');
        setReserving(false);
        return;
      }
      const startDateTime = combineDateTime(date, hour);
      const endDateTime = combineDateTime(
        date,
        `${parseInt(hour.slice(0, 2)) + 1}:00`
      );

      // トップレベルの`reservations`コレクションへの参照を取得
      const reservationsRef = collection(firestore, 'reservations');

      // `startDate`が`endDateTime`以下であり、`active`が`true`である予約をクエリ
      const q = query(
        reservationsRef,
        where('startDate', '<=', endDateTime),
        where('active', '==', true)
      );

      const querySnapshot = await getDocs(q);
      const conflicts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      // 重複する予約があるかどうかを確認する処理
      const isConflict = conflicts.some(reservation => {
        if (
          startDateTime < reservation.endDate &&
          endDateTime > reservation.startDate
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (isConflict) {
        // 予約が重複している場合、モーダルを表示
        onOpen();
      } else {
        try {
          // ここからGoogleカレンダーへのイベント追加処理
          const now = new Date();
          const nowStr = now.toISOString();
          const calendarEvent = {
            calendarId: `${currentUser?.studio?.slug}@studio-medy.com`, // GoogleカレンダーのID
            event: {
              summary: currentUser?.fullName, // イベントのタイトル
              location: currentUser?.studio?.name, // イベントの場所
              description: `ご本人がアプリから追加（${nowStr}）`, // イベントの説明
              start: {
                dateTime: `${startDateTime}:00+09:00`,
                timeZone: 'Asia/Tokyo',
              },
              end: {
                dateTime: `${endDateTime}:00+09:00`,
                timeZone: 'Asia/Tokyo',
              },
            },
          };

          // Cloud Functionを呼び出してイベントを作成
          const response = await fetch(
            'https://asia-northeast1-medy-system.cloudfunctions.net/create_google_calendar_event',
            {
              method: 'POST',
              body: JSON.stringify(calendarEvent),
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (!response.ok) {
            console.error(
              'Googleカレンダーへのイベント追加に失敗しました',
              response
            );
            return;
          }

          // 最も優先度が高いチケットを選択
          const ticketToUse = currentUser.tickets[0];

          // チケットのusedをtrueに更新
          const userDocRef = doc(firestore, 'users', currentUser.uid);
          const ticketRef = doc(userDocRef, 'tickets', ticketToUse.id);
          await updateDoc(ticketRef, {
            used: true,
          })
            .then(() => {
              console.log('チケットの使用済みフラグをtrueに更新しました。');
            })
            .catch(error => {
              console.error(
                'チケットの使用済みフラグの更新に失敗しました: ',
                error
              );
            });

          const eventData = await response.json();
          const docRef = await addDoc(collection(firestore, 'reservations'), {
            startDate: startDateTime,
            endDate: endDateTime,
            userId: currentUser?.uid,
            studioId: currentUser?.studioId,
            visited: false,
            active: true,
            changed: false,
            type: ticketToUse.type, // 予約の種類をonce, plan, trial, work, blockのいずれかで指定　チケット利用の場合はplanかonce
            ticketId: ticketToUse.id, // チケット利用の場合はチケットのIDを指定
            eventId: eventData.id,
            priority: false,
          });
          const embeds = [
            {
              title: 'ご予約の追加',
              description: `以下の予約が新しく追加されました。`,
              color: 16752762,
              fields: [
                {
                  name: '日時',
                  value: `${startDateTime
                    .replace('-', '年')
                    .replace('-', '月')
                    .replace('T', '日')}`,
                  inline: true,
                },
                {
                  name: '予約者',
                  value: currentUser?.fullName,
                  inline: true,
                },
              ],
              footer: {
                text: 'ご予約承認時刻',
              },
              timestamp: new Date().toISOString(),
            },
          ];
          console.log('予約の追加に成功しました', docRef.id);

          await sendDiscordNotification(
            'https://discord.com/api/webhooks/1217760107249795153/-_iAxvFOjHxrtQHMyzNh3ft8oO6x1NXPeePxO4w9msuBXPZtaPPZQ-nudGMU9R3DSDwr',
            embeds
          );
          setReserving(false);
          navigate('/home', { state: { reload: true } });
        } catch (error) {
          setReserving(false);
          console.error('予約の追加に失敗しました', error);
        }
      }
    },
    [
      date,
      hour,
      onOpen,
      currentUser?.studio?.slug,
      currentUser?.studio?.name,
      currentUser?.fullName,
      currentUser.tickets,
      currentUser.uid,
      currentUser?.studioId,
      navigate,
    ]
  );

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // 明日の日付を設定
    const defaultDate = tomorrow.toISOString().split('T')[0]; // YYYY-MM-DD 形式
    // 来月末を設定
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 2);
    nextMonth.setDate(0);
    setMaxDate(nextMonth.toISOString().split('T')[0]);
    setDate(defaultDate);
    setMinDate(defaultDate);
    updateWeekdayAndHour(defaultDate);
  }, [updateWeekdayAndHour]);

  useEffect(() => {
    const fetchSelectedDateStudioReservationsData = async () => {
      // トップレベルの`reservations`コレクションへの参照を取得
      const reservationsRef = collection(firestore, 'reservations');
      // そのユーザーの所属スタジオidで検索し指定した日付の予約をクエリ
      const q = query(
        reservationsRef,
        where('studioId', '==', currentUser?.studioId),
        where('active', '==', true),
        where('startDate', '>=', date),
        where('startDate', '<', `${date}T23:59`)
      );
      const reservationsSnapshot = await getDocs(q);
      const reservations = reservationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUnavailableHours(
        reservations.map(reservation => {
          const startHour = new Date(reservation.startDate).getHours();
          return startHour;
        })
      );
      // studio.fixiesの中からweekdayに一致するfixieのstartを取得したものを予約不可能時間として追加
      setUnavailableHours(prevHours => [
        ...new Set([...prevHours, ...fixedUnavailableHours]),
      ]);
      // 予約件数とスタジオの予約可能時間数を比較
      if (
        reservations.length !== 0 &&
        reservations.length ===
          currentUser?.studio.openingTimes.find(
            time => time.weekday === weekday
          )?.closeAt -
            currentUser?.studio.openingTimes.find(
              time => time.weekday === weekday
            )?.startAt
      ) {
        setPlaceholderText('満枠になりました🙇‍♂️');
      }
    };
    fetchSelectedDateStudioReservationsData();
    fetchCalendarEvents(date);
    setHour('');
  }, [
    date,
    currentUser?.studioId,
    currentUser?.studio.openingTimes,
    weekday,
    fetchCalendarEvents,
    fixedUnavailableHours,
  ]);

  useEffect(() => {
    if (!currentUser.studio.fixies) {
      return;
    }
    // 曜日が確定したタイミングでスタジオの専用枠からその曜日の専用枠の時（予約不可能な時間）を取得
    setFixedUnavailableHours(
      currentUser.studio.fixies
        .filter(fixie => fixie.weekday === weekday)
        .map(fixie => fixie.start)
    );
  }, [currentUser.studio.fixies, weekday]);

  if (fetching)
    return <LoadingModal text="予約が可能な時間を問い合わせ中..." />;
  if (reserving) return <LoadingModal text="ご予約を作成中..." />;

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="体験レッスン予約ページ" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Box as="form" onSubmit={handleSubmit}>
          <Input
            placeholder="希望する日付"
            type="date"
            min={minDate}
            max={maxDate}
            value={date}
            onChange={handleDateChange}
            mb="4"
          />
          <Select
            placeholder={placeholderText}
            value={hour}
            onChange={e => onChangeHour(e.target.value)}
            mb="4"
          >
            {currentUser?.studio.openingTimes.find(
              time => time.weekday === weekday
            )?.startAt !== undefined &&
              [
                ...Array(
                  currentUser?.studio.openingTimes.find(
                    time => time.weekday === weekday
                  ).closeAt -
                    currentUser?.studio.openingTimes.find(
                      time => time.weekday === weekday
                    ).startAt
                ),
              ].map((_, index) => {
                const hourValue =
                  currentUser?.studio.openingTimes.find(
                    time => time.weekday === weekday
                  ).startAt + index;
                // 既存のロジックに加えて、unavailableHours に含まれない時間のみを選択肢として表示
                if (
                  hourValue <
                    currentUser?.studio.openingTimes.find(
                      time => time.weekday === weekday
                    ).closeAt &&
                  ![
                    ...new Set([
                      ...unavailableHours,
                      ...calendarUnavailableHours,
                    ]),
                  ].includes(hourValue)
                ) {
                  return (
                    <option
                      key={hourValue}
                      value={`${
                        hourValue < 10 ? `0${hourValue}` : hourValue
                      }:00`}
                    >
                      {`${hourValue < 10 ? `0${hourValue}` : hourValue}:00`}
                    </option>
                  );
                }
                return null;
              })}
          </Select>
          <Flex justify="center">
            {calendarUnavailableHours.length !== 0 ||
            placeholderText === 'ご希望の時間を選んで下さい🤲' ? (
              <Button type="submit" colorScheme="telegram">
                予約を追加
              </Button>
            ) : (
              <Button variant={'outlineBlack'} disabled>
                予約できません
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
      <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
        予約の追加が完了するとホームに移動します🙆‍♀️
      </Text>
      <BackButton />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={'x2'} mx={8}>
          <ModalHeader>申し訳ありません🙇‍♂️</ModalHeader>
          <ModalBody>
            他の予定があり予約ができませんでした。別の日時でお試しください。
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="gray" mr={2} onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default AddReservation;
