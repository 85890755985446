import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Button,
  VStack,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import Logo from './Logo';
import BackButton from './BackButton';
import { useAuth } from '../AuthProvider';
import {
  firestore,
  collection,
  getDocs,
  doc,
  updateDoc,
} from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

const StudioChangePage = () => {
  const { currentUser } = useAuth();
  const [studios, setStudios] = useState([]);
  const [selectedStudioId, setSelectedStudioId] = useState('');
  const [loadingStudios, setLoadingStudios] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    // スタジオ一覧を取得
    const fetchStudios = async () => {
      try {
        const studiosCollection = collection(firestore, 'studios');
        const studiosSnapshot = await getDocs(studiosCollection);
        const studiosList = studiosSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudios(studiosList);
      } catch (error) {
        console.error('スタジオの取得に失敗しました', error);
        setError('スタジオの取得に失敗しました。');
      } finally {
        setLoadingStudios(false);
      }
    };
    fetchStudios();
  }, []);

  useEffect(() => {
    // デフォルトのスタジオを設定
    if (currentUser && currentUser.studioId) {
      setSelectedStudioId(currentUser.studioId);
    }
  }, [currentUser]);

  const handleStudioChange = value => {
    setSelectedStudioId(value);
  };

  const handleSave = async () => {
    setSaving(true);
    setError('');
    try {
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        studioId: selectedStudioId,
      });
      // トップページにナビゲートしてリロード
      toast({
        title: 'スタジオを更新しました。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/home', { state: { reload: true } });
    } catch (error) {
      console.error('スタジオの更新に失敗しました', error);
      setError('スタジオの更新に失敗しました。');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb={8} // フッターの高さ分のパディングを追加
      maxW="md" // コンテナの最大幅を設定
    >
      <VStack spacing={6}>
        <Logo text="スタジオ変更" />
        <Box
          borderWidth="1px"
          borderRadius="xl"
          overflow="hidden"
          p={6}
          width="100%"
          backgroundColor={'#fdfdfd'}
          boxShadow="sm"
        >
          <VStack spacing={6} align="stretch">
            <Text fontSize="lg" textAlign="center" fontWeight="bold">
              所属するスタジオを選択してください
            </Text>
            {error && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            )}
            {loadingStudios ? (
              <Flex justify="center">
                <Spinner size="lg" />
              </Flex>
            ) : studios.length > 0 ? (
              <RadioGroup
                onChange={handleStudioChange}
                value={selectedStudioId}
              >
                <Stack direction="column">
                  {studios.map(studio => (
                    <Radio key={studio.id} value={studio.id}>
                      {studio.name}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            ) : (
              <Text>スタジオ情報がありません。</Text>
            )}
            <Button
              onClick={handleSave}
              colorScheme="blue"
              width="full"
              isLoading={saving}
              loadingText="保存中"
              disabled={!selectedStudioId || saving}
            >
              適用
            </Button>
          </VStack>
        </Box>
        <BackButton />
      </VStack>
    </Container>
  );
};

export default StudioChangePage;
