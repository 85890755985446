import React from 'react';
import { useAuth } from '../AuthProvider';
import { Box, Container, Heading, Button } from '@chakra-ui/react';
import BackButton from './BackButton';

const ChangeReservation = () => {
  const { currentUser } = useAuth();

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="0px" mb="20px">
        medy予約の変更について
      </Heading>
      <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
        <div>
          <p>
            <span style={{ color: 'red' }}>
              予約の変更およびキャンセルは、予約日の前日２１時まで可能です。
            </span>
          </p>
          <br />
          <a
            href={`
          https://docs.google.com/forms/d/e/1FAIpQLSce2XInbPwTbMJMEOnR3K_hLb_981kRqWbCorEnl41UZdB5Pg/viewform?usp=pp_url&entry.67110184=${
            currentUser?.studio?.name
          }&entry.1785760527=${currentUser?.fullName}&entry.1607495935=${
              currentUser?.email
            }&entry.1600834792=${'2024-03-15'}&entry.579186326=${'10:00'}&entry.305041048=`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button colorScheme="blue" width="full">
              予約を変更・キャンセルする
            </Button>
          </a>
          <br />
        </div>
      </Box>
      <BackButton />
      <Box overflow="hidden" p={4} mt={4} mb={4}>
        <p style={{ fontSize: 12, color: 'gray' }}>
          <b>
            期限を過ぎますと、翌日あるいは当日のレッスンは時間通り行われるものとして費用もかかってしまいます。
          </b>
          十分にご注意ください。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          期限のある理由としましては、システムによるインストラクターへの予定通知や支払い関係のレッスン数計算等に影響が出るためです。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          また、medyは幸いにもみなさまのご愛顧を賜っております人気のスタジオです。そのため、変更に伴って、予約を取り直したい日時に予約ができない可能性もございます。
          <b>
            変更・キャンセルは十分に余裕を持って行っていただくようにお願いいたします。
          </b>
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          病気や事故などやむを得ない事情のある予約の変更の場合はフォームに理由をお書きください。３〜４営業日以内に担当者が誠実に対応いたします。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          変更・キャンセル期限を過ぎた状態でインストラクターに個別に相談いただいても
          <b>インストラクターに期日超過後の変更権限がない</b>
          ため、対応が難しい点についてご理解のほど重ねてよろしくお願いいたします。
        </p>
      </Box>
      {'　'}
    </Container>
  );
};

export default ChangeReservation;
