import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../AuthProvider';
import { v4 as uuid } from 'uuid';
import { Box, Container, Text, Badge, useDisclosure } from '@chakra-ui/react';
import BackButton from './BackButton';
import LoadingModal from '../components/LoadingModal';
import Logo from './Logo';
import { handleWaitExecFunction } from '../utils/loading';
import {
  firestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  onSnapshot,
} from '../firebaseConfig';

const Payments = () => {
  const { currentUser } = useAuth();
  const [onceProducts, setOnceProducts] = useState({});
  const [oncePrices, setOncePrices] = useState({});
  const [fetching, setFetching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const price = {
    female: {
      0: '9,900',
      2: '9,350',
      4: '8,800',
      6: '8,250',
      8: '7,700',
    },
    male: {
      0: '11,000',
      2: '10,450',
      4: '9,900',
      6: '9,350',
      8: '8,800',
    },
  };

  // 無期限チケットの価格を取得するための関数
  const getOnceTicketPrice = () => {
    if (currentUser.sex && price[currentUser.sex]) {
      return price[currentUser.sex][0];
    }
    return ''; // デフォルトメッセージ
  };

  // プランチケットの価格を取得するための関数
  const getPlanTicketPrice = () => {
    if (
      currentUser.sex &&
      price[currentUser.sex] &&
      typeof currentUser.plan !== 'undefined' &&
      price[currentUser.sex][currentUser.plan] !== undefined
    ) {
      return price[currentUser.sex][currentUser.plan];
    }
    return ''; // デフォルトメッセージ
  };

  const createCheckoutSession = useCallback(async () => {
    if (!currentUser || !oncePrices[0][0]?.id) return;

    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'payment',
        line_items: [{ price: oncePrices[0][0]?.id, quantity: 1 }],
        success_url: window.location.origin + '/once-payment-success',
        cancel_url: window.location.origin + '/payments',
      });

      // FirestoreのonSnapshotを使用して、セッションのURLがセットされたか監視し、ステートを更新
      const unsubscribe = onSnapshot(checkoutSessionRef, doc => {
        const { url } = doc.data();
        if (url) {
          localStorage.setItem('checkoutSessionUrl', url);
          window.location.assign(url);
        }
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Checkout session creation failed:', error);
    }
  }, [oncePrices, currentUser]);

  const createCheckoutSession2 = useCallback(async () => {
    if (!currentUser || !oncePrices[1][0]?.id) return;

    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'payment',
        line_items: [{ price: oncePrices[1][0]?.id, quantity: 1 }],
        success_url: window.location.origin + '/plan-ticket-payment-success',
        cancel_url: window.location.origin + '/payments',
      });

      // FirestoreのonSnapshotを使用して、セッションのURLがセットされたか監視し、ステートを更新
      const unsubscribe = onSnapshot(checkoutSessionRef, doc => {
        const { url } = doc.data();
        if (url) {
          localStorage.setItem('checkoutSessionUrl', url);
          window.location.assign(url);
        }
      });

      return () => unsubscribe();
    } catch (error) {
      console.error('Checkout session creation failed:', error);
    }
  }, [oncePrices, currentUser]);

  useEffect(() => {
    const fetchOnceProducts = async () => {
      setFetching(true);
      const productsCollectionRef = collection(firestore, 'products');
      const q = query(
        productsCollectionRef,
        where('active', '==', true),
        where('stripe_metadata_type', '==', 'live'),
        where('stripe_metadata_category', '==', 'once')
      );
      const querySnapshot = await getDocs(q);
      const productsData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          const pricesCollectionRef = collection(
            firestore,
            `products/${doc.id}/prices`
          );
          const priceQuerySnapshot = await getDocs(pricesCollectionRef);
          productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));
          return productData;
        })
      );
      console.log(productsData);
      setOnceProducts(productsData);

      const matchingOncePrices = productsData.map(product =>
        product.prices.filter(price => price.metadata.sex === currentUser?.sex)
      );
      console.log(matchingOncePrices);
      let matchingOncePrice1 = matchingOncePrices[1];
      let matchingOncePrice2 = matchingOncePrices[0].filter(
        price => Number(price.metadata.plan) === currentUser?.plan
      );
      console.log([matchingOncePrice1, matchingOncePrice2]);
      setOncePrices([matchingOncePrice1, matchingOncePrice2]);
      setFetching(false);
    };
    fetchOnceProducts();
  }, [currentUser]);

  if (fetching) return <LoadingModal text="情報を取得中..." />;

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="お会計ページ" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text textAlign={'center'}>
          <b>以下からチケットの追加が可能です🌟</b>
        </Text>
        <Box
          borderWidth="1px"
          borderRadius="xl"
          overflow="hidden"
          p={4}
          mt={4}
          onClick={() =>
            handleWaitExecFunction(createCheckoutSession, onOpen, onClose)
          }
        >
          <Text>
            {onceProducts[1]?.name}1枚　
            <Badge px={2} py={1} mb={1}>
              {getOnceTicketPrice()}円
            </Badge>
          </Text>
        </Box>
        {currentUser?.plan === 0 ? null : (
          <Box
            borderWidth="1px"
            borderRadius="xl"
            overflow="hidden"
            p={4}
            mt={4}
            onClick={() =>
              handleWaitExecFunction(createCheckoutSession2, onOpen, onClose)
            }
          >
            <Text>
              {onceProducts[0]?.name}1枚　
              <Badge px={2} py={1} mb={1}>
                {getPlanTicketPrice()}円
              </Badge>
            </Text>
          </Box>
        )}
      </Box>
      <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
        メニューをタップすると決済ページに移動します🙆‍♀️
      </Text>
      <BackButton path="/home" />
      <LoadingModal
        isOpen={isOpen}
        onClose={onClose}
        text="決済リンク発行中..."
      />
    </Container>
  );
};

export default Payments;
