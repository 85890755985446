import React from 'react';
import { Container, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { routing } from './routes';

function App() {
  const { currentUser } = useAuth();

  return (
    <Router>
      <Flex direction="column" minH="100vh">
        <Container maxW="container.xl" h="100vh">
          <Routes>{routing(!!currentUser)}</Routes>
          {'　'}
        </Container>
      </Flex>
    </Router>
  );
}

export default App;
