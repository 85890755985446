import React, { useState, useEffect, useCallback } from 'react';
import {
  Text,
  Container,
  Button,
  Box,
  Input,
  VStack,
  Select,
  FormLabel,
  Flex,
  useToast,
  HStack,
  Circle,
  InputGroup,
  InputRightElement,
  Table,
  Tbody,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';
import {
  firestore,
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
  getDoc,
} from '../firebaseConfig';
import { useAuth } from '../AuthProvider';
// import LoadingModal from './LoadingModal'; // Ensure this component is a full-screen modal
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const Before = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  // Initialize states
  const [step, setStep] = useState(Number(localStorage.getItem('step')) || 1);
  const [completedSteps, setCompletedSteps] = useState(
    JSON.parse(localStorage.getItem('completedSteps')) || [
      false,
      false,
      false,
      false,
      false,
    ]
  );
  const [enrollmentDiscount, setEnrollmentDiscount] = useState(
    Number(localStorage.getItem('enrollmentDiscount')) || 0
  );
  const [couponCode, setCouponCode] = useState(
    localStorage.getItem('couponCode') || ''
  );
  const [ticketQuantity, setTicketQuantity] = useState(
    Number(localStorage.getItem('ticketQuantity')) || 0
  );
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const storedPlan = localStorage.getItem('selectedPlan');
    return storedPlan !== null ? Number(storedPlan) : 8;
  });
  const [isMedayCouponApplied, setIsMedayCouponApplied] = useState(() => {
    return localStorage.getItem('couponCode')?.toLowerCase() === 'medy';
  });
  const [preparing, setPreparing] = useState(false);

  const [planPrice, setPlanPrice] = useState({});
  const [coupons, setCoupons] = useState({});

  // Price settings
  const INITIAL_PRICE = 40000;
  const TICKET_PRICE = {
    8: 7700,
    6: 8250,
    4: 8800,
    2: 8800,
    0: 9900,
  };
  const PLAN_MENU = {
    8: '月８',
    6: '月６',
    4: '月４',
    2: '月２',
    0: '都度',
  };
  const PLAN_DESCRIPTION = {
    8: '通常9,900円のレッスンが7,700円です✨',
    6: '週1は専用枠で通い、2回は都度予約です😊',
    4: '効果の実感には最低でも週1は必要です🙌',
    2: '特別適用：月2プランでお得に通えます🎉',
    0: 'アカウント維持に月額550円必要です👌',
  };

  // Persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem('step', step);
  }, [step]);

  useEffect(() => {
    localStorage.setItem('completedSteps', JSON.stringify(completedSteps));
  }, [completedSteps]);

  useEffect(() => {
    localStorage.setItem('enrollmentDiscount', enrollmentDiscount);
  }, [enrollmentDiscount]);

  useEffect(() => {
    localStorage.setItem('couponCode', couponCode);
  }, [couponCode]);

  useEffect(() => {
    localStorage.setItem('ticketQuantity', ticketQuantity);
  }, [ticketQuantity]);

  useEffect(() => {
    localStorage.setItem('selectedPlan', selectedPlan);
  }, [selectedPlan]);

  // Update isMedayCouponApplied based on couponCode
  useEffect(() => {
    setIsMedayCouponApplied(couponCode.toLowerCase() === 'medy');
  }, [couponCode]);

  // Handle plan changes
  useEffect(() => {
    if (selectedPlan === 0) {
      setTicketQuantity(1);
      localStorage.setItem('ticketQuantity', '1');
    }
  }, [selectedPlan]);

  // Validate "月2プラン" selection
  useEffect(() => {
    if (selectedPlan === 2 && !isMedayCouponApplied) {
      setSelectedPlan(8);
      toast({
        title: 'プラン変更',
        description:
          '「月2プラン」は「medy」クーポン適用時のみ選択可能です。デフォルトプランに戻ります。',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [selectedPlan, isMedayCouponApplied, toast]);

  // Prevent ticket quantity reset when navigating back from payment
  useEffect(() => {
    const storedTicketQuantity = Number(localStorage.getItem('ticketQuantity'));
    if (storedTicketQuantity && ticketQuantity !== storedTicketQuantity) {
      setTicketQuantity(storedTicketQuantity);
    }
  }, [ticketQuantity]);

  // Handle URL query parameters for payment success or cancellation
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const handleSuccess4 = async () => {
      setPreparing(true);
      try {
        // Update user's initial field
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          initial: true,
        });

        // Mark step 4 as completed
        setCompletedSteps(prev => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[3] = true;
          localStorage.setItem(
            'completedSteps',
            JSON.stringify(newCompletedSteps)
          );
          return newCompletedSteps;
        });

        // Add tickets
        const expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 1);
        expireDate.setDate(0);
        const ticketData = {
          expire: expireDate,
          type: 'plan',
          used: false,
        };
        for (let i = 0; i < ticketQuantity; i++) {
          const ticketId = uuid();
          await setDoc(
            doc(firestore, `users/${currentUser.uid}/tickets`, ticketId),
            ticketData
          );
        }

        // Clear URL parameters
        const url = new URL(window.location);
        url.searchParams.delete('success4');
        window.history.replaceState({}, document.title, url.toString());

        toast({
          title: 'お支払い完了',
          description: '初回のお支払いが完了しました。次のステップに進みます。',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        localStorage.removeItem('checkoutSessionUrl4');
        setStep(5);
      } catch (error) {
        console.error('Error handling success4:', error);
        toast({
          title: 'エラー',
          description: '処理中にエラーが発生しました。',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setPreparing(false);
      }
    };

    const handleSuccess5 = async () => {
      setPreparing(true);
      try {
        // Update user's status field
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          status: true,
        });

        // Mark step 5 as completed
        setCompletedSteps(prev => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[4] = true;
          localStorage.setItem(
            'completedSteps',
            JSON.stringify(newCompletedSteps)
          );
          return newCompletedSteps;
        });

        toast({
          title: '登録完了',
          description: 'サブスクリプションの登録が完了しました。',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        localStorage.removeItem('checkoutSessionUrl5');
      } catch (error) {
        console.error('Error handling success5:', error);
        toast({
          title: 'エラー',
          description: '処理中にエラーが発生しました。',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setPreparing(false);
      }
    };

    if (queryParams.get('success4')) {
      handleSuccess4();
    } else if (queryParams.get('success5')) {
      handleSuccess5();
    } else if (queryParams.get('canceled')) {
      localStorage.removeItem('checkoutSessionUrl4');
      localStorage.removeItem('checkoutSessionUrl5');
      toast({
        title: 'お支払いキャンセル',
        description: 'お支払いがキャンセルされました。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast, currentUser, ticketQuantity, selectedPlan]);

  // Function to set ticket quantity
  const setTicket = value => {
    setTicketQuantity(value);
    localStorage.setItem('ticketQuantity', value);
  };

  // Apply coupon function
  const applyCoupon = () => {
    const discount = coupons[couponCode.toLowerCase()];
    if (discount) {
      setEnrollmentDiscount(discount);
      toast({
        title: 'クーポン適用',
        description:
          couponCode.toLowerCase() === 'medy'
            ? '「medy」クーポンが適用されました。月2プランが選択可能です！'
            : `${discount}%の割引が適用されました。`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: '無効なクーポン',
        description: '無効なクーポンコードです。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setEnrollmentDiscount(0);
    }
  };

  // Select plan function
  const selectPlan = async value => {
    const numericValue = Number(value);
    setSelectedPlan(numericValue);
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    await updateDoc(userDocRef, {
      plan: numericValue,
    });
    if (numericValue === 0 && ticketQuantity !== 1) {
      setTicketQuantity(1);
    }
    localStorage.setItem('selectedPlan', numericValue);
  };

  // Handle payment function
  const handlePayment = async () => {
    setPreparing(true); // Start preparing
    try {
      const discountedEnrollmentFee =
        INITIAL_PRICE * (1 - enrollmentDiscount / 100);
      const totalTicketPrice = TICKET_PRICE[selectedPlan] * ticketQuantity;
      const additionalAmount = selectedPlan === 0 ? 550 : 0;
      const totalAmount =
        discountedEnrollmentFee + totalTicketPrice + additionalAmount;

      if (totalAmount === 0) {
        setCompletedSteps(prev => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[3] = true;
          localStorage.setItem(
            'completedSteps',
            JSON.stringify(newCompletedSteps)
          );
          return newCompletedSteps;
        });
        setStep(5);
        return;
      }

      if (getCheckoutSessionUrl(4)) {
        window.location.assign(getCheckoutSessionUrl(4));
      } else {
        await createCheckoutSession(totalAmount);
      }
    } catch (error) {
      console.error('Error in payment process:', error);
      toast({
        title: 'エラー',
        description: 'お支払いに失敗しました。もう一度お試しください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setPreparing(false);
    }
  };

  // Handle subscription function
  const handleSubscription = useCallback(async () => {
    if (!currentUser || !planPrice?.id) return;
    setPreparing(true); // Start preparing

    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'subscription',
        line_items: [{ price: planPrice?.id, quantity: 1 }],
        success_url: window.location.origin + '/before?success5=true',
        cancel_url: window.location.origin + '/before',
        metadata: {
          user_id: currentUser.uid,
          user_name: currentUser.fullName,
          studio_id: currentUser.studioId,
          studio_name: currentUser.studio.name,
        },
      });

      const unsubscribe = onSnapshot(checkoutSessionRef, docSnap => {
        const { url } = docSnap.data();
        if (url) {
          localStorage.setItem(`checkoutSessionUrl5`, url);
          window.location.assign(url);
        }
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error('Subscription session creation failed:', error);
      toast({
        title: 'エラー',
        description: 'サブスクリプション登録に失敗しました。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setPreparing(false);
    }
  }, [currentUser, toast, planPrice]);

  // Get checkout session URL
  const getCheckoutSessionUrl = step =>
    localStorage.getItem(`checkoutSessionUrl${step}`);

  // Create checkout session
  const createCheckoutSession = async totalAmount => {
    if (!currentUser || getCheckoutSessionUrl(4)) return;
    setPreparing(true);

    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'payment',
        line_items: [
          {
            price_data: {
              currency: 'jpy',
              product_data: {
                name: '初回お支払い',
              },
              unit_amount: totalAmount,
            },
            quantity: 1,
          },
        ],
        success_url: window.location.origin + '/before?success4=true',
        cancel_url: window.location.origin + '/before',
      });

      onSnapshot(checkoutSessionRef, docSnap => {
        const { url } = docSnap.data();
        if (url) {
          localStorage.setItem(`checkoutSessionUrl4`, url);
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Checkout session creation failed:', error);
      toast({
        title: 'エラー',
        description: 'お支払いに失敗しました。もう一度お試しください。',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setPreparing(false);
    }
  };

  // Fetch coupons
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponDocRef = doc(firestore, 'settings', 'coupon');
        const docSnap = await getDoc(couponDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCoupons(data.initial || {});
        } else {
          console.log('No coupon data found.');
        }
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };

    fetchCoupons();
  }, []);

  // Fetch plan products
  useEffect(() => {
    const fetchPlanProducts = async () => {
      const productsCollectionRef = collection(firestore, 'products');
      const q = query(
        productsCollectionRef,
        where('active', '==', true),
        where('stripe_metadata_type', '==', 'live'),
        where('stripe_metadata_category', '==', 'plan')
      );
      const querySnapshot = await getDocs(q);
      const productsData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          const pricesCollectionRef = collection(
            firestore,
            `products/${doc.id}/prices`
          );
          const priceQuerySnapshot = await getDocs(pricesCollectionRef);
          productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));
          return productData;
        })
      );
      const matchingProductData = productsData.find(
        product => Number(product.metadata.plan) === selectedPlan
      );
      if (!matchingProductData) {
        console.log('No matching product found.');
        setPlanPrice({});
        return;
      }
      console.log('Matching product found:', matchingProductData);

      const matchingPlanPrices = matchingProductData.prices.filter(
        price => price.metadata.sex === currentUser?.sex
      );
      let matchingPlanPrice;
      if (matchingPlanPrices.length === 1) {
        matchingPlanPrice = matchingPlanPrices[0];
      } else if (matchingPlanPrices.length > 1) {
        const sexMatchingPrice = matchingPlanPrices.find(
          price => price.metadata.sex === currentUser?.sex
        );
        if (sexMatchingPrice) {
          matchingPlanPrice = sexMatchingPrice;
        }
      }
      setPlanPrice(matchingPlanPrice || {});
    };
    fetchPlanProducts();

    if (currentUser?.initial === true && currentUser?.status === true) {
      navigate('/home');
    }
  }, [
    navigate,
    currentUser?.initial,
    currentUser.initial_discount,
    currentUser?.plan,
    currentUser.sex,
    currentUser?.status,
    currentUser.trial_discount,
    currentUser?.trialId,
    selectedPlan,
  ]);

  // Render step content
  const stepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                プランを選択してください
              </FormLabel>
              <Select
                value={selectedPlan}
                onChange={e => selectPlan(e.target.value)}
              >
                {Object.keys(PLAN_MENU).map(plan => {
                  // 「月2プラン」を表示する条件
                  if (Number(plan) === 2 && !isMedayCouponApplied) {
                    return null;
                  }
                  return (
                    <option key={plan} value={plan}>
                      {PLAN_MENU[plan]}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            <Text fontSize="sm" mb={2}>
              {PLAN_DESCRIPTION[selectedPlan]}
            </Text>
            <Button
              onClick={() => {
                setCompletedSteps(prev => {
                  const newCompletedSteps = [...prev];
                  newCompletedSteps[0] = true;
                  localStorage.setItem(
                    'completedSteps',
                    JSON.stringify(newCompletedSteps)
                  );
                  return newCompletedSteps;
                });
                setStep(2);
              }}
              colorScheme="blue"
              width="full"
              isDisabled={selectedPlan === 2 && !isMedayCouponApplied}
            >
              次へ
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                クーポンコードを入力
              </FormLabel>
              <InputGroup>
                <Input
                  name="couponCode"
                  placeholder="クーポンコード"
                  value={couponCode}
                  onChange={e => setCouponCode(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={applyCoupon}>
                    適用
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
            {enrollmentDiscount > 0 && (
              <Text fontSize="sm" color="green.500" mt={2}>
                クーポン適用: {enrollmentDiscount}% 割引
              </Text>
            )}
            <Text fontSize="md" my={4}>
              入会金：{' '}
              <b>
                {(
                  INITIAL_PRICE *
                  (1 - enrollmentDiscount / 100)
                ).toLocaleString()}{' '}
                円
              </b>
            </Text>
            <Button
              onClick={() => {
                setCompletedSteps(prev => {
                  const newCompletedSteps = [...prev];
                  newCompletedSteps[1] = true;
                  localStorage.setItem(
                    'completedSteps',
                    JSON.stringify(newCompletedSteps)
                  );
                  return newCompletedSteps;
                });
                setStep(3);
              }}
              colorScheme="blue"
              width="full"
            >
              次へ
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">
                今月通うチケット枚数を選択してください
              </FormLabel>
              <Select
                value={ticketQuantity}
                onChange={e => setTicket(Number(e.target.value))}
                isDisabled={selectedPlan === 0}
              >
                {selectedPlan === 0 ? (
                  <option value={1}>チケット1枚 - 今月あと1回来る</option>
                ) : (
                  [...Array(8).keys()].map(value => (
                    <option key={value} value={value}>
                      チケット{value}枚 - 今月あと{value}回来る
                    </option>
                  ))
                )}
              </Select>
            </Flex>
            <Button
              onClick={() => {
                setCompletedSteps(prev => {
                  const newCompletedSteps = [...prev];
                  newCompletedSteps[2] = true;
                  localStorage.setItem(
                    'completedSteps',
                    JSON.stringify(newCompletedSteps)
                  );
                  return newCompletedSteps;
                });
                setStep(4);
              }}
              colorScheme="blue"
              width="full"
            >
              次へ
            </Button>
          </>
        );
      case 4:
        const discountedEnrollmentFee =
          INITIAL_PRICE * (1 - enrollmentDiscount / 100);
        const totalTicketPrice = TICKET_PRICE[selectedPlan] * ticketQuantity;
        const additionalAmount = selectedPlan === 0 ? 550 : 0;
        const totalAmount =
          discountedEnrollmentFee + totalTicketPrice + additionalAmount;

        return (
          <>
            <Text fontSize="md" my={4}>
              今月分お支払い金額： <b>{totalAmount.toLocaleString()} 円</b>
            </Text>
            {totalAmount <= 0 ? (
              <Text fontSize="sm" color="green.500" mb={4}>
                お支払いは不要です。次のステップに進んでください。
              </Text>
            ) : (
              <Text fontSize="sm" color="gray.500" mb={4}>
                ・入会金{' '}
                {(
                  INITIAL_PRICE *
                  (1 - enrollmentDiscount / 100)
                ).toLocaleString()}{' '}
                円（{enrollmentDiscount}%割引）
                <br />
                ・今月チケット {TICKET_PRICE[selectedPlan].toLocaleString()} 円
                × {ticketQuantity} 枚 = {totalTicketPrice.toLocaleString()} 円
                <br />
                {selectedPlan === 0 && (
                  <>
                    ・月額追加費用 550 円
                    <br />
                  </>
                )}
              </Text>
            )}
            <Button
              onClick={handlePayment}
              colorScheme={completedSteps[3] ? 'gray' : 'blue'}
              width="full"
              isLoading={preparing}
              isDisabled={completedSteps[3]}
            >
              {totalAmount <= 0
                ? completedSteps[3]
                  ? '次へ'
                  : '次へ'
                : completedSteps[3]
                ? '支払い済み'
                : 'お会計へ'}
            </Button>
          </>
        );

      case 5:
        return (
          <>
            <Text fontSize="md" my={4}>
              選択されたプラン： {PLAN_MENU[selectedPlan]}
            </Text>
            <Button
              onClick={handleSubscription}
              colorScheme="blue"
              width="full"
              isLoading={preparing}
              isDisabled={completedSteps[4]}
            >
              {completedSteps[4] ? '登録済み' : 'プラン登録'}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  // Render step circles
  const stepCircles = () => {
    const steps = [1, 2, 3, 4, 5];
    return (
      <HStack spacing={4} justify="center" mb={4}>
        {steps.map(num => {
          const isClickable =
            num === 1 || completedSteps.slice(0, num - 1).every(Boolean);
          return (
            <Circle
              key={num}
              size="40px"
              bg={
                completedSteps[num - 1]
                  ? 'green.500'
                  : num === step
                  ? 'blue.500'
                  : 'gray.200'
              }
              color="white"
              cursor={isClickable ? 'pointer' : 'not-allowed'}
              onClick={() => isClickable && setStep(num)}
            >
              {completedSteps[num - 1] ? '✓' : num}
            </Circle>
          );
        })}
      </HStack>
    );
  };

  // Calculate total amounts for summary
  const discountedEnrollmentFee =
    INITIAL_PRICE * (1 - enrollmentDiscount / 100);
  const totalTicketPrice = TICKET_PRICE[selectedPlan] * ticketQuantity;
  const nextMonthTickets = selectedPlan !== 0 ? selectedPlan : 0;
  const nextMonthAmount =
    TICKET_PRICE[selectedPlan] * nextMonthTickets +
    (selectedPlan === 0 ? 550 : 0);
  const additionalAmount = selectedPlan === 0 ? 550 : nextMonthAmount;
  const totalInitialAmount =
    discountedEnrollmentFee + totalTicketPrice + additionalAmount;

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'10'}
      mt={4}
      pt={8}
      pb="4"
      position="relative" // Ensure positioning for modal
    >
      <Logo text="入会手続き" />
      {stepCircles()}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <VStack spacing="12px">{stepContent()}</VStack>
      </Box>

      {/* Summary Table */}
      <Box mt={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2} textAlign={'center'}>
          お支払い内容（２ヶ月分）
        </Text>
        <Table variant="simple" size="sm" bg="white" borderRadius="md">
          <Tbody>
            <Tr>
              <Td>プラン</Td>
              <Td>{PLAN_MENU[selectedPlan]}</Td>
              <Td></Td>
              <Td>{completedSteps[0] ? '✅' : ''}</Td>
            </Tr>
            <Tr>
              <Td>入会金</Td>
              <Td></Td>
              <Td>
                {(
                  INITIAL_PRICE *
                  (1 - enrollmentDiscount / 100)
                ).toLocaleString()}{' '}
                円
              </Td>
              <Td>{completedSteps[1] ? '✅' : ''}</Td>
            </Tr>
            <Tr>
              <Td style={{ color: 'red' }}>割引額</Td>
              <Td style={{ color: 'red' }}>
                {enrollmentDiscount > 0
                  ? `${enrollmentDiscount}%割引`
                  : '割引なし'}
              </Td>
              <Td style={{ color: 'red' }}>
                {(discountedEnrollmentFee - INITIAL_PRICE).toLocaleString()} 円
              </Td>
              <Td style={{ color: 'red' }}>{completedSteps[1] ? '✅' : ''}</Td>
            </Tr>
            <Tr>
              <Td>今月分</Td>
              <Td>予約{ticketQuantity}回</Td>
              <Td>{totalTicketPrice.toLocaleString()} 円</Td>
              <Td>{completedSteps[2] ? '✅' : ''}</Td>
            </Tr>
            <Tr>
              <Td>来月分</Td>
              <Td>予約{nextMonthTickets}回</Td>
              <Td>{nextMonthAmount.toLocaleString()} 円</Td>
              <Td>{completedSteps[4] ? '✅' : ''}</Td>
            </Tr>
            <Tr>
              <Td colSpan={2} fontWeight={'bold'}>
                合計
              </Td>
              <Td fontWeight={'bold'}>
                {totalInitialAmount.toLocaleString()} 円
              </Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
        <Text fontSize="sm" mt={2} textAlign={'center'} color={'gray'}>
          ※ 来月以降は翌月分のプラン料金となります🙆‍♀️
        </Text>
      </Box>

      {/* Full-Screen Loading Modal */}
      <Modal isOpen={preparing} isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <Spinner size="xl" color="blue.500" />
            <Text mt={4} fontSize="lg" color="gray.700">
              支払い準備中...
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Before;
