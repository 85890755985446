import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // BrowserRouterをimportする

import { Box, Container, Button, Flex, Text, Heading, Badge } from '@chakra-ui/react';
import { auth, firestore, collection, query, where, getDocs } from '../firebaseConfig';

const FormList = () => {
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  const onClickHome = () => navigate('/home');

  const price = {
    female: {
      0: "9,900",
      2: "9,350",
      4: "8,800",
      6: "8,250",
      8: "7,700"
    },
    male: {
      0: "11,000",
      2: "10,450",
      4: "9,900",
      6: "9,350",
      8: "8,800"
    }
  }

  // 無期限チケットの価格を取得するための関数
  const getOnceTicketPrice = () => {
    if (userInfo.sex && price[userInfo.sex]) {
      return price[userInfo.sex][0];
    }
    return ""; // デフォルトメッセージ
  };

  // プランチケットの価格を取得するための関数
  const getPlanTicketPrice = () => {
    if (userInfo.sex && price[userInfo.sex] && typeof userInfo.plan !== 'undefined' && price[userInfo.sex][userInfo.plan] !== undefined) {
      return price[userInfo.sex][userInfo.plan];
    }
    return ""; // デフォルトメッセージ
  };


  useEffect(() => {
    const fetchUserInfo = async () => {
      if (auth.currentUser) {
        const usersCollectionRef = collection(firestore, "users");
        const q = query(usersCollectionRef, where("uid", "==", auth.currentUser.uid));
        const querySnapshot = await getDocs(q);
        // ユーザー情報を取得し、状態に設定
        if (!querySnapshot.empty) {
          // 通常は一致するドキュメントが1つだけのはずなので、最初のドキュメントのデータを取得
          const userData = querySnapshot.docs[0].data();
          setUserInfo(userData);
        } else {
          console.log("ユーザー情報が見つかりません。");
        }
      }
    };
    fetchUserInfo();
  }, []);

  return (
    <Container backgroundColor={"#ede9e5"} borderRadius={"10"} mt={4} pt={8}>
      <Heading as="h1" size="xs" textAlign="center" mt="12px" mb="20px" color="gray">会員専用システム</Heading>
      todo: 決済リンクを性別と契約プランで自動で振り分け
      <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
        <Text textAlign={"center"}><b>追加のお会計一覧</b></Text>
        <a href="https://buy.stripe.com/4gwcOW17Gb4a09iaEE" target='_blank' rel="noreferrer"><Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text>無期限チケット1枚　<Badge px={2} py={1} mb={1}>{getOnceTicketPrice()}円</Badge></Text>
        </Box></a>
        <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text>プランチケット1枚　<Badge px={2} py={1} mb={1}>{getPlanTicketPrice()}円</Badge></Text>
        </Box>
      </Box>
      <Flex justifyContent="center" mt={4}>
        <Button size="sm" colorScheme="gray" onClick={onClickHome}>ホームに戻る</Button>
      </Flex>
    </Container>
  );
};

export default FormList;
