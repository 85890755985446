import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { sendEmailVerification } from '../firebaseConfig';
import {
  Box,
  Container,
  Button,
  Flex,
  Spacer,
  Text,
  Badge,
  IconButton,
  RadioGroup,
  Radio,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { SettingsIcon, PlusSquareIcon, RepeatIcon } from '@chakra-ui/icons';
import { FaEdit } from 'react-icons/fa'; // 編集アイコンのインポート
import Logo from './Logo';
import Footer from './Footer';
import {
  getEndOfThisMonthInSeconds,
  getEndOfNextMonthInSeconds,
  getNowInSeconds,
} from '../utils/datetime';
import { fetchStudioName } from '../utils/firestore';

// Firestore関数を正しくインポート
import { firestore } from '../firebaseConfig'; // Firestoreのインスタンス
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore'; // Firestore関数

const Home = () => {
  const [studioName, setStudioName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  // モーダルの制御
  const { isOpen, onOpen, onClose } = useDisclosure();

  // スタジオ変更モーダル内の状態
  const [studios, setStudios] = useState([]);
  const [selectedStudioId, setSelectedStudioId] = useState('');
  const [loadingStudios, setLoadingStudios] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();

  const endOfThisMonthInSeconds = getEndOfThisMonthInSeconds();
  const endOfNextMonthInSeconds = getEndOfNextMonthInSeconds();
  const nowInSec = getNowInSeconds();

  const thisMonthPlanTickets = currentUser?.tickets?.length
    ? currentUser.tickets.filter(
        ticket =>
          ticket.type === 'plan' &&
          ticket.expire.seconds > nowInSec &&
          ticket.expire.seconds <= endOfThisMonthInSeconds && // 今月末までのチケット
          !ticket.used
      ).length
    : 0;

  const nextMonthPlanTickets = currentUser?.tickets?.length
    ? currentUser.tickets.filter(
        ticket =>
          ticket.type === 'plan' &&
          ticket.expire.seconds > endOfThisMonthInSeconds && // 今月末より後 かつ
          ticket.expire.seconds <= endOfNextMonthInSeconds && // 来月末までのチケット
          !ticket.used
      ).length
    : 0;

  const onceTickets = currentUser?.tickets?.length
    ? currentUser.tickets.filter(
        ticket =>
          ticket.type === 'once' &&
          ticket.expire.seconds > nowInSec &&
          !ticket.used
      ).length
    : 0;

  const onClickReservations = () =>
    navigate(
      `/studios/${currentUser?.studioId}/users/${currentUser?.uid}/reservations`,
      { state: { user: null } }
    );
  const onClickPayments = () => navigate('/payments');
  const onClickAddReservation = () => navigate('/add-reservation');

  const onClickSetReservation = () => {
    if (currentUser?.fixies?.length > 0 && !currentUser.instructor) {
      navigate('/priority-reservation');
    } else {
      navigate('/set-fixies');
    }
  };

  const resendVerificationEmail = async () => {
    if (currentUser && !currentUser.emailVerified) {
      try {
        await sendEmailVerification(currentUser.auth.currentUser);
        alert('確認メールを再送信しました。');
      } catch (error) {
        console.log('確認メールの再送信エラー', error);
        alert('確認メールの再送信に失敗しました。');
      }
    }
  };

  useEffect(() => {
    if (
      window.location.hostname !== 'localhost' &&
      currentUser?.maintenanceMode
    ) {
      navigate('/maintenance');
    }
    if (!currentUser || currentUser.status === false) {
      navigate('/before');
      return;
    }
    if (currentUser.trial_lesson) {
      navigate('/waiting');
      return;
    }
    if (currentUser?.reservations?.length > 0)
      fetchStudioName(currentUser?.reservations[0]?.studioId).then(name =>
        setStudioName(name)
      );
  }, [currentUser, navigate]);

  useEffect(() => {
    // リロードフラグをチェックし１回だけリロードする仕組み
    const reloadFlag = localStorage.getItem('reloadFlag');
    if (reloadFlag === 'true') {
      localStorage.setItem('reloadFlag', 'false'); // フラグをクリア
      navigate('/before', { state: { reload: false } }, { replace: true }); // 状態を更新
    } else if (location.state?.reload) {
      localStorage.setItem('reloadFlag', 'true'); // リロード前にフラグをセット
      window.location.reload();
    }
  }, [location, navigate]);

  // モーダル内で使用するスタジオの取得
  useEffect(() => {
    if (isOpen) {
      const fetchStudios = async () => {
        setLoadingStudios(true);
        try {
          const studiosCollection = collection(firestore, 'studios');
          const studiosSnapshot = await getDocs(studiosCollection);
          const studiosList = studiosSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStudios(studiosList);
        } catch (error) {
          console.error('スタジオの取得に失敗しました', error);
          setError('スタジオの取得に失敗しました。');
        } finally {
          setLoadingStudios(false);
        }
      };
      fetchStudios();
      // デフォルトのスタジオを設定
      if (currentUser && currentUser.studioId) {
        setSelectedStudioId(currentUser.studioId);
      }
    }
  }, [isOpen, currentUser]);

  const handleStudioChange = value => {
    setSelectedStudioId(value);
  };

  const handleSaveStudioChange = async () => {
    setSaving(true);
    setError('');
    try {
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        studioId: selectedStudioId,
      });
      // トップページにナビゲートしてリロード
      toast({
        title: 'スタジオを更新しました。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose(); // モーダルを閉じる
      navigate('/home', { state: { reload: true } });
    } catch (error) {
      console.error('スタジオの更新に失敗しました', error);
      setError('スタジオの更新に失敗しました。');
    } finally {
      setSaving(false);
    }
  };

  if (!currentUser.active) {
    return currentUser.status ? (
      <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
        <Helmet>
          <title>medy会員専用システム</title>
        </Helmet>
        <Logo />
        <Box
          borderWidth="0px"
          borderRadius="xl"
          overflow="hidden"
          p={4}
          mt={4}
          backgroundColor={'#fdfdfd'}
          boxShadow="sm"
        >
          <Text fontSize={16} textAlign="center" mt={0} fontWeight={600}>
            退会を受け付け致しました🤲
          </Text>
          <Text fontSize={12} textAlign="center" mt={2}>
            期間中、ご愛顧いただきありがとうございました🙇‍♀️
          </Text>
          <Text fontSize={10} textAlign="center" mt={4} color={'gray'}>
            ※ 登録情報は、一定期間経過後に削除されます。
          </Text>
          {/* TODO: 再入会の案内など追加 */}
        </Box>
        {'　'}
      </Container>
    ) : (
      <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
        <Helmet>
          <title>medy会員専用システム</title>
        </Helmet>
        <Logo />
        <Box
          borderWidth="0px"
          borderRadius="xl"
          overflow="hidden"
          p={4}
          mt={4}
          backgroundColor={'#fdfdfd'}
          boxShadow="sm"
        >
          <Text fontSize={16} textAlign="center" mt={0} fontWeight={600}>
            体験ご来店ありがとうございました🤲
          </Text>
          <Text fontSize={12} textAlign="center" mt={2}>
            また通いたいタイミングなどありましたらお願いします🙇‍♀️
          </Text>
          <Text fontSize={10} textAlign="center" mt={4} color={'gray'}>
            ※ 公式ラインからお問い合わせください。
          </Text>
          {/* TODO: 再入会の案内など追加 */}
        </Box>
        {'　'}
      </Container>
    );
  }

  return (
    <>
      <Container
        backgroundColor={'#ede9e5'}
        borderRadius={'10'}
        mt={4}
        pt={8}
        height={'95vh'}
        overflowY="auto"
        maxW="container.lg" // 最大幅を設定
      >
        <Helmet>
          <title>medy会員専用システム</title>
        </Helmet>
        <Logo />
        {currentUser && currentUser.emailVerified ? (
          <>
            <Box
              borderWidth="0px"
              borderRadius="xl"
              overflow="hidden"
              p={4}
              mt={4}
              backgroundColor={'#fdfdfd'}
              boxShadow="sm"
            >
              <Flex alignItems="center" mb="4">
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  ml={2}
                  mt={1}
                >
                  <Flex alignItems="center">
                    <Text fontSize={20}>
                      <b>{currentUser?.fullName || ''}</b>
                      <span style={{ fontSize: 16 }}> さま</span>
                    </Text>
                    {/* 所属スタジオ横の編集ボタン */}
                  </Flex>
                  <Flex alignItems="center">
                    <Text fontSize="11" ml={1}>
                      {studioName}に所属中{''}
                      <IconButton
                        aria-label="スタジオを変更"
                        icon={<FaEdit />}
                        size="xs"
                        variant="ghost"
                        ml={0}
                        mb={1}
                        onClick={onOpen}
                      />
                    </Text>
                  </Flex>
                </Flex>
                <Spacer />
                <IconButton
                  aria-label="Reload"
                  key={'reload'}
                  icon={<RepeatIcon />}
                  size="lg"
                  variant="ghost"
                  onClick={() => window.location.reload()}
                  mr={-3}
                  mt={-4}
                />
                <IconButton
                  aria-label="Settings"
                  key={'settings'}
                  icon={<SettingsIcon />}
                  size="lg"
                  variant="ghost"
                  onClick={() => navigate('/forms')}
                  mr={-1} // 設定ボタンとログアウトボタンの間隔
                  mt={-4}
                />
              </Flex>
              <Box
                borderWidth="1px"
                borderRadius="xl"
                overflow="hidden"
                p={4}
                mt={-2}
                onClick={() => onClickSetReservation()}
                cursor="pointer"
              >
                <Flex justifyContent="space-between" alignItems="stretch">
                  <Flex flexDirection="column" justifyContent="space-between">
                    {currentUser?.fixies?.length > 0 ? (
                      <Text fontSize={12}>
                        <Badge
                          px={2}
                          py={1}
                          backgroundColor={'gray.600'}
                          color={'white'}
                          mb={2}
                        >
                          {currentUser.instructor
                            ? '毎週の固定ブロック'
                            : '登録プランと専用枠'}
                        </Badge>
                        <br />
                        <b>
                          {currentUser.instructor
                            ? ''
                            : currentUser.plan > 0
                            ? `月${currentUser?.plan}回プラン：`
                            : 'プラン登録なし'}
                        </b>
                        {currentUser.fixies.map(fix => (
                          <Badge px={1} py={1} mr={1} key={fix.id}>
                            {/* fix.startが文字列の時、先頭２文字のみ表示 */}
                            {fix.weekday}曜
                            {typeof fix.start === 'string'
                              ? fix.start.slice(0, 2) +
                                '時' +
                                fix.start.slice(2) +
                                '分'
                              : fix.start}
                          </Badge>
                        ))}
                      </Text>
                    ) : (
                      <Text fontSize={12}>
                        <Badge
                          px={2}
                          py={1}
                          backgroundColor={'gray.600'}
                          color={'white'}
                          mb={2}
                        >
                          {currentUser.instructor
                            ? '毎週の固定ブロック'
                            : '登録プランと専用枠'}
                        </Badge>
                        <br />
                        <b>
                          {currentUser.instructor
                            ? 'ブロック設定の設定がありません'
                            : currentUser.plan > 0
                            ? `月${currentUser?.plan}回プラン：`
                            : 'プラン登録がないため専用枠は設定できません'}
                        </b>
                        　
                      </Text>
                    )}
                  </Flex>
                  <IconButton
                    aria-label="チケットの追加はこちら"
                    icon={<PlusSquareIcon />}
                    size="md"
                    colorScheme="teal"
                    variant="solid"
                    alignSelf={'flex-end'}
                  />
                </Flex>
              </Box>
              <Box
                borderWidth="1px"
                borderRadius="xl"
                overflow="hidden"
                p={4}
                mt={2}
              >
                <Text fontSize={12}>
                  <Badge
                    px={2}
                    py={1}
                    mb={2}
                    backgroundColor={'teal'}
                    color={'white'}
                  >
                    ご予約チケットの残数
                  </Badge>
                </Text>
                <Flex justifyContent="space-between" alignItems="stretch">
                  <Flex flexDirection="column" justifyContent="space-between">
                    <Text fontSize={12}>
                      　今月末まで使えるもの：{' '}
                      <Badge px={2} py={1} mb={1}>
                        {thisMonthPlanTickets}
                      </Badge>{' '}
                      枚
                    </Text>
                    <Text fontSize={12}>
                      　来月末まで使えるもの：{' '}
                      <Badge px={2} py={1} mb={1}>
                        {nextMonthPlanTickets}
                      </Badge>{' '}
                      枚
                    </Text>
                    <Text fontSize={12}>
                      　いつまでも使えるもの：{' '}
                      <Badge px={2} py={1}>
                        {onceTickets}
                      </Badge>{' '}
                      枚
                    </Text>
                  </Flex>
                  <IconButton
                    aria-label="チケットの追加はこちら"
                    icon={<PlusSquareIcon />}
                    size="md"
                    colorScheme="blue"
                    onClick={onClickPayments}
                    variant="solid"
                    alignSelf={'flex-end'}
                  />
                </Flex>
              </Box>
              <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
                {currentUser.instructor
                  ? ''
                  : 'チケットの追加は＋ボタンを押してください'}
              </Text>
              <Box
                borderWidth="1px"
                borderRadius="xl"
                overflow="hidden"
                p={4}
                mt={2}
              >
                {currentUser?.reservations?.length > 0 ? (
                  <Text fontSize={12}>
                    <Badge
                      px={2}
                      py={1}
                      backgroundColor={'teal'}
                      color={'white'}
                      mb={2}
                    >
                      {currentUser.instructor
                        ? '次のスケジュール'
                        : '次回のレッスンご予約'}
                    </Badge>
                    <br />
                    　スタジオ：{' '}
                    <Badge px={2} py={1} mb={1} textTransform={'none'}>
                      {studioName}
                    </Badge>
                    <br />
                    　来店日時：{' '}
                    <Badge px={2} py={1}>
                      {currentUser.reservations[0].startDate
                        .replace('-', '年')
                        .replace('-', '月')
                        .replace('T', '日')}
                    </Badge>
                  </Text>
                ) : (
                  <Text fontSize={12}>
                    <Badge
                      px={2}
                      py={1}
                      backgroundColor={'teal'}
                      color={'white'}
                      mb={2}
                    >
                      次回レッスン
                    </Badge>
                    <br />
                    　次回のレッスンはありません。
                  </Text>
                )}
              </Box>
              <Flex justifyContent="space-between" mt={3} mb={0}>
                <Button
                  size="sm"
                  onClick={onClickReservations}
                  colorScheme="gray"
                >
                  {currentUser.instructor ? 'スタジオ予定' : 'ご予約の確認'}
                </Button>
                {currentUser.instructor ? (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={onClickAddReservation}
                  >
                    代わりに予約
                  </Button>
                ) : currentUser?.tickets?.length > 0 ? (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={onClickAddReservation}
                  >
                    新しく予約する
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={onClickAddReservation}
                  >
                    予約状況の確認
                  </Button>
                )}
              </Flex>
              {!currentUser.instructor && (
                <Text color={'gray'} fontSize="xs" mt={3} textAlign={'center'}>
                  ご予約の確認から予約をキャンセル可能です
                </Text>
              )}
            </Box>
          </>
        ) : (
          <>
            <Button
              colorScheme="orange"
              width="full"
              onClick={resendVerificationEmail}
              mb={4}
            >
              確認メールを再送する
            </Button>
          </>
        )}
      </Container>

      {/* フッターの表示: インストラクターのみ */}
      {currentUser.instructor && <Footer />}

      {/* スタジオ変更モーダル */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>所属スタジオの変更</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error && (
              <Alert status="error" mb="4">
                <AlertIcon />
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            )}
            {loadingStudios ? (
              <Flex justify="center" align="center" height="100px">
                <Spinner size="lg" />
              </Flex>
            ) : studios.length > 0 ? (
              <RadioGroup
                onChange={handleStudioChange}
                value={selectedStudioId}
              >
                <Stack direction="column">
                  {studios.map(studio => (
                    <Radio key={studio.id} value={studio.id}>
                      {studio.name}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            ) : (
              <Text>スタジオ情報がありません。</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              キャンセル
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSaveStudioChange}
              isLoading={saving}
              disabled={!selectedStudioId || saving}
            >
              適用
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Home;
