import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Container,
  Heading,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { firestore, collection, getDocs } from '../firebaseConfig';
import BackButton from './BackButton';
import { format } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Sales = () => {
  const { studioId } = useParams();
  const [loading, setLoading] = useState(true);
  const [monthlySales, setMonthlySales] = useState([]);
  const [selectedDayDetails, setSelectedDayDetails] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchSalesData = async () => {
      setLoading(true);
      try {
        // 全ての customers を取得
        const customersSnapshot = await getDocs(
          collection(firestore, 'customers')
        );
        const salesData = {};

        for (const customerDoc of customersSnapshot.docs) {
          const customerId = customerDoc.id;
          const paymentsRef = collection(
            firestore,
            `customers/${customerId}/payments`
          );
          const paymentsSnapshot = await getDocs(paymentsRef);

          paymentsSnapshot.forEach(paymentDoc => {
            const paymentData = paymentDoc.data();

            // 'livemode' と 'status' を確認
            if (!paymentData.livemode || paymentData.status !== 'succeeded') {
              return; // 条件を満たさない場合、この支払いをスキップ
            }

            // 'created' フィールドの存在と有効性を確認
            if (!paymentData.created) {
              console.warn(
                `Missing 'created' timestamp for payment ${paymentData.id}`
              );
              return; // この支払いをスキップ
            }

            const paymentDate = new Date(paymentData.created * 1000);

            if (isNaN(paymentDate.getTime())) {
              console.warn(
                `Invalid 'created' timestamp for payment ${paymentData.id}`
              );
              return; // この支払いをスキップ
            }

            // 金額の取得と検証
            if (typeof paymentData.amount_received !== 'number') {
              console.warn(
                `Invalid 'amount_received' for payment ${paymentData.id}`
              );
              return; // この支払いをスキップ
            }

            const amount = paymentData.amount_received;

            // 日付のフォーマット
            const formattedMonth = format(paymentDate, 'yyyy-MM');
            const formattedDay = format(paymentDate, 'yyyy-MM-dd');

            // 月ごとの売上データに追加
            if (!salesData[formattedMonth]) {
              salesData[formattedMonth] = {
                total: 0,
                days: {},
              };
            }
            salesData[formattedMonth].total += amount;

            // 日ごとの売上データに追加
            if (!salesData[formattedMonth].days[formattedDay]) {
              salesData[formattedMonth].days[formattedDay] = {
                total: 0,
                transactions: [],
              };
            }
            salesData[formattedMonth].days[formattedDay].total += amount;

            // 取引詳細を保存
            salesData[formattedMonth].days[formattedDay].transactions.push({
              id: paymentData.id,
              customer: customerId,
              amount: amount,
              date: paymentDate,
              description: paymentData.description || '',
            });
          });
        }

        // 売上データを配列に変換
        const monthlySalesArray = Object.keys(salesData)
          .sort((a, b) => (a < b ? 1 : -1)) // 降順にソート
          .map(monthKey => ({
            month: monthKey,
            total: salesData[monthKey].total,
            days: salesData[monthKey].days,
          }));

        setMonthlySales(monthlySalesArray);
        console.log('売上データを取得しました:', monthlySalesArray);
        setLoading(false);
      } catch (error) {
        console.error('売上データの取得中にエラーが発生しました:', error);
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [studioId]);

  // 日ごとの取引詳細を表示する関数
  const showDayDetails = dayData => {
    setSelectedDayDetails(dayData.transactions);
    onOpen();
  };

  if (loading) {
    return (
      <Container
        backgroundColor={'#ede9e5'}
        borderRadius={'10'}
        mt={4}
        pt={8}
        centerContent
      >
        <Spinner />
        <Text mt={4}>売上データを読み込んでいます...</Text>
      </Container>
    );
  }

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h2" size="md" mb={4} textAlign="center">
        毎月の売上
      </Heading>

      {/* グラフの表示 */}
      <Box
        mb={8}
        borderWidth="0px"
        borderRadius="xl"
        backgroundColor="#fdfdfd"
        p={4}
      >
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={monthlySales.map(monthData => ({
              month: monthData.month,
              total: monthData.total,
            }))}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip formatter={value => `¥${value.toLocaleString()}`} />
            <Legend />
            <Line
              type="monotone"
              dataKey="total"
              stroke="#8884d8"
              name="売上額"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>

      {/* 月ごとの売上詳細 */}
      <Box
        mb={4}
        borderWidth="0px"
        borderRadius="xl"
        backgroundColor="#fdfdfd"
        p={4}
      >
        <Accordion allowMultiple>
          {monthlySales.map(monthData => (
            <AccordionItem key={monthData.month}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {monthData.month} - 合計売上: ¥
                    {monthData.total.toLocaleString()}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>日付</Th>
                      <Th>売上額</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(monthData.days)
                      .sort((a, b) => (a < b ? 1 : -1)) // 降順にソート
                      .map(dayKey => (
                        <Tr
                          key={dayKey}
                          onClick={() => showDayDetails(monthData.days[dayKey])}
                          cursor="pointer"
                          _hover={{ backgroundColor: 'gray.100' }}
                        >
                          <Td>{dayKey}</Td>
                          <Td>
                            ¥{monthData.days[dayKey].total.toLocaleString()}
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>

      {/* 戻るボタン */}
      <BackButton path={`/studios/${studioId}`} />

      {/* 日ごとの取引詳細を表示するモーダル */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>取引詳細</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedDayDetails.length > 0 ? (
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>取引ID</Th>
                    <Th>顧客ID</Th>
                    <Th>金額</Th>
                    <Th>説明</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedDayDetails.map(transaction => (
                    <Tr key={transaction.id}>
                      <Td>{transaction.id}</Td>
                      <Td>{transaction.customer}</Td>
                      <Td>¥{transaction.amount.toLocaleString()}</Td>
                      <Td>{transaction.description}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Text>取引データがありません。</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Sales;
