// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  sendEmailVerification,
  sendPasswordResetEmail,
  onIdTokenChanged,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  onSnapshot,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  deleteDoc,
  setDoc,
  Timestamp,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import {
  getStripePayments,
  getProducts,
  createCheckoutSession,
} from '@stripe/firestore-stripe-payments';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCdPOThE2l7FdNzPCaMlXU3lx1-3hIrWlQ',
  authDomain: 'medy-system.firebaseapp.com',
  projectId: 'medy-system',
  storageBucket: 'medy-system.appspot.com',
  messagingSenderId: '603861072312',
  appId: '1:603861072312:web:2ac89a05bdc06dca61f74d',
  measurementId: 'G-WT1GVYM3N3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
// const analytics = getAnalytics(app);

const auth = getAuth(app);
const firestore = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
// googleProvider.addScope('https://www.googleapis.com/auth/calendar.readonly');
const payments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'customers',
});

export {
  auth,
  googleProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  updateEmail,
  sendEmailVerification,
  onIdTokenChanged,
  signOut,
  firestore,
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  onSnapshot,
  query,
  where,
  getDocs,
  setDoc,
  orderBy,
  limit,
  deleteDoc,
  payments,
  getProducts,
  createCheckoutSession,
  Timestamp,
  arrayUnion,
  arrayRemove,
  sendPasswordResetEmail,
};
