import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { Container, Text, Box } from '@chakra-ui/react';
import { signOut } from '../firebaseConfig';
import FormLink from './FormLink';
import BackButton from './BackButton';
import Logo from './Logo';
import { updateUserLoginField } from '../utils/firestore';
import {
  EditIcon,
  RepeatIcon,
  RepeatClockIcon,
  StarIcon,
  DeleteIcon,
  CopyIcon,
  ExternalLinkIcon,
  UnlockIcon,
} from '@chakra-ui/icons';

const FormList = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const links = [
    {
      text: '所属店を変更する',
      path: '/change-studio',
      icon: <StarIcon />,
    },
    {
      text: '専用枠を設定する',
      path: '/set-fixies',
      icon: <RepeatClockIcon />,
    },
    {
      text: '回数券通いに切り替える',
      path: '/suspend',
      icon: <RepeatIcon />,
    },
    {
      text: 'プランを登録・変更する',
      path: '/change-plan',
      icon: <RepeatIcon />,
    },
    { text: '退会を申請する', path: '/terminate', icon: <DeleteIcon /> },
    { text: '連絡先を更新する', path: '/profile', icon: <EditIcon /> },
    { text: 'パスワードを変更する', path: '/resset', icon: <UnlockIcon /> },
    { text: '規約を確認する', path: '/terms-of-service', icon: <CopyIcon /> },
  ];

  const filteredLinks = currentUser?.plan === 0 ? links.slice(2) : links;

  const handleLogout = async () => {
    try {
      await updateUserLoginField(currentUser.uid, false);
      await signOut(currentUser.auth);
      navigate('/login');
    } catch (error) {
      console.error('ログアウトに失敗しました：', error);
    }
  };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="各種申請フォーム" />
      {filteredLinks.map((link, index) => (
        <FormLink key={index} onClick={() => navigate(link.path)}>
          <Text fontSize={14}>
            {link.icon}
            <span style={{ marginLeft: 10 }}>{link.text}</span>
          </Text>
        </FormLink>
      ))}
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        cursor="pointer"
        onClick={handleLogout}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text fontSize={14}>
          <ExternalLinkIcon />
          <span style={{ marginLeft: 10 }}>ログアウトする</span>
        </Text>
      </Box>
      <BackButton />
      {currentUser && (
        <Text fontSize={10} textAlign={'center'} color={'gray'}>
          ID: {currentUser?.uid || ''}
        </Text>
      )}
      {'　'}
    </Container>
  );
};

export default FormList;
