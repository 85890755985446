import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { signOut } from '../firebaseConfig';
import {
  Box,
  Container,
  Text,
  Button,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Link,
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon, WarningIcon } from '@chakra-ui/icons';
import { useClipboard } from '@chakra-ui/react';
import { firestore, doc, getDoc, updateDoc } from '../firebaseConfig';
import Logo from './Logo';
import LoadingModal from './LoadingModal';
import { sendDiscordNotification } from '../utils/discord';

const Waiting = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isSignOutOpen, setIsSignOutOpen] = useState(false);
  const [cancelling, setCanceling] = useState(false);
  const cancelRef = useRef();
  const signOutCancelRef = useRef();
  const { hasCopied, onCopy } = useClipboard(
    currentUser?.studio?.address || ''
  );

  useEffect(() => {
    if (!currentUser) {
      navigate('/trial');
    } else {
      if (!currentUser.trialId) {
        navigate('/trial');
      }
      // ページを一回だけリロード
      if (!window.location.hash) {
        window.location.hash = 'reloaded';
        window.location.reload();
      }

      // 体験予約を取得
      const fetchReservation = async () => {
        try {
          const reservationDoc = await getDoc(
            doc(firestore, 'reservations', currentUser.trialId)
          );
          if (reservationDoc.exists() && reservationDoc.data().active) {
            setReservation(reservationDoc.data());
          } else {
            const shouldNavigate = window.confirm(
              '予約情報が見つかりませんでした。予約しますか？'
            );
            if (shouldNavigate) {
              navigate('/trial');
            } else {
              navigate('/home');
            }
          }
        } catch (error) {
          console.error('Error fetching reservation: ', error);
        } finally {
          setLoading(false);
        }
      };

      fetchReservation();
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser && reservation) {
      const now = new Date();
      const startDate = new Date(reservation.startDate);
      if (startDate < now) navigate('/before');
    }
  }, [currentUser, reservation, navigate]);

  const handleCancel = async () => {
    if (currentUser && currentUser.trialId) {
      try {
        const reservationRef = doc(
          firestore,
          'reservations',
          currentUser.trialId
        );
        setCanceling(true);
        await updateDoc(reservationRef, { active: false });

        if (reservation.eventId) {
          const response = await fetch(
            'https://asia-northeast1-medy-system.cloudfunctions.net/delete_google_calendar_event',
            {
              method: 'POST',
              body: JSON.stringify({
                calendarId: `${currentUser.studio.slug}@studio-medy.com`,
                eventId: reservation.eventId,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (!response.ok) {
            console.error(
              'Googleカレンダーへのイベント削除に失敗しました',
              response
            );
            return;
          }
        }

        const embeds = [
          {
            title: 'キャンセル通知',
            description: `以下の予定がキャンセルされました。`,
            color: 16752762,
            fields: [
              {
                name: '日時',
                value: `${new Date(reservation.startDate).toLocaleString(
                  'ja-JP',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                )}`,
                inline: true,
              },
              {
                name: '予約者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: 'キャンセル承認時刻',
            },
            timestamp: new Date().toISOString(),
          },
        ];

        await sendDiscordNotification(
          currentUser.studio.slug,
          embeds,
          currentUser.studio.instructors[reservation.instructorId]
        );

        await sendDiscordNotification('reserve', embeds);
        await sendDiscordNotification('audit', embeds);

        const userRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userRef, { trialId: '' });

        setCanceling(false);
        navigate('/trial');
      } catch (error) {
        console.error('Error cancelling reservation: ', error);
        setCanceling(false);
      }
    }
  };

  const handleCancelWithSignOut = async () => {
    if (currentUser && currentUser.trialId) {
      try {
        const reservationRef = doc(
          firestore,
          'reservations',
          currentUser.trialId
        );
        setCanceling(true);
        await updateDoc(reservationRef, { active: false });

        if (reservation.eventId) {
          const response = await fetch(
            'https://asia-northeast1-medy-system.cloudfunctions.net/delete_google_calendar_event',
            {
              method: 'POST',
              body: JSON.stringify({
                calendarId: `${currentUser.studio.slug}@studio-medy.com`,
                eventId: reservation.eventId,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (!response.ok) {
            console.error(
              'Googleカレンダーへのイベント削除に失敗しました',
              response
            );
            return;
          }
        }

        const embeds = [
          {
            title: 'キャンセル通知',
            description: `以下の予定がキャンセルされました。`,
            color: 16752762,
            fields: [
              {
                name: '日時',
                value: `${new Date(reservation.startDate).toLocaleString(
                  'ja-JP',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                )}`,
                inline: true,
              },
              {
                name: '予約者',
                value: currentUser?.fullName,
                inline: true,
              },
            ],
            footer: {
              text: 'キャンセル承認時刻',
            },
            timestamp: new Date().toISOString(),
          },
        ];

        await sendDiscordNotification(
          'https://discord.com/api/webhooks/1217760107249795153/-_iAxvFOjHxrtQHMyzNh3ft8oO6x1NXPeePxO4w9msuBXPZtaPPZQ-nudGMU9R3DSDwr',
          embeds
        );

        const userRef = doc(firestore, 'users', currentUser.uid);
        await updateDoc(userRef, { trialId: '' });

        setCanceling(false);
        signOut(currentUser.auth);
        navigate('/trial');
      } catch (error) {
        console.error('Error cancelling reservation: ', error);
        setCanceling(false);
      }
    }
  };

  if (loading) return <LoadingModal text="予約情報を取得中..." />;

  if (cancelling) return <LoadingModal text="ご予約をキャンセル中..." />;

  const now = new Date();
  const startDate = new Date(reservation?.startDate);
  // startDateに30分追加する
  startDate.setMinutes(startDate.getMinutes() + 30);

  // 前日21時の日時を取得
  const dayBefore = new Date(startDate);
  dayBefore.setDate(startDate.getDate() - 1);
  dayBefore.setHours(21, 0, 0, 0);

  const isCancellationDeadlinePassed = now > dayBefore;

  // ヒアリングフォームの完了状況（例としてcurrentUserにプロパティがあると仮定）
  // 実際には、ユーザーのヒアリング完了状況を適切に取得してください。
  const isHearingCompleted = currentUser?.agreedToTerms || false;

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>{currentUser.fullName}さま</title>
      </Helmet>
      <Logo text="体験受付システム" />
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={3}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        width="100%"
      >
        <Tabs variant="enclosed">
          <TabList>
            <Tab>体験</Tab>
            <Tab>
              回答{' '}
              {isHearingCompleted ? (
                <CheckIcon color="green.500" ml={2} />
              ) : (
                <WarningIcon color="red.500" ml={2} />
              )}
            </Tab>
            <Tab>変更</Tab>
          </TabList>

          <TabPanels>
            {/* 体験タブ */}
            <TabPanel>
              <Text
                fontSize="lg"
                textAlign="center"
                my={4}
                fontWeight={'bold'}
                color="gray.700"
              >
                {reservation
                  ? startDate > now
                    ? 'ご予約を承りました✨'
                    : 'おつかれさまでした✨'
                  : 'データがありません'}
              </Text>
              {reservation ? (
                <>
                  <Box
                    mb={4}
                    p={4}
                    bg="gray.100"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <Text fontSize="lg" fontWeight="bold" color="gray.700">
                      ご予約内容の詳細
                    </Text>
                    <Divider my={2} />
                    <Text fontSize="md" color="gray.600" mb={2}>
                      <Text as="span" fontWeight="bold">
                        お名前:
                      </Text>{' '}
                      {currentUser.fullName} さま
                    </Text>
                    <Text fontSize="md" color="gray.600" mb={2}>
                      <Text as="span" fontWeight="bold">
                        体験日:
                      </Text>{' '}
                      {new Date(reservation.startDate).toLocaleDateString(
                        'ja-JP',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        }
                      )}
                    </Text>
                    <Text fontSize="md" color="gray.600" mb={2}>
                      <Text as="span" fontWeight="bold">
                        お時間:
                      </Text>{' '}
                      {new Date(reservation.startDate).toLocaleTimeString(
                        'ja-JP',
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        }
                      )}{' '}
                      〜
                    </Text>
                    <Text fontSize="md" color="gray.600" mb={2}>
                      <Text as="span" fontWeight="bold">
                        店舗名:
                      </Text>{' '}
                      {currentUser.studio.name}
                    </Text>
                    <Text fontSize="md" color="gray.600">
                      <Text as="span" fontWeight="bold">
                        担当者:
                      </Text>{' '}
                      {currentUser.studio.instructors[reservation.instructorId]}{' '}
                      インストラクター
                    </Text>
                  </Box>
                  <Box
                    mb={4}
                    p={4}
                    bg="gray.100"
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    {/* 住所タイトルとコピーボタンを左右に配置 */}
                    <Flex justify="space-between" align="center" mb={2}>
                      <Text fontSize="lg" fontWeight="bold" color="gray.700">
                        住所
                      </Text>
                      <Button
                        onClick={onCopy}
                        size="xs" // ボタンをさらに小さくする場合は "xs" を使用
                        colorScheme="teal"
                        leftIcon={<CopyIcon />}
                      >
                        {hasCopied ? 'コピー完了' : 'コピー'}
                      </Button>
                    </Flex>

                    <Divider my={2} />

                    {/* 住所の表示 */}
                    <Flex align="center">
                      <Text fontSize="md" color="gray.600">
                        {currentUser.studio.address}
                      </Text>
                    </Flex>

                    <Divider my={2} />

                    <iframe
                      title="map"
                      src={currentUser.studio.map}
                      width="100%"
                      height="auto"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </Box>

                  {now > startDate ? (
                    <Box>
                      <Text fontSize="sm" my="6" textAlign="center">
                        <Link
                          href="/terms-of-service"
                          color="gray.700"
                          textDecoration="underline"
                          isExternal
                        >
                          サービスご利用規約
                        </Link>
                      </Text>
                      <Button
                        colorScheme="teal"
                        onClick={() => navigate('/before')}
                        size="sm"
                        width="100%"
                      >
                        規約に同意して入会する
                      </Button>
                      <Text
                        color={'gray'}
                        fontSize="xs"
                        mt={2}
                        mb={2}
                        textAlign={'center'}
                      >
                        入会手続きは３ステップです🙌
                      </Text>
                      <Divider mt={6} mb={4} />
                      <Button
                        colorScheme="gray"
                        onClick={() =>
                          (window.location.href =
                            'https://buy.stripe.com/4gwcOW9Ec7RY4py4gh')
                        }
                        size="sm"
                        width="100%"
                        mt={2}
                      >
                        体験費用のみ支払う
                      </Button>
                      <Text
                        color={'gray'}
                        fontSize="xs"
                        mt={2}
                        mb={2}
                        textAlign={'center'}
                      >
                        お支払いがまだの場合はこちら💁‍♀️
                      </Text>
                    </Box>
                  ) : null}
                </>
              ) : (
                <Text fontSize="md" textAlign="center" color="gray.600">
                  予約情報を取得できませんでした。
                </Text>
              )}
            </TabPanel>

            {/* ヒアリングタブ */}
            <TabPanel mt={4}>
              {isHearingCompleted ? (
                <Box textAlign="center">
                  <CheckIcon color="green.500" w={8} h={8} />
                  <Text fontSize="lg" fontWeight="bold" mt={2}>
                    事前ヒアリング完了
                  </Text>
                  <Text fontSize="md" color="gray.600" mt={2}>
                    ご協力ありがとうございます。ご来店お待ちしております✨
                  </Text>
                </Box>
              ) : (
                <Box textAlign="center">
                  <WarningIcon color="red.500" w={8} h={8} />
                  <Text fontSize="lg" fontWeight="bold" my={2}>
                    事前ヒアリングが未提出
                  </Text>
                  <Text fontSize="md" color="gray.600" my={4}>
                    ヒアリングシートを事前に記入しておきましょう🙌
                  </Text>
                  <Button
                    colorScheme="teal"
                    onClick={() => navigate('/hearing')}
                    mt={4}
                  >
                    ヒアリングシート記入
                  </Button>
                  <Text fontSize="md" color="gray.600" mt={4}>
                    体験レッスンでマシンピラティスの時間をたっぷり取ることができます😉
                  </Text>
                </Box>
              )}
            </TabPanel>

            {/* 変更タブ */}
            <TabPanel>
              {now < startDate ? (
                <>
                  <Text
                    fontSize="md"
                    textAlign="center"
                    color="gray.800"
                    my={4}
                    fontWeight={'bold'}
                  >
                    日時の変更は前日２１時まで
                  </Text>
                  {isCancellationDeadlinePassed ? (
                    <Text
                      color={'red.500'}
                      fontSize="sm"
                      mt={2}
                      textAlign={'center'}
                    >
                      キャンセル期限が切れました。
                    </Text>
                  ) : (
                    <>
                      <Button
                        colorScheme="red"
                        onClick={() => setIsOpen(true)}
                        size="sm"
                        width="100%"
                        mb={4}
                      >
                        キャンセル・日時変更する
                      </Button>
                      <Text
                        color={'gray'}
                        fontSize="xs"
                        mb={2}
                        textAlign={'center'}
                      >
                        変更希望の方は一度キャンセル下さい🙇‍♀️
                      </Text>
                      <Divider my={4} />
                      <Button
                        colorScheme="red"
                        variant="outline"
                        onClick={() => setIsSignOutOpen(true)}
                        size="sm"
                        width="100%"
                      >
                        別のメールアドレスで取り直す
                      </Button>
                      <Text
                        color={'gray'}
                        fontSize="xs"
                        mt={2}
                        textAlign={'center'}
                      >
                        アカウントを作り直します🙆‍♀️
                      </Text>
                    </>
                  )}
                </>
              ) : (
                <Box textAlign="center">
                  <Text color={'red.500'} fontSize="sm" mt={2}>
                    予約の時間が過ぎました。サポートが必要な場合はお問い合わせください。
                  </Text>
                </Box>
              )}
              <Divider my={4} />
              <Text fontSize={12} color={'gray'} mb={4} mx={0}>
                <b>キャンセルポリシー</b>
                <br />
                前日２１時を過ぎた場合、キャンセル操作ができなくなります。この場合、キャンセル料１００％となり体験費用は返金されません。
                <br />
                <br />
                変更や取消のためにキャンセルをする場合は前もってお手続きください。
                <br />
                <br />
                <b>
                  日時変更ではなく完全に体験予約を取り消す場合は、キャンセル後に公式LINEにてお伝えください。
                  <br />
                  キャンセル期限内であれば返金を受けられます。
                </b>
              </Text>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* キャンセル確認ダイアログ */}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                体験予約のキャンセル
              </AlertDialogHeader>

              <AlertDialogBody>
                本当に体験予約をキャンセルしますか？
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                  いいえ
                </Button>
                <Button colorScheme="red" onClick={handleCancel} ml={3}>
                  はい
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* 別のメールアドレスで取り直しダイアログ */}
        <AlertDialog
          isOpen={isSignOutOpen}
          leastDestructiveRef={signOutCancelRef}
          onClose={() => setIsSignOutOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                別のメールアドレスで取り直し
              </AlertDialogHeader>

              <AlertDialogBody>
                本当に現在の予約をキャンセルし、別のメールアドレスで取り直しますか？
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={signOutCancelRef}
                  onClick={() => setIsSignOutOpen(false)}
                >
                  いいえ
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleCancelWithSignOut}
                  ml={3}
                >
                  はい
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Container>
  );
};

export default Waiting;
