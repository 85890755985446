import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { Box, Container, Heading, Button } from '@chakra-ui/react';
import BackButton from './BackButton';

const Suspend = () => {
  const { currentUser } = useAuth();
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  useEffect(() => {
    // 現在の日時を取得
    const now = new Date();
    // 現在の年月を取得
    const year = now.getFullYear();
    const month = now.getMonth(); // 月は0から始まるので、+1する必要はありません

    // 当月の20日23時59分の日時オブジェクトを作成
    const deadline = new Date(year, month, 20, 23, 59, 59);

    // 現在の日時が期限を過ぎているか確認
    if (now > deadline) {
      setIsDeadlinePassed(true);
    }
  }, []);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="0px" mb="20px">
        回数券ご利用への切り替え
      </Heading>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <div>
          <p style={{ color: 'gray' }}>
            ご希望の方は、切り替える月の前月２０日までにご申請ください。
          </p>
          <br />
          <a
            href={`https://docs.google.com/forms/d/e/1FAIpQLSeqLnJZ5NwAKn67ub-yYZwkb1T48NByHNL3K6E-JczvMrco4Q/viewform?usp=pp_url&entry.67110184=${currentUser?.studio?.name}&entry.1785760527=${currentUser?.fullName}&entry.1607495935=${currentUser?.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              colorScheme="gray"
              width="full"
              isDisabled={isDeadlinePassed}
            >
              切り替えフォームを開く
            </Button>
          </a>
          {isDeadlinePassed && (
            <p
              style={{
                color: 'red',
                textAlign: 'center',
                marginTop: '10px',
                fontSize: 12,
              }}
            >
              ※ 来月適用の切替申請の期限が過ぎました。
              <br />
              翌月になると手続きが可能です。
            </p>
          )}
          <br />
        </div>
      </Box>
      <BackButton />
      <Box overflow="hidden" px={4} mb={4}>
        <p style={{ fontSize: 12, color: 'gray' }}>
          利用規約にもあります通り、決済・会計処理の都合上、期限を過ぎますと切替や返金などの対応ができません。ご注意ください。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          回数券で通われる場合は、レッスンの予約ごとに回数券の購入が必要です。また、情報管理およびアカウント維持のため月額550円がかかります。
        </p>
      </Box>
      {'　'}
    </Container>
  );
};

export default Suspend;
