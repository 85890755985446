import React, { useEffect, useState } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { auth, firestore, collection, onSnapshot } from '../firebaseConfig';

const StudioList = () => {
  const [studios, setStudios] = useState([]);

  useEffect(() => {
    const studiosCollection = collection(firestore, 'studios');
    const unsubscribe = onSnapshot(studiosCollection, (snapshot) => {
      const studiosArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudios(studiosArray);
    }, (error) => {
      console.error("Error fetching studios:", error);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Container backgroundColor={"#ede9e5"} borderRadius={"10"} mt={4} pt={8}>
      <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
        <div>
          <p>{auth.currentUser.displayName}</p>
          {studios.map((studio) => (<>
            <div key={studio.id}>{studio.name} {studio.address}</div></>
          ))}
        </div>
      </Box>
    </Container>
  );
};

export default StudioList;
