import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Logo from './Logo';
import {
  Text,
  Container,
  Button,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import {
  firestore,
  doc,
  setDoc,
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
} from '../firebaseConfig';
import { useAuth } from '../AuthProvider';
import LoadingModal from '../components/LoadingModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { sendDiscordNotification } from '../utils/discord';

const Confirm = () => {
  const { currentUser } = useAuth();
  const [initialPrice, setInitialPrice] = useState({});
  const [preparing, setPreparing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true); // 最初はモーダルを閉じた状態にする
  const cancelRef = React.useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedDateTime = location.state?.selectedDateTime;

  const getCheckoutSessionUrl = () =>
    localStorage.getItem('checkoutSessionUrl');

  const createCheckoutSession = async () => {
    if (!currentUser || !initialPrice?.id || getCheckoutSessionUrl()) return;
    setPreparing(true);
    const sessionId = uuid();
    const checkoutSessionRef = doc(
      firestore,
      `customers/${currentUser.uid}/checkout_sessions/${sessionId}`
    );

    try {
      await setDoc(checkoutSessionRef, {
        mode: 'payment',
        line_items: [{ price: initialPrice?.id, quantity: 1 }],
        success_url: window.location.origin + '/trial-payment-success',
        cancel_url: window.location.origin + '/trial-confirm',
      });

      const unsubscribe = onSnapshot(checkoutSessionRef, doc => {
        const { url } = doc.data();
        if (url) {
          localStorage.setItem('checkoutSessionUrl', url);
          window.location.assign(url);
        }
      });

      return () => {
        setPreparing(false);
        unsubscribe();
      };
    } catch (error) {
      console.error('Checkout session creation failed:', error);
      // エラーが発生した場合、モーダルを再度開く
      setIsModalOpen(true);
    }
  };

  const handlePayment = () => {
    if (getCheckoutSessionUrl()) {
      window.location.assign(getCheckoutSessionUrl());
    } else {
      createCheckoutSession();
    }
  };

  const handleStop = async () => {
    setIsModalOpen(false);

    // ディスコード通知
    const embeds = [
      {
        title: '新規体験のお支払い画面で離脱',
        description: `体験レッスン代金の支払い案内を見て体験予約をやめました`,
        color: 8421504,
        fields: [
          {
            name: '氏名（漢字）',
            value: currentUser.fullName,
            inline: true,
          },
          {
            name: '氏名（かな）',
            value: currentUser.fullKana,
            inline: true,
          },
          {
            name: 'LINE表示名',
            value: currentUser.displayName,
            inline: true,
          },
          {
            name: '電話番号',
            value: currentUser.phoneNumber,
            inline: true,
          },
          {
            name: 'メールアドレス',
            value: currentUser.email,
            inline: true,
          },
          {
            name: '認知経路',
            value: currentUser.path,
            inline: true,
          },
          {
            name: 'モチベーション',
            value: currentUser.reason,
            inline: true,
          },
        ],
        footer: { text: 'UID: ' + currentUser.uid },
        timestamp: new Date().toISOString(),
      },
    ];
    await sendDiscordNotification('trial', embeds);
    await sendDiscordNotification('audit', embeds);
  };

  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.trialPaid && currentUser.trialId.length > 0)
      navigate('/waiting');
    if (currentUser.trialPaid) navigate('/reserve');
    const fetchInitialProducts = async () => {
      const productsCollectionRef = collection(firestore, 'products');
      const q = query(
        productsCollectionRef,
        where('active', '==', true),
        where('stripe_metadata_type', '==', 'live'),
        where('stripe_metadata_category', '==', 'trial')
      );
      const querySnapshot = await getDocs(q);
      const productsData = await Promise.all(
        querySnapshot.docs.map(async doc => {
          const productData = { id: doc.id, ...doc.data() };
          const pricesCollectionRef = collection(
            firestore,
            `products/${doc.id}/prices`
          );
          const priceQuerySnapshot = await getDocs(pricesCollectionRef);
          productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
            id: priceDoc.id,
            ...priceDoc.data(),
          }));
          return productData;
        })
      );
      const matchingPrices = productsData[0].prices;
      let matchingPrice;
      if (matchingPrices.length === 1) {
        matchingPrice = matchingPrices[0];
      } else if (matchingPrices.length > 1) {
        const sexMatchingPrice = matchingPrices.find(
          price => price.metadata.sex === currentUser.sex
        );
        if (sexMatchingPrice) {
          matchingPrice = sexMatchingPrice;
        }
      }
      setInitialPrice(matchingPrice);
    };
    fetchInitialProducts();
  }, [currentUser, navigate]);

  if (preparing) return <LoadingModal text="支払いを作成中..." />;

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>{currentUser.fullName}さま</title>
      </Helmet>
      <Logo text="体験予約確認画面" />
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        width="100%"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          {'まだご予約は完了していません'}
        </Text>
        <Text textAlign="center">
          {currentUser.fullName || currentUser.fullName}さま
        </Text>
        <Text textAlign="center" mb="4">
          {currentUser.email}
        </Text>
        <Text textAlign="center" fontWeight={'bold'}>
          体験レッスン希望日時
        </Text>
        {selectedDateTime && (
          <Text textAlign="center">
            {`${selectedDateTime.date.getFullYear()}年${
              selectedDateTime.date.getMonth() + 1
            }月${selectedDateTime.date.getDate()}日 ${
              selectedDateTime.hour
            }:00`}
          </Text>
        )}
        {!selectedDateTime && (
          <Text fontSize="12" color="red">
            体験レッスンの希望時間を選択してください
          </Text>
        )}
        <Text textAlign="center">
          <Button
            colorScheme="blue"
            my={2}
            mt={4}
            onClick={() => setIsModalOpen(true)}
            isDisabled={!selectedDateTime || !currentUser}
          >
            お支払いに進む
          </Button>
          <Text color={'gray'} fontSize="xs" mb={2} textAlign={'center'}>
            クレジットカードを用意するとスムーズです🙆‍♀️
          </Text>
        </Text>
      </Box>

      <AlertDialog
        isOpen={isModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {}}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            rounded={'xl'}
            position="fixed"
            bottom="0"
            left="0%"
            transform="translateX(-50%)"
            mb="-3"
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              体験レッスン代金のお会計💳
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb={4}>
                ご希望の日時はお支払い後に確定します🙆‍♀️
                <br />
                <br />
                通常8,000円の体験費用が半額の4,000円でパーソナルレッスンを受けられるキャンペーン中です🎉
                <br />
                <br />
                さらに！体験後に感想をお書きいただくことで4,000円も全額キャッシュバック✨
                <br />
                <br />
                実質「無料」で体験できるお得な期間です🙌
                <br />
                <br />
                この機会にぜひ、medyのマシンピラティスをお試しください🌟
              </Text>
              <Text fontSize={'xs'} color={'gray'}>
                ※ medyでは、前払い制を導入しております🙇‍♀️
                <br />
                　所定の操作でキャンセルや返金も可能です。ご安心ください。
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={handleStop} mb={4} mr={4}>
                今はやめる
              </Button>
              <Button colorScheme="blue" onClick={handlePayment} mb={4}>
                体験レッスンのお支払いへ
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default Confirm;
