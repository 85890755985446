import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { firestore, doc, updateDoc } from '../firebaseConfig';
import {
  Container,
  Text,
  Box,
  Button,
  Input,
  VStack,
  Heading,
  useToast,
  FormLabel,
  Flex,
} from '@chakra-ui/react';
import BackButton from './BackButton';

const EditProfilePage = () => {
  const toast = useToast();
  const [userData, setUserData] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    let skipStore = false;
    try {
      if (
        currentUser.fullName === userData.fullName &&
        currentUser.fullKana === userData.fullKana &&
        currentUser.phoneNumber === userData.phoneNumber
      ) {
        skipStore = true;
      }

      // Firestoreドキュメントの更新データを準備
      const updatedData = {};

      // 変更がある場合、更新データオブジェクトに追加
      if (currentUser.fullName !== userData.fullName)
        updatedData.fullName = userData.fullName;
      if (currentUser.fullKana !== userData.fullKana)
        updatedData.fullKana = userData.fullKana;
      if (currentUser.phoneNumber !== userData.phoneNumber)
        updatedData.phoneNumber = userData.phoneNumber;

      // Firestoreのドキュメントを更新（変更がある場合のみ）
      if (!skipStore) {
        const userDocRef = doc(firestore, 'users', currentUser.id);
        await updateDoc(userDocRef, updatedData);
      } else {
        alert('情報の変更がありません');
        return;
      }

      // if (currentUser.email !== userData.email) {
      //   await updateEmail(currentUser, userData.email);
      //   const actionCodeSettings = {
      //     url: `${window.location.origin}/confirm-email`,
      //     handleCodeInApp: true,
      //   };
      //   await sendEmailVerification(currentUser, actionCodeSettings);
      //   toast({
      //     title: 'メールアドレス変更リクエスト送信完了',
      //     description: '新しいメールアドレスにメール確認リンクが送信されました。リンクをクリックして確認を完了してください。',
      //     status: 'success',
      //     duration: 5000,
      //     isClosable: true,
      //   });
      //   // メール確認画面に遷移する
      //   navigate('/confirm-email', { state: { email: userData.email } });
      // }

      toast({
        title: 'プロフィール更新完了',
        description: 'プロフィールが正常に更新されました。',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/home', { state: { reload: true } });
    } catch (error) {
      toast({
        title: 'プロフィール更新エラー',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // ページ読み込み時にcurrentUserをstateに設定する部分は変更なし
  useEffect(() => {
    setUserData({
      id: currentUser.id,
      fullName: currentUser.fullName,
      fullKana: currentUser.fullKana,
      phoneNumber: currentUser.phoneNumber,
      email: currentUser.email, // これはAuthから直接取得できないため、初期設定は留意
    });
  }, [currentUser]);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Heading as="h1" size="sm" textAlign="center" mt="20px" mb="20px">
        medyご登録情報の編集
      </Heading>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        {userData && (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <VStack spacing="24px" pt={2} pb={4}>
              <Flex flexDirection="column" alignItems="start" width="full">
                <FormLabel fontSize="12" mb="1">
                  フルネーム（漢字）
                </FormLabel>
                <Input
                  placeholder="フルネーム"
                  value={userData.fullName || ''}
                  onChange={e =>
                    setUserData({ ...userData, fullName: e.target.value })
                  }
                />
              </Flex>
              <Flex flexDirection="column" alignItems="start" width="full">
                <FormLabel fontSize="12" mb="1">
                  フルネーム（ひらがな）
                </FormLabel>
                <Input
                  placeholder="フルネーム（かな）"
                  value={userData.fullKana || ''}
                  onChange={e =>
                    setUserData({ ...userData, fullKana: e.target.value })
                  }
                />
              </Flex>
              <Flex flexDirection="column" alignItems="start" width="full">
                <FormLabel fontSize="12" mb="1">
                  電話番号（半角数字のみ）
                </FormLabel>
                <Input
                  placeholder="電話番号"
                  value={userData.phoneNumber || ''}
                  onChange={e =>
                    setUserData({ ...userData, phoneNumber: e.target.value })
                  }
                />
              </Flex>
              {/* <Flex flexDirection="column" alignItems="start" width="full">
              <FormLabel fontSize="12" mb="1">メールアドレス</FormLabel>
              <Input
                placeholder="新しいメールアドレス"
                value={userData.email || ''}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              />
            </Flex> */}
              <Button type="submit" colorScheme="blue" width="full" mt={2}>
                上記の内容で更新する
              </Button>
            </VStack>
          </form>
        )}
      </Box>
      <BackButton />
      <Box overflow="hidden" px={4} mb={4}>
        <Text color={'gray'} fontSize="xs" mt={0}>
          メールアドレスの変更は、こちらからできません。どうしても変更しなければならない理由がある場合は、公式LINEにてご相談ください。
          <br />
          <br />
          メールアドレスは、このアプリの操作をはじめ、認証や決済の様々なアカウントと深く結びついているため、データを引き継ぐためにも慎重な作業が必要になります。ご依頼後に少々お時間をいただく場合がありますため、あらかじめご容赦くださいませ。
        </Text>
      </Box>
      {'　'}
    </Container>
  );
};

export default EditProfilePage;
