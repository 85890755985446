import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Text,
  Container,
  Heading,
  Flex,
  Spinner,
  Badge,
  Button,
  Input,
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import BackButton from './BackButton';
import { firestore, doc, getDoc, updateDoc } from '../firebaseConfig'; // updateDocを追加

const Studio = () => {
  const { studioId } = useParams();
  const [studio, setStudio] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isStatsVisible, setIsStatsVisible] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState('');
  const [announce, setAnnounce] = useState(''); // アナウンス用の状態を追加
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // モーダル用の状態
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFixieIndex, setSelectedFixieIndex] = useState(null);
  const [editedWeekday, setEditedWeekday] = useState('');
  const [editedStart, setEditedStart] = useState('');

  // バリデーションエラー用の状態
  const [startError, setStartError] = useState('');

  const toast = useToast();

  const onClickShift = () =>
    navigate(`/studios/${studioId}/instructors/${selectedInstructor}/shift`);
  const onClickBlock = () =>
    navigate(`/studios/${studioId}/instructors/${currentUser.uid}/block`);

  useEffect(() => {
    const fetchStudioAndUsers = async () => {
      if (studioId) {
        const studioDocRef = doc(firestore, 'studios', studioId);
        const studioDocSnap = await getDoc(studioDocRef);

        if (studioDocSnap.exists()) {
          const studioData = studioDocSnap.data();
          setStudio(studioData);
          console.log(studioData);

          if (studioData.users && Array.isArray(studioData.users)) {
            const userPromises = studioData.users.map(userRef =>
              getDoc(userRef)
            );
            const userDocs = await Promise.all(userPromises);
            const usersData = userDocs
              .filter(docSnap => docSnap.exists() && !docSnap.data().instructor)
              .map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
            setUsers(usersData);
          } else {
            console.log('このスタジオにはユーザーが所属していません。');
          }

          // アナウンスの読み込み
          const announceRef = doc(firestore, 'settings', 'announce');
          const announceDoc = await getDoc(announceRef);
          if (announceDoc.exists()) {
            const announceData = announceDoc.data()[studioId] || '';
            setAnnounce(announceData); // アナウンスを状態にセット
          }
        } else {
          console.log('スタジオが見つかりません。');
        }
      }
    };

    fetchStudioAndUsers();
  }, [studioId]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  const handleAnnounceChange = event => {
    setAnnounce(event.target.value);
  };

  const handleUpdateAnnounce = async () => {
    const announceRef = doc(firestore, 'settings', 'announce');
    try {
      await updateDoc(announceRef, { [studioId]: announce }); // アナウンスを更新
      toast({
        title: 'アナウンスを更新しました。',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      window.location.reload(); // アナウンスの変更をリアルタイムに反映するためにページをリロード
    } catch (error) {
      console.error('アナウンスの更新に失敗しました:', error);
      toast({
        title: 'エラー',
        description: 'アナウンスの更新に失敗しました。もう一度お試しください。',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const filteredUsers = users.filter(user =>
    user.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleStatsVisibility = () => {
    setIsStatsVisible(!isStatsVisible);
  };

  // 曜日と時間でソートされた固定予約の配列
  const sortedFixies = useMemo(() => {
    if (!studio || !studio.fixies) return [];
    const weekdayOrder = { 月: 1, 火: 2, 水: 3, 木: 4, 金: 5, 土: 6, 日: 7 };
    return [...studio.fixies].sort((a, b) => {
      if (weekdayOrder[a.weekday] !== weekdayOrder[b.weekday]) {
        return weekdayOrder[a.weekday] - weekdayOrder[b.weekday];
      }
      return a.start.localeCompare(b.start);
    });
  }, [studio]);

  if (!studio) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner mr={3} />
        <Text fontSize="xl">データ読み込み中...</Text>
      </Flex>
    );
  }

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="" mb="">
        medy講師専用システム
      </Heading>

      <Box borderWidth="0px" borderRadius="xl" overflow="hidden" p={0} mt={4}>
        <Box
          mb={4}
          borderWidth="0px"
          borderRadius="xl"
          backgroundColor="#fdfdfd"
          p={4}
        >
          <Text textAlign={'center'} mt={0}>
            <b>スタジオ予定関係</b>
          </Text>
          <Button
            size="md"
            onClick={onClickBlock}
            colorScheme="teal"
            width={'100%'}
            my={4}
          >
            スケジュールを管理する
          </Button>
          {/* アナウンスの表示と更新 */}
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            予約画面でのアナウンス表示
          </Text>
          <Textarea
            placeholder="アナウンスを入力してください"
            value={announce}
            onChange={handleAnnounceChange}
            backgroundColor={'#fdfdfd'}
            mb={2}
          />
          <Button
            colorScheme="blue"
            onClick={handleUpdateAnnounce}
            width="100%"
          >
            アナウンスを更新
          </Button>
        </Box>

        {/* UsersListコンポーネント */}
        <UsersList
          users={filteredUsers}
          studioId={studioId}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
        />

        {/* 固定予約一覧の追加（UsersListの下に移動） */}
        <Box
          mt={4}
          borderWidth="1px"
          borderRadius="xl"
          backgroundColor="#fdfdfd"
          p={4}
        >
          <Text textAlign={'center'} mb={4}>
            <b>固定予約一覧</b>
          </Text>
          {sortedFixies && sortedFixies.length > 0 ? (
            <Table variant="simple" size="sm" bg="white" borderRadius="md">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">会員</Td>
                  <Td fontWeight="bold">曜日</Td>
                  <Td fontWeight="bold">時間</Td>
                  <Td fontWeight="bold">移動</Td>
                </Tr>
                {sortedFixies.map((fixie, index) => (
                  <Tr
                    key={index}
                    cursor="pointer"
                    _hover={{ backgroundColor: 'gray.100' }}
                    onClick={() => {
                      setSelectedFixieIndex(index);
                      setEditedWeekday(fixie.weekday);
                      setEditedStart(fixie.start);
                      setStartError(''); // エラーをリセット
                      onOpen();
                    }}
                  >
                    <Td>{fixie.userName}</Td>
                    <Td>{fixie.weekday}</Td>
                    <Td>{fixie.start}</Td>
                    <Td>
                      {fixie.hasOwnProperty('movable')
                        ? fixie.movable
                          ? '可能'
                          : '不可'
                        : '-'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text textAlign="center">固定予約はありません。</Text>
          )}
        </Box>

        {/* 編集モーダル */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedFixieIndex !== null && sortedFixies[selectedFixieIndex]
                ? `${sortedFixies[selectedFixieIndex].userName}さんの専用枠を編集`
                : '専用枠の編集'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="weekday" mb={4}>
                <FormLabel>曜日</FormLabel>
                <Select
                  value={editedWeekday}
                  onChange={e => setEditedWeekday(e.target.value)}
                >
                  <option value="月">月</option>
                  <option value="火">火</option>
                  <option value="水">水</option>
                  <option value="木">木</option>
                  <option value="金">金</option>
                  <option value="土">土</option>
                  <option value="日">日</option>
                </Select>
              </FormControl>
              <FormControl id="start" mb={4} isInvalid={startError !== ''}>
                <FormLabel>開始時間</FormLabel>
                <Input
                  type="text"
                  placeholder="例: 1000"
                  value={editedStart}
                  onChange={e => {
                    const value = e.target.value;
                    setEditedStart(value);
                    // バリデーション
                    if (!/^\d{4}$/.test(value)) {
                      setStartError('4桁の半角数字を入力してください。');
                    } else {
                      const hour = parseInt(value.substring(0, 2), 10);
                      const minute = parseInt(value.substring(2, 4), 10);
                      if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
                        setStartError('有効な時間を入力してください。');
                      } else {
                        setStartError('');
                      }
                    }
                  }}
                />
                {startError && (
                  <FormErrorMessage>{startError}</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={async () => {
                  if (selectedFixieIndex === null) return;

                  // 追加のバリデーションチェック
                  if (!/^\d{4}$/.test(editedStart)) {
                    setStartError('4桁の半角数字を入力してください。');
                    return;
                  }
                  const hour = parseInt(editedStart.substring(0, 2), 10);
                  const minute = parseInt(editedStart.substring(2, 4), 10);
                  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
                    setStartError('有効な時間を入力してください。');
                    return;
                  }

                  // 更新するfixies配列をコピー
                  const updatedFixies = [...studio.fixies];
                  updatedFixies[selectedFixieIndex] = {
                    ...updatedFixies[selectedFixieIndex],
                    weekday: editedWeekday,
                    start: editedStart,
                  };

                  // Firestoreに更新
                  try {
                    const studioDocRef = doc(firestore, 'studios', studioId);
                    await updateDoc(studioDocRef, { fixies: updatedFixies });
                    // 更新後、ローカルステートも更新
                    setStudio(prevStudio => ({
                      ...prevStudio,
                      fixies: updatedFixies,
                    }));
                    toast({
                      title: '固定予約を更新しました。',
                      status: 'success',
                      duration: 3000,
                      isClosable: true,
                    });
                    onClose();
                  } catch (error) {
                    console.error('固定予約の更新に失敗しました:', error);
                    toast({
                      title: 'エラー',
                      description:
                        '固定予約の更新に失敗しました。もう一度お試しください。',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }}
                isDisabled={startError !== ''}
              >
                保存
              </Button>
              <Button variant="ghost" onClick={onClose}>
                キャンセル
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* UserStatsTable や 管理者メニューなど、他のセクションは省略 */}
        <Box
          my={4}
          borderWidth="0px"
          borderRadius="xl"
          backgroundColor="#fdfdfd"
          p={4}
        >
          <Text
            fontSize="sm"
            fontWeight="bold"
            mt={0}
            mb={0}
            cursor="pointer"
            onClick={toggleStatsVisibility}
          >
            {studio.name + ' の情報 '}
            {isStatsVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Text>
          {isStatsVisible && (
            <>
              <Text fontSize="xs" mt={4}>
                〒{studio.postalCode}
              </Text>
              <Text fontSize="xs">{studio.address}</Text>
              <UserStatsTable
                currentUser={currentUser}
                users={users}
                studio={studio}
              />
            </>
          )}
        </Box>
        {currentUser?.admin && (
          <Box
            mt={4}
            borderWidth="0px"
            borderRadius="xl"
            backgroundColor="#fdfdfd"
            p={4}
          >
            <Text textAlign={'center'} mt={0}>
              <b>管理者メニュー</b>
            </Text>
            <Select
              placeholder="インストラクターを選択"
              value={selectedInstructor}
              onChange={e => setSelectedInstructor(e.target.value)}
              backgroundColor={'#fdfdfd'}
              mt={4}
            >
              {studio.instructors &&
                Object.entries(studio.instructors).map(([id, name]) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </Select>
            <Button
              size="md"
              onClick={onClickShift}
              colorScheme="teal"
              width={'100%'}
              mt={4}
              isDisabled={!selectedInstructor}
            >
              予約の受付枠を設定する
            </Button>
            <Button
              size="md"
              onClick={() => navigate(`/studios/${studioId}/business`)}
              colorScheme="blue"
              width={'100%'}
              mt={4}
            >
              経営の指標を確認する
            </Button>
            <Button
              size="md"
              onClick={() => navigate(`/studios/${studioId}/sales`)}
              colorScheme="orange"
              width={'100%'}
              mt={4}
            >
              毎月の売上を確認する
            </Button>
          </Box>
        )}
      </Box>
      <BackButton path="/manage" />
    </Container>
  );
};

const UserStatsTable = ({ currentUser, users, studio }) => {
  const countUsersByPlan = (plan, sex) => {
    return users.filter(
      user =>
        user.plan === plan && user.status && user.active && user.sex === sex
    ).length;
  };

  const analyzeUsers = sex => {
    const fixedUsersCount = users.filter(
      user => user?.fixies?.length > 0 && user.sex === sex
    ).length;
    const activeUsersCount = users.filter(
      user => user.status && user.active && user.sex === sex
    ).length;
    const planUsersCount = users.filter(
      user =>
        [2, 4, 6, 8].includes(user.plan) &&
        user.status &&
        user.active &&
        user.sex === sex
    ).length;

    // planごとの人数とplanを掛け算して足し合わせた当月予測レッスン数
    const totalPlanUsersCount = users
      .filter(user => user.status && user.active && user.sex === sex)
      .map(user => user.plan)
      .reduce((acc, plan) => acc + plan, 0);

    // planごとの単価
    const planPrices = {
      female: {
        2: 8800,
        4: 8800,
        6: 8250,
        8: 7700,
      },
      male: {
        2: 9900,
        4: 9900,
        6: 9350,
        8: 8800,
      },
    };

    // planの単価と人数を掛け算した売上
    let planSales = 0;
    users
      .filter(
        user =>
          [2, 4, 6, 8].includes(user.plan) &&
          user.status &&
          user.active &&
          user.sex === sex
      )
      .forEach(user => {
        planSales += planPrices[sex][user.plan] * user.plan;
      });

    return {
      fixedUsersCount,
      activeUsersCount,
      planUsersCount,
      totalPlanUsersCount,
      planSales,
    };
  };
  const female = analyzeUsers('female');
  const male = analyzeUsers('male');

  const countValidShifts = shifts => {
    let count = 0;
    for (const key in shifts) {
      if (shifts[key] && shifts[key].length > 0) count++;
    }
    return count * 4;
  };

  return (
    <Table variant="simple" my={2} fontSize={'xs'} backgroundColor={'#fdfdfd'}>
      <Tbody>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}></Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            女性
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            男性
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            プラン登録者総数
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {female['planUsersCount']}名
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {male['planUsersCount']}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            月2プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(2, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(2, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            月4プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(4, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(4, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            月6プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(6, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(6, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            月8プラン
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(8, 'female')}名
          </Td>
          <Td height="40px" padding="8px">
            {countUsersByPlan(8, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px">
            専用枠利用
          </Td>
          <Td height="40px" padding="8px">
            {female['fixedUsersCount']}名
          </Td>
          <Td height="40px" padding="8px">
            {male['fixedUsersCount']}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            回数券利用（休会含む）
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {countUsersByPlan(0, 'female')}名
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {countUsersByPlan(0, 'male')}名
          </Td>
        </Tr>
        <Tr>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            会員数合計
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {female['activeUsersCount']}名
          </Td>
          <Td height="40px" padding="8px" fontWeight={'bold'}>
            {male['activeUsersCount']}名
          </Td>
        </Tr>
        {currentUser?.admin && (
          <>
            <Tr>
              <Td height="40px" padding="8px" fontWeight={'bold'}>
                レッスン数予測
              </Td>
              <Td colSpan="2" height="40px" padding="8px" fontWeight={'bold'}>
                {female['totalPlanUsersCount'] + male['totalPlanUsersCount']}/
                {countValidShifts(studio.shifts)} 回
              </Td>
            </Tr>
            <Tr>
              <Td height="40px" padding="8px" fontWeight={'bold'}>
                当月売上高予測（回数券除く）
              </Td>
              <Td colSpan="2" height="40px" padding="8px" fontWeight={'bold'}>
                {/* 3桁区切り */}
                {(female['planSales'] + male['planSales']).toLocaleString()}円
              </Td>
            </Tr>
          </>
        )}
      </Tbody>
    </Table>
  );
};

const UsersList = ({ users, studioId, searchQuery, onSearchChange }) => {
  const navigate = useNavigate();
  const handleUserClick = (userId, flag) => {
    if (!flag) return;
    navigate(`/studios/${studioId}/users/${userId}`);
  };
  // 現会員、未入会、退会者のユーザーをフィルタリング
  const activeUsers = users.filter(user => user.active && user.status);
  const trialUsers = users.filter(
    user => user.active && !user.status && user?.trialId?.length > 0
  );
  const pendingUsers = users.filter(
    user => !user.status && !(user?.trialId?.length > 0)
  );
  const inactiveUsers = users.filter(user => !user.active && user.status);
  return (
    <div>
      <Box
        key={'search'}
        borderWidth="1px"
        borderRadius="xl"
        p={4}
        mt={0}
        backgroundColor={'#fdfdfd'}
      >
        <Text textAlign={'center'} mb={4}>
          <b>所属会員の一覧</b>
        </Text>
        <Input
          placeholder="氏名で検索"
          backgroundColor={'#fdfdfd'}
          value={searchQuery}
          onChange={onSearchChange}
          mb={2}
        />

        {/* タブの追加 */}
        <Tabs>
          <TabList overflowX={'auto'}>
            <Tab>会員（{activeUsers.length}）</Tab>
            <Tab>体験（{trialUsers.length}）</Tab>
            <Tab>保留（{pendingUsers.length}）</Tab>
            <Tab>退会（{inactiveUsers.length}）</Tab>
          </TabList>

          <TabPanels>
            {/* 現会員のリスト */}
            <TabPanel>
              <UserListPanel
                users={activeUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>

            {/* 体験のリスト */}
            <TabPanel>
              <UserListPanel
                users={trialUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>

            {/* 検討のリスト */}
            <TabPanel>
              <UserListPanel
                users={pendingUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>

            {/* 退会者のリスト */}
            <TabPanel>
              <UserListPanel
                users={inactiveUsers}
                handleUserClick={handleUserClick}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

// 共通のユーザーリストパネルコンポーネントを定義
const UserListPanel = ({ users, handleUserClick }) => (
  <Box
    height="240px" // 固定の高さ
    overflowY="scroll" // 縦スクロールを有効にする
    mt={1}
  >
    {users.length > 0 ? (
      users.map(user => (
        <Box
          key={user.id}
          borderWidth="1px"
          borderRadius="xl"
          p={4}
          mt={2}
          backgroundColor={user.active ? '#fdfdfd' : 'gray.300'}
          onClick={() => handleUserClick(user.id, user.active)}
          cursor={user.active ? 'pointer' : 'not-allowed'}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="xs">
              <Badge
                fontSize="9px"
                px={1}
                py="0.5"
                mr="1"
                mb="0.5"
                bg="gray.100"
              >
                月{user.plan}
              </Badge>
              {user.fullName}　
              {user.active && user.status ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="green.100"
                >
                  継続中
                </Badge>
              ) : !user.active && user.status ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.300"
                >
                  退会済
                </Badge>
              ) : !user.active && !user.status ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.100"
                >
                  未入会
                </Badge>
              ) : user.initial ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.100"
                >
                  手続中
                </Badge>
              ) : user?.trialId?.length > 0 ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="red.500"
                  color="white"
                >
                  体験
                </Badge>
              ) : (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="yellow.100"
                >
                  検討
                </Badge>
              )}
              {user?.fixies?.length > 0 ? (
                <Badge
                  fontSize="9px"
                  px={1}
                  py="0.5"
                  mr="1"
                  mb="0.5"
                  bg="green.300"
                >
                  固定済
                </Badge>
              ) : (
                <></>
              )}
            </Text>
          </Flex>
        </Box>
      ))
    ) : (
      <Text>ユーザーが存在しません。</Text>
    )}
  </Box>
);

export default Studio;
