// components/User.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Text, Container, Heading, Flex, Spinner } from '@chakra-ui/react';
import FormLink from './FormLink';
import BackButton from './BackButton';
import {
  firestore,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from '../firebaseConfig';

const User = () => {
  const { studioId, userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // 継続期間を計算する関数
  function calculateContinuationPeriod(createdAt) {
    const currentDate = new Date(); // 現在の時間
    const createdDate = createdAt.toDate(); // TimestampをDate形式に変換

    // 年、月、日をそれぞれ計算
    let yearDiff = currentDate.getFullYear() - createdDate.getFullYear();
    let monthDiff = currentDate.getMonth() - createdDate.getMonth();
    let dayDiff = currentDate.getDate() - createdDate.getDate();

    // 日が負の値の場合、1ヶ月引いて日数を修正
    if (dayDiff < 0) {
      monthDiff--;
      const daysInPreviousMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate();
      dayDiff += daysInPreviousMonth;
    }

    // 月が負の値の場合、1年引いて月数を修正
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    // 結果を「○年○ヶ月○日」で返す ただし、0年の場合は年を表示しない
    return yearDiff > 0
      ? `${yearDiff}年${monthDiff}ヶ月${dayDiff}日`
      : `${monthDiff}ヶ月と${dayDiff}日`;
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        // studioid からスタジオ取得
        const studioDocRef = doc(firestore, 'studios', studioId);
        const studioDocSnap = await getDoc(studioDocRef);

        if (userDocSnap.exists() && studioDocSnap.exists()) {
          // ユーザー情報を取得
          const userData = { id: userDocSnap.id, ...userDocSnap.data() };

          // スタジオ情報を取得
          const studioData = {
            id: studioDocSnap.id,
            slug: studioDocSnap.data().slug,
          };

          // 'tickets' サブコレクションの参照に対してクエリを作成
          const ticketsColRef = collection(userDocRef, 'tickets');
          const unusedTicketsQuery = query(
            ticketsColRef,
            where('used', '==', false)
          );

          // 'tickets' サブコレクションからクエリに一致するドキュメントを取得
          const ticketsSnapshot = await getDocs(unusedTicketsQuery);
          const ticketsData = ticketsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          // reservationのコレクションから今の時間以降でactive = trueでstudioIdとuserIdが一致するものを取得
          const reservationsColRef = collection(firestore, 'reservations');
          const reservationsQuery = query(
            reservationsColRef,
            where('active', '==', true),
            where('studioId', '==', studioId),
            where('userId', '==', userId)
          );
          const reservationsSnapshot = await getDocs(reservationsQuery);
          const reservationsData = reservationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          const now = new Date();
          const futureReservations = reservationsData.filter(reservation => {
            const endDate = new Date(reservation.endDate);
            return endDate > now && reservation.type !== 'block';
          });

          const sortedReservations = futureReservations.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
          );

          // ユーザー情報と 'tickets' 情報をまとめて状態に保存
          setUser({
            ...userData,
            tickets: ticketsData,
            studio: studioData,
            reservations: sortedReservations,
          });
          console.log('予約を取得しました:', reservationsData);
        } else {
          console.log('ユーザーが見つかりません。');
        }
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      }
    };

    fetchUser();
  }, [studioId, userId]);

  if (!user) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner mr={3} />
        <Text fontSize="xl">データ読み込み中...</Text>
      </Flex>
    );
  }

  // // 体験予約のステータス
  // const trialStatus = {
  //   0: '体験予約を検討中',
  //   1: '体験予約を待機中',
  //   2: '体験予約したが来店しなかった',
  //   3: '体験予約して来店したが入会しなかった',
  //   4: '体験予約して来店して入会した',
  // };

  const links = [
    // {
    //   text: `体験レッスン（現在の状況：${trialStatus[user?.trial_status]}）`,
    //   path: `/studios/${studioId}/users/${userId}/tickets`,
    // },
    {
      text: `チケット管理（有効期限内：${user?.tickets?.length}枚）`,
      path: `/studios/${studioId}/users/${userId}/tickets`,
    },
    {
      text: `ご予約の管理（来店ご予定：${user?.reservations?.length}件）`,
      path: `/studios/${studioId}/users/${userId}/reservations`,
    },
    {
      text: `専用枠の設定（今の専用枠：${user?.fixies?.length}枠）`,
      path: `/studios/${studioId}/users/${userId}/set-fixies`,
    },
    {
      text: `プランの変更（今のプラン：月${user?.plan}）`,
      path: `/studios/${studioId}/users/${userId}/allow-change-plan`,
    },
    {
      text: `退会の手続き（継続：${calculateContinuationPeriod(
        user?.createdAt
      )}）`,
      path: `/studios/${studioId}/users/${userId}/allow-terminate`,
    },
  ];

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="24px" mb="20px">
        {user.fullName}さんの管理ページ
      </Heading>
      {links.map((link, index) => (
        <FormLink
          key={index}
          onClick={() => navigate(link.path, { state: { user: user } })}
        >
          {link.text}
        </FormLink>
      ))}
      <BackButton />
    </Container>
  );
};

export default User;
