import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import {
  Box,
  Container,
  Heading,
  Button,
  // Select,
  // FormLabel,
  // Flex,
} from '@chakra-ui/react';
import BackButton from './BackButton';
// import LoadingModal from './LoadingModal';
// import {
//   firestore,
//   collection,
//   getDocs,
//   query,
//   where,
// } from '../firebaseConfig';

const ChangePlan = () => {
  const { currentUser } = useAuth();
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  // const [updating, setUpdating] = useState(false);
  // const [selectedPlan, setSelectedPlan] = useState(4);
  // const [planNumber, setPlanNumber] = useState(4);
  // const [plansOptions, setPlansOptions] = useState([]);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  const plans = {
    0: '回数券ご利用中',
    2: '月２回プラン',
    4: '月４回プラン',
    6: '月６回プラン',
    8: '月８回プラン',
  };

  // const handlePlanChange = e => {
  //   const priceId = e.target.value;
  //   setSelectedPlan(priceId);
  //   setPlanNumber(plansOptions.find(plan => plan.value === priceId)?.plan);
  // };

  // const handleSubmit = useCallback(async () => {
  //   if (!selectedPlan || !currentUser.subscriptionId) {
  //     console.log('必要な情報が足りません');
  //     return;
  //   }
  //   setUpdating(true);

  //   // クラウドファンクションのURL
  //   const functionUrl =
  //     'https://asia-northeast1-medy-system.cloudfunctions.net/update_subscription';

  //   // リクエストのボディを準備
  //   const requestBody = {
  //     subscriptionId: currentUser?.subscriptionId,
  //     newPriceId: selectedPlan,
  //   };

  //   try {
  //     const response = await fetch(functionUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     const responseData = await response.json();
  //     setUpdating(false);
  //     console.log('サブスクリプションの更新に成功:', responseData);
  //     navigate('/set-plan', { state: { number: planNumber } });
  //   } catch (error) {
  //     console.error('サブスクリプションの更新に失敗:', error);
  //     setUpdating(false);
  //   }
  // }, [selectedPlan, currentUser.subscriptionId, navigate, planNumber]);

  useEffect(() => {
    // 現在の日時を取得
    const now = new Date();
    // 現在の年月を取得
    const year = now.getFullYear();
    const month = now.getMonth(); // 月は0から始まるので、+1する必要はありません

    // 当月の20日23時59分の日時オブジェクトを作成
    const deadline = new Date(year, month, 20, 23, 59, 59);

    // 現在の日時が期限を過ぎているか確認
    if (now > deadline) {
      setIsDeadlinePassed(true);
    }
  }, []);

  // useEffect(() => {
  //   const fetchPlanProducts = async () => {
  //     setLoading(true);
  //     const productsCollectionRef = collection(firestore, 'products');
  //     const q = query(
  //       productsCollectionRef,
  //       where('active', '==', true),
  //       where('stripe_metadata_type', '==', 'live'),
  //       where('stripe_metadata_category', '==', 'plan')
  //     );
  //     const querySnapshot = await getDocs(q);
  //     const productsData = await Promise.all(
  //       querySnapshot.docs.map(async doc => {
  //         const productData = { id: doc.id, ...doc.data() };
  //         const pricesCollectionRef = collection(
  //           firestore,
  //           `products/${doc.id}/prices`
  //         );
  //         const priceQuerySnapshot = await getDocs(pricesCollectionRef);
  //         productData.prices = priceQuerySnapshot.docs.map(priceDoc => ({
  //           id: priceDoc.id,
  //           ...priceDoc.data(),
  //         }));
  //         return productData;
  //       })
  //     );

  //     const unselectProductData = productsData?.filter(
  //       product => Number(product.metadata.plan) !== currentUser?.plan
  //     );

  //     const plansOptions = unselectProductData.map(product => ({
  //       value: product.prices.find(
  //         price => price.stripe_metadata_sex === currentUser?.sex
  //       ).id,
  //       plan: product.metadata.plan,
  //       label: product.name,
  //     }));
  //     setPlansOptions(plansOptions);
  //     setLoading(false);
  //   };
  //   fetchPlanProducts();
  // }, [navigate, currentUser?.plan, currentUser?.sex]);

  // if (loading) {
  //   return <LoadingModal text="プラン情報を取得中..." />;
  // }

  // if (updating) {
  //   return <LoadingModal text="プランを変更中..." />;
  // }

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Heading as="h1" size="sm" textAlign="center" mt="0px" mb="20px">
        medy定期プランの変更
      </Heading>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Heading as="h2" size="sm" textAlign="center" mt="20px" mb="20px">
          現在は『{plans[currentUser?.plan] || '回数券ご利用中'}』です🙌
        </Heading>
        {/* <Box
          borderWidth="0px"
          borderRadius="xl"
          overflow="hidden"
          p={4}
          mt={4}
          backgroundColor={'#fdfdfd'}
        >
          <FormLabel fontSize="sm" color="gray.600">
            切り替えるプランを選んで下さい：
          </FormLabel>
          <Select
            placeholder="プランを選択"
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            {plansOptions.map(plan => (
              <option key={plan.value} value={plan.value}>
                {plan.label}
              </option>
            ))}
          </Select>
          <Flex flexDirection="column" alignItems="center" width="full">
            {isDeadlinePassed ? (
              <Button mt={4} colorScheme="red" isDisabled>
                プラン変更期限が切れました
              </Button>
            ) : (
              <Button
                mt={4}
                colorScheme="teal"
                onClick={() => handleSubmit(selectedPlan)}
              >
                プラン変更
              </Button>
            )}
          </Flex>
          <Box overflow="hidden" px={2} mt={4}>
            <p style={{ fontSize: 12, color: 'gray' }}>
              {isDeadlinePassed
                ? 'プランを変更する場合は、切り替えたい月の前月１日から２０日の間にご申請ください。'
                : '回数の少ないプランに切り替えると専用枠はリセットされます。ご注意ください。また、２０日を過ぎますと翌月適用の形でプラン変更ができなくなります。この点についても十分にご注意ください。'}
            </p>
          </Box>
        </Box> */}
        <div>
          <p>
            <span style={{ color: 'gray' }}>
              プランを登録・変更する場合は、切り替えたい月の前月２０日までにご申請ください。
            </span>
          </p>
          <br />
          <a
            href={`
          https://docs.google.com/forms/d/e/1FAIpQLSd6t9FdO6TsY7Y3k7OniauBS-o2IrJbALmc1qNK30cYNWjVnA/viewform?usp=pp_url&entry.928921988=${currentUser?.studio?.name}&entry.684669523=${currentUser?.fullName}&entry.1637351293=${currentUser?.email}&entry.943745978=&entry.331742947=`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              colorScheme="orange"
              width="full"
              isDisabled={isDeadlinePassed}
            >
              プランを登録・変更する
            </Button>
          </a>
          {isDeadlinePassed && (
            <p
              style={{
                color: 'red',
                textAlign: 'center',
                marginTop: '10px',
                fontSize: 12,
              }}
            >
              ※ 来月中のプラン変更申請の期限が過ぎました。
              <br />
              翌月になると手続きが可能です。
            </p>
          )}
          <br />
        </div>
      </Box>
      <BackButton />
      <Box overflow="hidden" px={6} mb={4}>
        <p style={{ fontSize: 12, color: 'gray' }}>
          利用規約にもあります通り、決済・会計処理の都合上、期限を過ぎますと切替や返金などの対応ができません。ご注意ください。
        </p>
        <br />
        <p style={{ fontSize: 12, color: 'gray' }}>
          プラン登録なしの状態になると、ご予約の専用枠が解除されます。
          <br />
          <br />
          解除した後に他の順番待ちのお客様に専用枠を提供するなどして、元に戻したくても戻せない場合があります。
          <br />
          <br />
          あらかじめご理解・ご容赦くださいませ。
        </p>
        <br />
        <p
          style={{
            fontSize: 13,
            color: 'teal',
            paddingRight: 8,
            float: 'right',
          }}
        >
          STUDIO medy
        </p>
      </Box>
      {'　'}
    </Container>
  );
};

export default ChangePlan;
