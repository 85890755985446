import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { useAuth } from '../AuthProvider';
import { sendDiscordNotification } from '../utils/discord';

const PlanSubscriptionSuccessPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const updateUserInfoAndAddTickets = async () => {
      const price = {
        female: {
          0: '0',
          2: '18,700',
          4: '35,200',
          6: '49,500',
          8: '61,600',
        },
        male: {
          0: '0',
          2: '20,900',
          4: '39,600',
          6: '56,100',
          8: '70,400',
        },
      };
      const embeds = [
        {
          title: `月${currentUser?.plan}回プラン契約`,
          description: `月${currentUser?.plan}回プランのサブスクリプションが購入されました。`,
          color: 5814783,
          fields: [
            {
              name: '売上',
              value: `${price[currentUser?.sex][currentUser?.plan]}円`,
              inline: true,
            },
            {
              name: '購入者',
              value: currentUser?.fullName,
              inline: true,
            },
          ],
          footer: {
            text: '購入日時',
          },
          timestamp: new Date().toISOString(),
        },
      ];

      await sendDiscordNotification('payment', embeds);

      console.log('User status and tickets updated successfully.');
      navigate('/before', { state: { reload: true } });
    };
    updateUserInfoAndAddTickets();
  }, [currentUser, navigate]);

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>お支払い成功🎉</title>
      </Helmet>
      <Heading
        as="h1"
        size="xs"
        textAlign="center"
        mt="12px"
        mb="20px"
        color="gray.600"
      >
        待機画面
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          チケットを付与しています...
        </Text>
        {/* <Image
          src="/waiting.jpg"
          alt="メンテナンス中のイラスト"
          borderRadius="md"
        /> */}
        <Text mt={4} fontSize={'xs'} color="gray.500" textAlign={'center'}>
          少々お待ちくださいませ。
        </Text>
      </Box>
    </Container>
  );
};

export default PlanSubscriptionSuccessPage;
