import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  firestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  updateDoc,
  addDoc,
  deleteDoc,
} from '../firebaseConfig';
import {
  Container,
  Text,
  Box,
  Button,
  Input,
  VStack,
  Heading,
  useToast,
  FormLabel,
  Flex,
  Switch,
  NumberInput,
  NumberInputField,
  Divider,
} from '@chakra-ui/react';
import BackButton from './BackButton';
import dayjs from 'dayjs';
import { useAuth } from '../AuthProvider';

const ContractEditPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { instructorId } = useParams(); // URLからinstructorIdを取得
  const [contractData, setContractData] = useState(null);
  const [instructorName, setInstructorName] = useState('');
  const [loading, setLoading] = useState(true);

  // currentUserの取得（管理者チェックのため）
  const { currentUser } = useAuth();

  // 認証と権限の確認
  useEffect(() => {
    if (!currentUser?.admin) {
      navigate('/home'); // 管理者でない場合はホームにリダイレクト
    }
  }, [currentUser, navigate]);

  // 契約ドキュメントの一覧を保持する状態
  const [contracts, setContracts] = useState([]);

  // Firestoreから契約データを取得する
  useEffect(() => {
    const fetchContractData = async () => {
      try {
        // インストラクターの名前を取得
        const userDocRef = doc(firestore, 'users', instructorId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setInstructorName(
            userDocSnap.data().fullName || '不明なインストラクター'
          );
        }

        // feesコレクションの全ドキュメントを取得
        const feesRef = collection(firestore, 'users', instructorId, 'fees');
        const feesSnapshot = await getDocs(
          query(feesRef, orderBy('createdAt', 'desc'))
        );
        const contractList = feesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            startAt: data.startAt
              ? dayjs(data.startAt.toDate()).format('YYYY-MM-DD')
              : '',
            finishAt: data.finishAt
              ? dayjs(data.finishAt.toDate()).format('YYYY-MM-DD')
              : '',
          };
        });
        setContracts(contractList);

        // 初期表示では何も選択されていない状態にする
        setContractData(null);
        setLoading(false);
      } catch (error) {
        toast({
          title: 'データ取得エラー',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchContractData();
  }, [instructorId, toast]);

  // 編集ボタンのハンドラー
  const handleEdit = contract => {
    setContractData(contract);
  };

  // コピーボタンのハンドラー
  const handleCopy = contract => {
    const copiedContract = { ...contract, id: null };
    setContractData(copiedContract);
  };

  // 削除ボタンのハンドラー
  const handleDelete = async contractId => {
    const confirmDelete = window.confirm('この契約を削除しますか？');
    if (confirmDelete) {
      try {
        const feeDocRef = doc(
          firestore,
          'users',
          instructorId,
          'fees',
          contractId
        );
        await deleteDoc(feeDocRef);
        // contractsから削除
        setContracts(contracts.filter(c => c.id !== contractId));
        // 削除した契約が選択されていた場合、フォームをリセット
        if (contractData?.id === contractId) {
          setContractData(null);
        }
        toast({
          title: '契約削除完了',
          description: '契約が削除されました。',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: '契約削除エラー',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // フォームの送信処理
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const feesRef = collection(firestore, 'users', instructorId, 'fees');

      const contractDataToSave = {
        ...contractData,
        startAt: contractData.startAt ? new Date(contractData.startAt) : null,
        finishAt: contractData.finishAt
          ? new Date(contractData.finishAt)
          : null,
        updatedAt: new Date(),
      };

      if (contractData.id) {
        // 既存の契約を更新
        const feeDocRef = doc(feesRef, contractData.id);
        await updateDoc(feeDocRef, contractDataToSave);
        // contractsリストを更新
        const updatedContract = {
          ...contractDataToSave,
          id: contractData.id,
          startAt: contractDataToSave.startAt
            ? dayjs(contractDataToSave.startAt).format('YYYY-MM-DD')
            : '',
          finishAt: contractDataToSave.finishAt
            ? dayjs(contractDataToSave.finishAt).format('YYYY-MM-DD')
            : '',
        };
        setContracts(
          contracts.map(c => (c.id === contractData.id ? updatedContract : c))
        );
      } else {
        // 新しい契約を作成
        const newDocRef = await addDoc(feesRef, {
          ...contractDataToSave,
          createdAt: new Date(),
        });
        const newContract = {
          ...contractDataToSave,
          id: newDocRef.id,
          startAt: contractDataToSave.startAt
            ? dayjs(contractDataToSave.startAt).format('YYYY-MM-DD')
            : '',
          finishAt: contractDataToSave.finishAt
            ? dayjs(contractDataToSave.finishAt).format('YYYY-MM-DD')
            : '',
        };
        // contractsリストに追加
        setContracts([newContract, ...contracts]);
      }

      toast({
        title: '契約保存完了',
        description: '契約が正常に保存されました。',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // フォームをリセット
      setContractData(null);
    } catch (error) {
      toast({
        title: '契約保存エラー',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // フォームのレンダリング
  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Heading as="h1" size="md" textAlign="center" mt="20px" mb="20px">
        契約編集ページ
      </Heading>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        {loading ? (
          <Text>読み込み中...</Text>
        ) : (
          <>
            <Text fontSize="lg" mb={4}>
              インストラクター：{instructorName}
            </Text>
            {/* 契約ドキュメントの一覧表示 */}
            <Box mt={4}>
              <Text fontSize="lg" mb={2}>
                契約一覧
              </Text>
              {contracts.map((contract, index) => (
                <Box
                  key={contract.id}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  mt={2}
                  cursor="pointer"
                  bg={contract.id === contractData?.id ? 'teal.100' : 'white'}
                  onClick={() => handleEdit(contract)}
                >
                  <Text fontSize="md">
                    契約期間: {contract.startAt} 〜 {contract.finishAt}
                  </Text>
                  <Flex mt={2}>
                    <Button
                      size="sm"
                      mr={2}
                      onClick={() => handleEdit(contract)}
                    >
                      編集
                    </Button>
                    <Button
                      size="sm"
                      mr={2}
                      onClick={() => handleCopy(contract)}
                    >
                      コピー
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleDelete(contract.id)}
                    >
                      削除
                    </Button>
                  </Flex>
                </Box>
              ))}
            </Box>
            {/* 新規追加ボタン */}
            <Button
              colorScheme="blue"
              size="sm"
              mt={4}
              onClick={() => {
                // 空の契約を用意
                setContractData({
                  id: null,
                  lesson: 0,
                  meeting: 0,
                  training: 0,
                  outsource: 0,
                  train: 0,
                  train_upper: false,
                  tax: false,
                  fix: [],
                  startAt: '',
                  finishAt: '',
                  address: '',
                });
              }}
            >
              新しい契約を追加
            </Button>
            {/* 契約編集フォーム */}
            {contractData && (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <VStack spacing="24px" pt={4} pb={4}>
                  {/* 契約期間 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      契約開始日
                    </FormLabel>
                    <Input
                      type="date"
                      value={contractData.startAt}
                      onChange={e =>
                        setContractData({
                          ...contractData,
                          startAt: e.target.value,
                        })
                      }
                    />
                  </Flex>
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      契約終了日
                    </FormLabel>
                    <Input
                      type="date"
                      value={contractData.finishAt}
                      onChange={e =>
                        setContractData({
                          ...contractData,
                          finishAt: e.target.value,
                        })
                      }
                    />
                  </Flex>
                  {/* レッスン単価 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      レッスン単価（円）
                    </FormLabel>
                    <NumberInput
                      min={0}
                      value={contractData.lesson || 0}
                      onChange={valueString =>
                        setContractData({
                          ...contractData,
                          lesson: parseInt(valueString),
                        })
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                  {/* 会議手当 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      会議手当（円）
                    </FormLabel>
                    <NumberInput
                      min={0}
                      value={contractData.meeting || 0}
                      onChange={valueString =>
                        setContractData({
                          ...contractData,
                          meeting: parseInt(valueString),
                        })
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                  {/* 研修手当 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      研修手当（円）
                    </FormLabel>
                    <NumberInput
                      min={0}
                      value={contractData.training || 0}
                      onChange={valueString =>
                        setContractData({
                          ...contractData,
                          training: parseInt(valueString),
                        })
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                  {/* 作業手当 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      作業手当（円）
                    </FormLabel>
                    <NumberInput
                      min={0}
                      value={contractData.outsource || 0}
                      onChange={valueString =>
                        setContractData({
                          ...contractData,
                          outsource: parseInt(valueString),
                        })
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                  {/* 出勤手当 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      出勤手当（円）
                    </FormLabel>
                    <NumberInput
                      min={0}
                      value={contractData.train || 0}
                      onChange={valueString =>
                        setContractData({
                          ...contractData,
                          train: parseInt(valueString),
                        })
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Flex>
                  {/* 出勤手当上限 */}
                  <Flex flexDirection="row" alignItems="center" width="full">
                    <Switch
                      isChecked={contractData.train_upper || false}
                      onChange={e =>
                        setContractData({
                          ...contractData,
                          train_upper: e.target.checked,
                        })
                      }
                      mr={2}
                    />
                    <FormLabel fontSize="12" mb="1">
                      出勤手当の上限を1万円に設定
                    </FormLabel>
                  </Flex>
                  {/* 源泉徴収の有無 */}
                  <Flex flexDirection="row" alignItems="center" width="full">
                    <Switch
                      isChecked={contractData.tax || false}
                      onChange={e =>
                        setContractData({
                          ...contractData,
                          tax: e.target.checked,
                        })
                      }
                      mr={2}
                    />
                    <FormLabel fontSize="12" mb="1">
                      源泉徴収の有無
                    </FormLabel>
                  </Flex>
                  {/* 住所 */}
                  <Flex flexDirection="column" alignItems="start" width="full">
                    <FormLabel fontSize="12" mb="1">
                      住所
                    </FormLabel>
                    <Input
                      placeholder="住所"
                      value={contractData.address || ''}
                      onChange={e =>
                        setContractData({
                          ...contractData,
                          address: e.target.value,
                        })
                      }
                    />
                  </Flex>
                  {/* 固定報酬 */}
                  <Divider my={4} />
                  <Text fontSize="lg" mb={2}>
                    固定報酬
                  </Text>
                  {contractData.fix &&
                    contractData.fix.map((fixItem, index) => (
                      <Box
                        key={index}
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        mt={2}
                      >
                        <Flex
                          flexDirection="column"
                          alignItems="start"
                          width="full"
                        >
                          <FormLabel fontSize="12" mb="1">
                            固定報酬名
                          </FormLabel>
                          <Input
                            value={fixItem.name || ''}
                            onChange={e => {
                              const newFix = [...contractData.fix];
                              newFix[index].name = e.target.value;
                              setContractData({ ...contractData, fix: newFix });
                            }}
                          />
                        </Flex>
                        <Flex
                          flexDirection="column"
                          alignItems="start"
                          width="full"
                        >
                          <FormLabel fontSize="12" mb="1">
                            金額（円）
                          </FormLabel>
                          <NumberInput
                            min={0}
                            value={fixItem.price || 0}
                            onChange={valueString => {
                              const newFix = [...contractData.fix];
                              newFix[index].price = parseInt(valueString);
                              setContractData({ ...contractData, fix: newFix });
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Flex>
                        <Button
                          colorScheme="red"
                          size="sm"
                          mt={2}
                          onClick={() => {
                            const newFix = contractData.fix.filter(
                              (_, i) => i !== index
                            );
                            setContractData({ ...contractData, fix: newFix });
                          }}
                        >
                          削除
                        </Button>
                      </Box>
                    ))}
                  <Button
                    colorScheme="teal"
                    size="sm"
                    mt={2}
                    onClick={() => {
                      const newFix = contractData.fix
                        ? [...contractData.fix, { name: '', price: 0 }]
                        : [{ name: '', price: 0 }];
                      setContractData({ ...contractData, fix: newFix });
                    }}
                  >
                    固定報酬を追加
                  </Button>
                  {/* 送信ボタン */}
                  <Button type="submit" colorScheme="blue" width="full" mt={6}>
                    {contractData.id ? '契約を更新する' : '契約を作成する'}
                  </Button>
                </VStack>
              </form>
            )}
          </>
        )}
      </Box>
      <BackButton />
    </Container>
  );
};

export default ContractEditPage;
