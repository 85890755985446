import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';

const TicketsList = () => {
  const { userId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state || {};
  const {
    isOpen: isDeactivateOpen,
    onOpen: onDeactivateOpen,
    onClose: onDeactivateClose,
  } = useDisclosure();

  // ユーザーを非アクティブ化する関数
  const deactivateUser = async () => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      await updateDoc(userDocRef, { active: false });
      onDeactivateClose();
      navigate(-2);
    } catch (error) {
      console.error('ユーザーの非アクティブ化に失敗しました: ', error);
    }
  };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={1}>
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        mt={6}
        p={4}
        w="100%"
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        maxH="70vh"
        display="flex"
        flexDirection="column"
      >
        <Text textAlign={'center'} fontSize="lg" mb="4">
          <b>{user ? user.fullName : 'ユーザー'}さまの退会処理</b>
        </Text>

        {/* ユーザー非アクティブ化ボタン */}
        <Box textAlign="center">
          <Button
            size="md"
            colorScheme="red"
            onClick={onDeactivateOpen}
            leftIcon={<FontAwesomeIcon icon={faUserSlash} />}
          >
            退会を確定させる
          </Button>
        </Box>

        <Modal isOpen={isDeactivateOpen} onClose={onDeactivateClose}>
          <ModalOverlay />
          <ModalContent verticalAlign={'center'} mx={8}>
            <ModalHeader>
              {user ? user.fullName : 'ユーザー'}さまの退会確認
            </ModalHeader>
            <ModalBody>
              <Text>この操作は元に戻せません。ユーザーを退会させますか？</Text>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" colorScheme="red" onClick={deactivateUser}>
                退会を確定
              </Button>
              <Button size="sm" variant="ghost" onClick={onDeactivateClose}>
                キャンセル
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      {'　'}
    </Container>
  );
};

export default TicketsList;
