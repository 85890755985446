import React, { useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { Box, Container, Text } from '@chakra-ui/react';
import BackButton from './BackButton';
import Logo from './Logo';

const TermsOfService = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    // 利用規約の更新フラグの監視
  }, [currentUser]);

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="ご利用の規約" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
        height="60vh"
        overflow="auto"
      >
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2}>
          <b>第１条 - 総則</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            STUDIO
            medy（以下、medy）の定めるこの利用規約（以下、本規約）は、medyが運営する全てのスタジオ（以下、スタジオ）で提供されるサービスのご利用に適用されます。全ての利用者は本規約を遵守するものとし、本規約が守られなかった場合は本規約に基づき適切に対応いたします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第２条 - 目的</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは、ご利用いただく皆様が新たな自分に生まれ変わり、ポジティブに人生を生きることができるように、心とカラダをしなやかに鍛え向上させるお手伝いをしたいと考えています。洗練されたマシンピラティスの機材やスタジオおよびパーソナルピラティスならではの丁寧なケアを通して、この目的を追求いたします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第３条 - サービス</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは、ご利用される皆様に、マンツーマンでピラティスのレッスンを行います。スタジオには高品質なマシンを設置し、洗練された個室空間でマシンピラティスに取り組むことができます。また、会員以外の方に体験レッスンを提供します。体験レッスンを受けられる場合は、ヒアリングシートの記入を虚偽なく行い、当日の体調が悪い場合は申し出てください。体調の状態を問わず、万一、レッスン後に不調をきたしてもmedyは責任を負わないものとします。また、類似の職業に就くインストラクターであるにも関わらず、インストラクターではないと虚偽を伝えて体験を受講したことが発覚した場合、違約金として2万円を請求いたします。体験レッスンを受ける際は、本利用規約に同意したものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第４条 - 会員制度</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは会員制のもとスタジオを運営しています。スタジオの利用を希望される方は、本規約に則り入会の契約を締結するものとします。入会を以って利用者は本規約に定める会員権を獲得し、入会後の利用者（以下、会員）は、継続的にスタジオを利用しレッスンを受けることができるようになります。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第５条 - 会員権利</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyの会員の権利は、スタジオを利用する権利、予約チケットを購入する権利、インストラクターのレッスン時間を予約する権利、および会員として必要になる全ての手続きが行える専用アプリを利用する権利です。入会手続きの完了とともに、会員はこれらの権利を得ることができ、継続して月に決まった回数レッスンを受けるプラン（以下、プラン）を登録した会員は、特定の曜日と時間をその会員専用の枠（以下、専用枠）として確保する権利が追加となります。なお、この会員の権利は第三者に譲渡や相続、貸与することはできません。また、これらの権利はmedyの運営が続く限りは保証されますが、medyが会員を除名処分にした場合および会員本人が退会の手続きをした場合と９０日以上予約がない場合に喪失します。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第６条 - 会員義務</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            会員は氏名や連絡先、カード情報などの登録情報の変更が必要になった場合は速やかに情報の更新を行ってください。また、体調不良の際はスタジオ利用を控え、健康の回復に専念してください。その他、肉体的・精神的な暴力行為、誹謗中傷行為、威嚇行為、迷惑行為、破壊行為、不法行為、公序良俗に反する行為、秩序を乱す行為など、medyが会員としてふさわしくないと認める行為を禁止します。インストラクターの指導を受ける際は、インストラクターの指示に従い、安全にレッスンを受けてください。お互いに人として尊重し、相手に不快感を与えないよう適切なコミュニケーションを取るものとします。また、スタジオ内での機材の取り扱いについても、機材の破損や故障を防ぐために、慎重に取り扱ってください。会員が本規約に違反した場合、medyは会員を除名処分とし、会員権利を喪失させることがあります。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第７条 - 会則</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyに入会する方は、本規約に同意し、医師に運動を禁じられておらず健康状態に異常がないことを約束するものとします。また、medyに過去に除名されておらず、反社会的活動を行っていない・暴力団の構成員ではなく、将来的に関わらないことも保証するものとします。また、medyが必要と判断した場合は、独自の審査を経て入会を認められる必要があります。入会を希望する方は、以上の規則を守って入会するものとし、違反が発覚した場合は除名となります。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第８条 - 入会</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            入会を希望する方が本規約を確認し、専用のアプリで入会手続きを行い、事務手数料やアカウント発行料を含む入会金を支払った段階で会則に基づいて入会が完了したものとして扱います。継続してレッスンを受ける場合は、入会のタイミングでプラン登録または回数券ご利用の手続きを完了します。medyによって入会を承認されアプリで予約ができるようになった方を会員と定義し、第５条と第６条に定める権利と義務が発生します。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第９条 - 料金</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            サービス利用料金は、会員専用アプリ内にある消費税込みの記載金額をアプリ内で提供される決済方法でお支払いください。クレジットカードまたは銀行振込に対応しています。プラン登録料は、翌月分を前月のうちにお支払いいただくものとし、引き落としは毎月入会した日と同じ日付となります。滞納された場合はスタジオ利用ができませんのでご注意ください。納入完了した料金については法令の定めによる返金以外は行われません。プランの変更や回数券ご利用への切り替え、退会については、期日である前月２０日までの手続きにご協力ください。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１０条 - ご予約</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            ご予約はアプリの「新しく予約する」ボタンからのみ受け付けています。すでに確定したご予約はアプリのホーム画面で「ご予約の確認」を開くと確認でき、ゴミ箱ボタンを押すことで前日２１時までキャンセルが可能です。予約時間を変更したい場合も一度キャンセルいただくことで予約チケットが返却されるので、そちらを利用して再度新しいご予約を作成してください。キャンセル期限を過ぎますとチケットが返却されず、チケット代の返金も認められない点にご注意ください。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１１条 - プラン</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyでは、レッスンを継続して通いやすいように回数が増えるほど安価にレッスンが受けられる定期プラン（以下、プラン）を用意しています。プランは月４回、月６回、月８回の３種類あります。入会時にご希望に応じて設定することができ、毎月３回以下しか通わない場合は回数券ご利用で通っていただいております。会員がプラン変更する場合は、変更を希望する月の前月の２０日までに専用アプリのメニューから、プラン変更申請を提出する必要があります。申請期日に遅れた場合の返金は受け付けておりません。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１２条 - 専用枠</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyでは、プラン登録されている方のみ、アプリにてご予約の専用枠を設定することができます。これは、月８回プランの方は２つ、他のプランの方は１つ設定が可能です。通いやすい曜日と時間を会員専用枠として扱うことで、会員が増えた時に予約ができないという悩みを抱えずに済み、ピラティスの習慣作りにも貢献するための制度で、原則として設定した専用枠の曜日と時間に通っていただく形になります。専用枠はいつでもアプリから変更が可能です。また、登録しているプランを回数が少ないプランに変更した場合、専用枠は一度リセットされるため、再設定が必要になる点にご注意ください。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１３条 - 回数券</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            会員が回数券を利用して通う場合は、適用を希望する月の前月の２０日までに専用アプリのメニューから申請を提出する必要があります。medyでは、退会後に再入会される場合は入会金が満額かかります。そのため、プラン登録の再開を予定している場合は一時的に回数券ご利用に切り替えることを推奨しております。また、意図せぬ会員権利の継続や喪失を防ぐため、６０日以上レッスンを受けられていない会員宛てに電磁気的方法で回数券切り替えのご案内をさせていただく場合があります。申請期日に遅れた場合の返金は受け付けておりません。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１４条 - 退会</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            会員が退会する場合は、希望する退会月の前月の２０日までに専用アプリのメニューから、退会申請を提出する必要があります。その後、medyによる退会手続きが進められ、月末までに完了し翌月末に退会となります。メール、電話、口頭による退会の申し出は受け付けておりませんのでご注意ください。未払いの月会費などがある場合には、それを完納した場合に限り退会申請が受理されます。申請期日に遅れた場合の返金は受け付けておりません。
            ご注意ください。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１５条 - 休業</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは、必要に応じて定休日をもうけます。また、スタジオの改築や修繕、外出が困難となる気象災害やパンデミック、その他やむえない事由等が発生した場合に、臨時で休業する場合があります。
            休業が事前に予定されている場合は、その旨を電磁気的方法で周知するものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１６条 - 閉鎖</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは、スタジオの全部または一部を閉鎖することができます。閉鎖が事前に予定されている場合は、原則として１ヶ月前までに会員に対してその旨を告知します。当該スタジオに所属する会員は、近隣で別のmedyスタジオが存続しており移籍可能な場合は優先して案内を受けることができるものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１７条 - 損害賠償</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            会員は、スタジオ利用中に自身の責に帰すべき事由により相手方に損害を発生させた場合、その損害に関する責を負うものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１８条 - 免責事項</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは、会員がスタジオの利用中または利用後に生じた損害に対しては、心身の不調・負傷や事故、会員間のトラブルや所持品の盗難・紛失などを含め、medyに故意または重大な過失がある損害を除き、当該損害に対する責を負いません。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第１９条 - 不可抗力</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            天災、火災、爆発、洪水、疫病、ストライキ、暴動または戦争行為などの影響を受け営業を停止せざるを得ないなどの不可抗力があった場合、medyは本規約の義務を履行する責任を負わず、履行遅滞について責任を負わないものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第２０条 - 規約変更</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            medyは、本規約の改定を行うことができます。改定を実施した場合は、会員専用アプリまたは公式LINEまたはスタジオで直接お伝えする形にて通知いたします。改定後に会員専用アプリを利用した時点で、改定事項が適用されるものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第２１条 - 協議解決</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            本規約に定めのない事項については、medyの公式ウェブサイト、会員専用アプリ、スタジオ内掲示などに定める通りに対応します。それら以外で、本規約の解釈に疑義が生じた場合には、双方誠意を持って協議の上、速やかに解決を図るものとします。
          </Text>
        </Box>
        <br />
        <Text textAlign={'left'} fontSize="14" ml="2" mb={-2} mt={-1}>
          <b>第２２条 - 合意管轄</b>
        </Text>
        <Box borderWidth="1px" borderRadius="xl" overflow="hidden" p={4} mt={4}>
          <Text fontSize={12} color="gray.600">
            本規約に則して解決できない一切のトラブルについては、準拠法を日本法として、東京地方裁判所を第一審の専属的合意管轄裁判所として解決を目指すものとします。
          </Text>
        </Box>
      </Box>
      <Text color={'gray'} fontSize="xs" my={3} mr={1} textAlign={'right'}>
        2024/03/03 公開
        <br />
        2024/04/28 更新
        <br />
        2024/07/24 更新
        <br />
        2024/11/25 更新
      </Text>
      <BackButton />
    </Container>
  );
};

export default TermsOfService;
