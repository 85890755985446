export const getEndOfThisMonthInSeconds = () => {
  const now = new Date();
  const endOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
  return Math.floor(endOfThisMonth.getTime() / 1000);
};

export const getEndOfNextMonthInSeconds = () => {
  const now = new Date();
  const endOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0, 23, 59, 59);
  return Math.floor(endOfNextMonth.getTime() / 1000);
};

export const getNowInSeconds = () => Math.floor(Date.now() / 1000);
