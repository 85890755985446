import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { doc, updateDoc, firestore } from '../firebaseConfig';

const SetPlan = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const number = location?.state?.number;

  useEffect(() => {
    const updateUserPlan = async () => {
      if (currentUser) {
        // planが0であることを確認
        const userRef = doc(firestore, 'users', currentUser.uid);
        try {
          // ユーザードキュメントを更新
          await updateDoc(userRef, { plan: Number(number) });
          console.log('User plan updated successfully.');
          navigate('/before', { state: { reload: true } });
        } catch (error) {
          console.error('Error updating user status:', error);
        }
      } else {
        console.log('User is not authenticated or plan is not set.');
      }
    };
    updateUserPlan();
  }, [currentUser, navigate, number]);

  return (
    <Container
      backgroundColor={'#ede9e5'}
      borderRadius={'lg'}
      mt={4}
      pt={8}
      maxW="container.md"
      centerContent
    >
      <Helmet>
        <title>プラン設定中⚙</title>
      </Helmet>
      <Heading
        as="h1"
        size="xs"
        textAlign="center"
        mt="12px"
        mb="20px"
        color="gray.600"
      >
        待機画面
      </Heading>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={6}
        my={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Text
          fontSize="lg"
          textAlign="center"
          mb={4}
          fontWeight={'bold'}
          color="gray.700"
        >
          プランを設定しています...
        </Text>
        {/* <Image
          src="/waiting.jpg"
          alt="メンテナンス中のイラスト"
          borderRadius="md"
        /> */}
        <Text mt={4} fontSize={'xs'} color="gray.500" textAlign={'center'}>
          少々お待ちくださいませ。
        </Text>
      </Box>
    </Container>
  );
};

export default SetPlan;
