import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Flex,
  Text,
} from '@chakra-ui/react';

const LoadingModal = ({ isOpen = true, text }) => {
  return (
    <Modal isOpen={isOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent backgroundColor="transparent" boxShadow="none">
        <Flex direction="column" align="center" justify="center" height="100vh">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.100"
            color="blue.500"
            size="xl"
          />
          <Text mt={4} color="white">
            {text || 'サーバー通信中...'}
          </Text>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
