import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from 'firebase/firestore'; // Firestore imports
import dayjs from 'dayjs';
import { Box, Container, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import Logo from './Logo';

const Manage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const onClickStudio = studioId => {
    navigate(`/studios/${studioId}`);
  };

  // ログアウト関数を作成
  const handleLogout = async () => {
    navigate('/home');
  };

  // State for studios
  const [studios, setStudios] = useState([]);
  // State for instructors
  const [instructors, setInstructors] = useState([]);

  // Fetch studios from Firestore
  useEffect(() => {
    const fetchStudios = async () => {
      const db = getFirestore(); // Initialize Firestore
      const studiosCollection = collection(db, 'studios');
      const studiosSnapshot = await getDocs(studiosCollection);
      const studiosList = studiosSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStudios(studiosList);
    };

    fetchStudios();
  }, []);

  // Fetch instructors from Firestore
  useEffect(() => {
    const fetchInstructors = async () => {
      const db = getFirestore(); // Firestoreの初期化
      const usersCollection = collection(db, 'users');
      const q = query(
        usersCollection,
        where('instructor', '==', true),
        // where('admin', '==', false),
        orderBy('createdAt', 'asc') // 古い順に並べる
      );
      const querySnapshot = await getDocs(q);
      const instructorsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInstructors(instructorsList.filter(instructor => !instructor.admin));
    };

    fetchInstructors();
  }, []);

  const onClickInstructor = instructorId => {
    navigate(`/instructors/${instructorId}/contracts`);
  };

  return (
    <Container backgroundColor={'#ede9e5'} borderRadius={'10'} mt={4} pt={8}>
      <Logo text="会員管理システム" />
      <Box
        borderWidth="0px"
        borderRadius="xl"
        overflow="hidden"
        p={4}
        mt={4}
        backgroundColor={'#fdfdfd'}
        boxShadow="sm"
      >
        <Flex alignItems="center" mb="4">
          <Flex flexDirection="column" alignItems="flex-start">
            <Text fontSize={20}>
              <b>{currentUser?.fullName || ''}</b>
            </Text>
            <Text fontSize="12">{currentUser?.email || ''}</Text>
          </Flex>
          <Spacer />
          <Button
            size="xs"
            colorScheme="gray"
            onClick={handleLogout}
            variant={'outlineBlack'}
            isDisabled
          >
            {currentUser?.admin
              ? '管理者'
              : currentUser?.instructor
              ? 'インストラクター'
              : '権限なし'}
          </Button>
        </Flex>

        {/* スタジオ一覧 */}
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          店舗管理
        </Text>
        {studios.length > 0 ? (
          studios.map(studio => (
            <Box
              key={studio.id}
              borderWidth="1px"
              borderRadius="md"
              p={4}
              mt={2}
              onClick={() => onClickStudio(studio.id)}
              cursor="pointer"
            >
              <Text>{studio.name || '名前未設定'}</Text>
            </Box>
          ))
        ) : (
          <Text>スタジオが見つかりません</Text>
        )}
        <Text color={'gray'} fontSize="xs" my={2} textAlign={'center'}>
          タップすると予約受付枠や会員の操作が可能
        </Text>

        {/* インストラクター一覧 */}
        {currentUser?.admin && (
          <Box mt={6}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              契約管理
            </Text>
            {instructors.length > 0 ? (
              instructors.map(instructor => (
                <Box
                  key={instructor.id}
                  borderWidth="1px"
                  borderRadius="md"
                  p={4}
                  mt={2}
                  onClick={() => onClickInstructor(instructor.id)}
                  cursor="pointer"
                >
                  <Text>{instructor.fullName || '名前未設定'}</Text>
                  <Text fontSize={'xs'}>
                    since{' '}
                    {instructor.createdAt
                      ? dayjs(instructor.createdAt.toDate()).format(
                          'YYYY-MM-DD'
                        )
                      : '作成日不明'}
                  </Text>
                </Box>
              ))
            ) : (
              <Text>インストラクターが見つかりません</Text>
            )}
          </Box>
        )}
      </Box>

      {/* homeに戻るボタンを追加 */}
      <Button
        size="md"
        onClick={handleLogout}
        colorScheme="gray"
        width={'100%'}
        my={4}
      >
        ホームに戻る
      </Button>
    </Container>
  );
};

export default Manage;
