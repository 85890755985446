import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Text,
  Image,
} from '@chakra-ui/react';

const LoadingModal = ({ isOpen = true, text }) => {
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [allComplete, setAllComplete] = useState(false);

  const imgStyle = {
    marginRight: '24px',
    objectFit: 'cover',
    objectPosition: 'center', // 中央の部分を表示
  };

  useEffect(() => {
    let interval1, interval2, interval3;
    if (isOpen) {
      setProgress1(0);
      setProgress2(0);
      setProgress3(0);
      setAllComplete(false);

      interval1 = setInterval(() => {
        setProgress1(prev => {
          if (prev >= 100) {
            clearInterval(interval1);
            interval2 = setInterval(() => {
              setProgress2(prev => {
                if (prev >= 100) {
                  clearInterval(interval2);
                  interval3 = setInterval(() => {
                    setProgress3(prev => {
                      if (prev >= 100) {
                        clearInterval(interval3);
                        setAllComplete(true);
                      }
                      return prev + 1;
                    });
                  }, 80);
                }
                return prev + 1;
              });
            }, 50);
          }
          return prev + 1;
        });
      }, 20);
    }
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent backgroundColor="white" boxShadow="none">
        <Flex
          direction="column"
          align="center"
          justify="center"
          height="100vh"
          p={4}
        >
          <Image
            src={'/medy_logo.svg'}
            alt="Logo"
            style={imgStyle}
            width={['66vw', '48%']}
          />
          {!allComplete && progress1 > 0 && progress1 < 100 && (
            <>
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progress1}%` }}
                ></div>
              </div>
              <Text mt={4} color="gray">
                {text || 'サーバー通信中...'}
              </Text>
            </>
          )}
          {!allComplete &&
            progress1 >= 100 &&
            progress2 > 0 &&
            progress2 < 100 && (
              <>
                <div className="progress-bar">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${progress2}%` }}
                  ></div>
                </div>
                <Text mt={4} color="gray">
                  データ取得中...
                </Text>
              </>
            )}
          {!allComplete &&
            progress2 >= 100 &&
            progress3 > 0 &&
            progress3 < 100 && (
              <>
                <div className="progress-bar">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${progress3}%` }}
                  ></div>
                </div>
                <Text mt={4} color="gray">
                  アプリ準備中...
                </Text>
              </>
            )}
          {allComplete && (
            <Text mt={4} color="gray">
              電波の状況が悪いようです
              <br />
              もう少しだけお待ちください...
            </Text>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default LoadingModal;
